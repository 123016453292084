import { z } from 'zod';

const optionalUrl = z.string().url().optional();

const experienceSchema = z.object({
  experience: z.array(z.object({
    poster: z.string().optional(),
    name: z.string().min(3, 'Must be at least 3 characters long'),
    logLine: z.string().min(10, 'Must be at least 10 characters long'),
    genre: z.string().optional(),
    format: z.string().min(3, 'Must be at least 3 characters long'),
    privacy: z.boolean().optional(),
    proof_link: optionalUrl,
    date: z.preprocess((arg) => {
      if (arg instanceof Date) return arg;
      if (typeof arg === 'string') {
        const date = new Date(arg);
        if (!isNaN(date.getTime())) return date;
      }
      return undefined;
    }, z.date().optional()),
    toDate: z.preprocess((arg) => {
      if (arg instanceof Date) return arg;
      if (typeof arg === 'string') {
        const date = new Date(arg);
        if (!isNaN(date.getTime())) return date;
      }
      return undefined;
    }, z.date().optional()),
    }).refine((data) => {
      // If `toDate` exists, `date` (From date) must also exist
      if (data.toDate && !data.date) {
        return false;
      }
      // If both dates exist, `toDate` must not be earlier than `date`
      if (data.date && data.toDate && data.toDate < data.date) {
        return false;
      }
      return true;
    }, {
      message: "'To' date cannot be earlier than 'From' date, and 'From' date must be provided if 'To' date exists.",
      path: ['toDate'], // Path to the error for better feedback
    })
  ).optional(),
});

export default experienceSchema;
