import React, { useState } from 'react';
import { Dialog, Button } from '@mui/material';
import { useAxios } from '../../../contexts/AxiosContext'; // Axios context for API calls
import { Pie } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend
} from 'chart.js';

// Register the necessary components for Chart.js
ChartJS.register(ArcElement, Tooltip, Legend);

const ReferralReport = () => {
    const axios = useAxios();
    const [open, setOpen] = useState(false);
    const [reportData, setReportData] = useState(null);
    const [loading, setLoading] = useState(true);

    // Open dialog and fetch report data
    const handleOpen = async () => {
        setOpen(true);
        setLoading(true);
        try {
            const response = await axios.get('/referral/report');
            setReportData(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching referral report', error);
            setLoading(false);
        }
    };

    // Close dialog
    const handleClose = () => {
        setOpen(false);
    };

    // Prepare data for the pie chart
    const pieChartData = reportData
        ? {
            labels: [
                ...Object.keys(reportData.verifiedUsers), // Referral codes + Direct Acquisition
            ],
            datasets: [
                {
                    label: 'Verified Users by Referral Code',
                    data: Object.values(reportData.verifiedUsers), // Verified users per referral code
                    backgroundColor: ['#36A2EB', '#FF6384', '#FFCE56', '#4BC0C0', '#9966FF'],
                },
            ],
        }
        : {};

    return (
        <div>
            <Button variant="contained" onClick={handleOpen}>
                Show Referral Report
            </Button>

            <Dialog open={open} onClose={handleClose} maxWidth="lg">
                <div style={{ padding: '20px', display: 'flex' }}>
                    <div style={{ width: '60%' }}>
                        <h2>Referral User Report</h2>

                        {loading && <p>Loading...</p>}

                        {reportData && (
                            <div>
                                <Pie data={pieChartData} />

                                <div style={{ marginTop: '20px' }}>
                                    <h3>Total Users: {reportData.totalUsers}</h3>
                                    <h3>Unverified Users: {reportData.unverifiedUsersCount}</h3>
                                </div>
                            </div>
                        )}
                    </div>

                    {/* Legends and referral metadata */}
                    {reportData && (
                        <div style={{ width: '40%', paddingLeft: '20px' }}>
                            <h3>Referral Programs Metadata</h3>
                            <ul>
                                {reportData.referralPrograms.map(referral => (
                                    <li key={referral._id} style={{ marginBottom: '10px' }}>
                                        <strong>{referral.eventName} - {referral.eventEpisode}</strong>
                                        <br />
                                        <span>Status: {referral.terminated ? 'Terminated' : (referral.expired ? 'Expired' : 'Active')}</span>
                                        <br />
                                        <span>From: {new Date(referral.fromDate).toLocaleDateString()}</span>
                                        <br />
                                        <span>To: {new Date(referral.toDate).toLocaleDateString()}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}

                    <Button variant="contained" onClick={handleClose} style={{ marginTop: '20px' }}>
                        Close
                    </Button>
                </div>
            </Dialog>
        </div>
    );
};

export default ReferralReport;
