import React from 'react';
import { Box, Typography, Container, Modal, useMediaQuery } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '../../contexts/ThemeContext';

const InfoModal = ({ open, onClose, title, content }) => {
    const { theme } = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const bgColor = theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.background.paper;
    const textColor = theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.text.primary;
    const subHeaderStyle = {
        color: textColor,
        fontWeight: '800',
        fontSize: isMobile ? '14px' : '18px',
    };
    const typographyStyle = {
        color: textColor,
        fontWeight: '300',
        fontSize: isMobile ? '12px' : '14px',
    };
    const modalStyle = {
        position: 'absolute',
        top: '0',
        left: '0',
        width: isMobile ? '100vw' : '50vw',
        maxHeight: '90vh',
        bgcolor: 'black',
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={modalStyle}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <CloseIcon
                        onClick={onClose}
                        sx={{
                            cursor: 'pointer',
                            color: typographyStyle.color,
                            fontSize: '24px',
                        }}
                    />
                </Box>
                <Typography variant="h4" gutterBottom style={{ color: textColor, fontWeight: '700', fontSize: "27px", marginLeft: "20px" }}>
                    {title}
                </Typography>
                <Container maxWidth="md" sx={{
                    backgroundColor: bgColor,
                    padding: '20px',
                    borderRadius: '8px',
                    overflowY: 'auto',
                    maxHeight: '80vh'
                }}>
                    {content.map((section, index) => (
                        <Box my={4} key={index}>
                            <Typography variant="h6" gutterBottom sx={subHeaderStyle}>
                                {section.header}
                            </Typography>
                            <Typography variant="body1" sx={typographyStyle}>
                                {section.body}
                            </Typography>
                        </Box>
                    ))}
                </Container>
            </Box>
        </Modal>
    );
};

export default InfoModal;
