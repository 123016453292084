import React, { useState, useRef, useEffect } from 'react';
import { Box, Grid, TextField, IconButton, Button, Typography, Tooltip, Divider, Autocomplete, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Skeleton } from '@mui/material';
import { useFormContext, Controller, useFieldArray, useWatch } from 'react-hook-form';
import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon, DeleteForever as DeleteForeverIcon, Add as AddIcon, Preview as PreviewIcon } from '@mui/icons-material';
import MetaPreview from '../FormAddons/MetaPreview';
import config from '../config.json';
import CloseIcon from '@mui/icons-material/Close';
import PreviewDialog from '../FormAddons/PreviewDialog';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const Experience = ({ userId, theme, isMobile }) => {
    const { control, setValue, trigger, formState: { errors } } = useFormContext();
    const { fields: experienceFields, append: appendexperience, remove: removeexperience } = useFieldArray({
        control,
        name: 'experience'
    });
    const watchedExperience = useWatch({ control, name: 'experience' });
    const [selectedExperienceIndex, setSelectedExperienceIndex] = useState(0);
    const [openDialog, setOpenDialog] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);
    const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
    const [previewUrl, setPreviewUrl] = useState('');
    const [openInvitingMessage, setOpenInvitingMessage] = useState(false);
    const isDark = theme.palette.mode === "dark";
    const [loadingMeta, setLoadingMeta] = useState({});
    const [linkHelperText, setLinkHelperText] = useState('Copy a valid link to paste here');

    useEffect(() => {
        if (experienceFields.length === 0) {
            setOpenInvitingMessage(true);
        }
    }, [experienceFields.length]);

    const titleRef = useRef(null);
    const logLineRef = useRef(null);
    const formatRef = useRef(null);
    const proofLinkRef = useRef(null);
    const dateRef = useRef(null);
    const toDateRef = useRef(null);

    useEffect(() => {
        if (titleRef.current) {
            titleRef.current.value = watchedExperience[selectedExperienceIndex]?.name || '';
        }
        if (logLineRef.current) {
            logLineRef.current.value = watchedExperience[selectedExperienceIndex]?.logLine || '';
        }
        if (formatRef.current) {
            formatRef.current.value = watchedExperience[selectedExperienceIndex]?.format || '';
        }
        if (proofLinkRef.current) {
            proofLinkRef.current.value = watchedExperience[selectedExperienceIndex]?.proof_link || '';
        }
        if (dateRef.current) {
            dateRef.current.setSelected(watchedExperience[selectedExperienceIndex]?.date ? new Date(watchedExperience[selectedExperienceIndex]?.date) : null);
        }
        if (toDateRef.current) {
            toDateRef.current.setSelected(watchedExperience[selectedExperienceIndex]?.toDate ? new Date(watchedExperience[selectedExperienceIndex]?.toDate) : null);
        }
    }, [selectedExperienceIndex, watchedExperience]);

    const handleAddNewExperience = () => {
        appendexperience({ name: '', logLine: '', genre: '', format: '', privacy: false, proof_link: '', date: null, toDate: '' });
    };

    useEffect(() => {
        if (experienceFields.length > 0) {
            setSelectedExperienceIndex(experienceFields.length - 1);
        } else {
            setSelectedExperienceIndex(0);  // or whatever your default should be
        }
    }, [experienceFields.length]);


    const handleNextExperience = async (index) => {
        const isValid = await trigger(`experience.${selectedExperienceIndex}`);
        if (isValid) {
            setSelectedExperienceIndex(index);
        } else {
            const firstErrorField = document.querySelector(`[name^="experience.${selectedExperienceIndex}"].Mui-error`);
            if (firstErrorField) {
                firstErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
                firstErrorField.focus();
            }
        }
    };

    const handlePrivacyToggle = () => {
        const currentExperience = watchedExperience[selectedExperienceIndex];
        if (currentExperience) {
            setValue(`experience.${selectedExperienceIndex}.privacy`, !currentExperience.privacy);
        }
    };

    // Function to handle pasting from the clipboard
    const handlePasteLink = async () => {
        try {
            const clipboardContent = await navigator.clipboard.readText();
            const urlPattern = new RegExp('^(https?:\\/\\/)?' + // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
                '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
            if (urlPattern.test(clipboardContent)) {
                setValue(`experience.${selectedExperienceIndex}.proof_link`, clipboardContent);
                setLinkHelperText('');
            } else {
                setLinkHelperText('Please copy a valid URL to paste here.');
            }
        } catch (error) {
            setLinkHelperText('Failed to paste content. Make sure to copy a URL.');
        }
    };

    const handleDeleteExperience = () => {
        removeexperience(deleteIndex);
        setDeleteIndex(null);
        setOpenDialog(false);

        // Adjust selected experience index if the current one is deleted
        if (selectedExperienceIndex >= experienceFields.length - 1) {
            setSelectedExperienceIndex(Math.max(0, experienceFields.length - 2));
        }
    };


    const handleDialogOpen = (index) => {
        setDeleteIndex(index);
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
        setDeleteIndex(null);
    };

    const handleOpenPreview = (url) => {
        setPreviewUrl(url);
        setOpenPreviewDialog(true);
    };

    const handleClosePreview = () => {
        setOpenPreviewDialog(false);
        setPreviewUrl('');
    };

    const currentExperience = watchedExperience[selectedExperienceIndex] || {};

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid
                    item
                    xs={12}
                    sx={{
                        height: '25%',
                        overflowX: 'auto',
                        whiteSpace: 'nowrap',
                        display: 'flex',
                        alignItems: 'center',
                        overflowY: 'hidden',
                        scrollbarWidth: 'none', // Firefox
                        '&::-webkit-scrollbar': {
                            display: 'none', // Chrome, Safari, Edge
                        },
                    }}
                >
                    <Box
                        display="inline-block"
                        onClick={handleAddNewExperience}
                        sx={{
                            cursor: 'pointer',
                            width: isMobile ? '50px' : '100px',
                            height: isMobile ? '75px' : '100px', // Height changes on hover
                            // backgroundC``olor: isDark ? '#f0f0f0' : '#f0f0f0',
                            borderRadius: '12px', // Keep rounded corners if desired
                            border: `2px solid white`, // Maintain border style
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginRight: 2,
                            position: 'relative'
                        }}
                    >
                        <AddIcon style={{ fontSize: '48px' }} />
                    </Box>
                    {experienceFields.map((item, index) => (
                        <Box
                            key={item.id}
                            display="inline-block"
                            onClick={() => handleNextExperience(index)}
                            sx={{
                                cursor: 'pointer',
                                border: selectedExperienceIndex === index ? `2px solid ${!isDark ? theme.palette.primary.main : 'white'}` : 'none',
                                marginRight: 2,
                                position: 'relative',
                                width: isMobile ? '100px' : '150px', // Updated width to be wider
                                height: isMobile ? '75px' : '100px', // Height changes on hover
                                borderRadius: '12px', // Optional: Add rounded corners
                                overflow: 'hidden', // Ensure content stays inside the box
                            }}
                        >
                            {loadingMeta[index] || !watchedExperience[index] ? (
                                <Skeleton variant="rectangular" width={150} height={100} />
                            ) : (
                                <>
                                    <MetaPreview
                                        key={watchedExperience[index]?.proof_link || ''}
                                        url={watchedExperience[index]?.proof_link || ''}
                                        expTitle={watchedExperience[index]?.name || ''}
                                        size="default"
                                        minimal={true}
                                        onLoad={() => setLoadingMeta((prev) => ({ ...prev, [index]: false }))}
                                        onLoading={() => setLoadingMeta((prev) => ({ ...prev, [index]: true }))}
                                    />
                                </>
                            )}

                        </Box>

                    ))}
                </Grid>

                <Grid item xs={12}>
                    {experienceFields.length > 0 && (
                        <Box>
                            <Box display="flex" alignItems="center" justifyContent="space-between" marginTop='8px'>
                                {/* {!isMobile && <Divider sx={{ color: 'grey', flexGrow: 1 }}>{`Experience: ${currentExperience.name || 'No Title'}`}</Divider>} */}
                                <Box display={'flex'} flexDirection={'row'} gap={1} alignItems={'center'}>
                                    <Grid item xs={2}>

                                        <Button
                                            onClick={handlePrivacyToggle}
                                            variant="outlined"
                                            color={watchedExperience[selectedExperienceIndex]?.privacy ? 'error' : 'success'}
                                            startIcon={watchedExperience[selectedExperienceIndex]?.privacy ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                        >
                                            {(watchedExperience[selectedExperienceIndex]?.privacy ? 'Private' : 'Public')}
                                        </Button>

                                    </Grid>
                                    {!isMobile && <Typography item > ← Toggle Between Private and Public mode to be shown in public profile</Typography>}
                                </Box>
                                <Tooltip title="Remove Experience">

                                    <Button
                                        onClick={() => handleDialogOpen(selectedExperienceIndex)}
                                        variant="outlined"
                                        color="error"
                                        startIcon={<DeleteForeverIcon />}
                                    >
                                        Delete
                                    </Button>
                                </Tooltip>

                            </Box>
                            <Grid container display="flex" spacing={2} marginTop={1}>
                                {!isMobile && <Grid item xs={12} sm={3}>
                                    <MetaPreview
                                        key={watchedExperience[selectedExperienceIndex]?.proof_link}
                                        expTitle={watchedExperience[selectedExperienceIndex]?.name}
                                        url={currentExperience.proof_link} size="default" minimal={true} />

                                    {currentExperience?.proof_link && (
                                        <Button
                                            variant="contained"
                                            startIcon={<PreviewIcon />}
                                            onClick={() => handleOpenPreview(currentExperience.proof_link)}
                                            sx={{ marginTop: "-65px", marginLeft: "5px", justifyContent: "center" }}
                                        >
                                            {!isMobile && "Preview"}
                                        </Button>
                                    )}

                                </Grid>}
                                <Grid item xs={12} sm={6} sx={{ marginTop: "10px" }}>
                                    <TextField
                                        inputRef={titleRef}
                                        fullWidth
                                        label={config.experience.title.label}
                                        margin="normal"
                                        size="small"
                                        helperText={errors?.experience?.[selectedExperienceIndex]?.name?.message || config.experience.title.helperText}
                                        error={!!errors?.experience?.[selectedExperienceIndex]?.name}
                                        onChange={(e) => setValue(`experience.${selectedExperienceIndex}.name`, e.target.value)}
                                    />
                                    <TextField
                                        inputRef={logLineRef}
                                        fullWidth
                                        label={config.experience.logLine.label}
                                        margin="normal"
                                        size="small"
                                        multiline
                                        minRows={4}
                                        maxRows={5}
                                        helperText={errors?.experience?.[selectedExperienceIndex]?.logLine?.message || config.experience.logLine.helperText}
                                        error={!!errors?.experience?.[selectedExperienceIndex]?.logLine}
                                        onChange={(e) => setValue(`experience.${selectedExperienceIndex}.logLine`, e.target.value)}
                                    />
                                    <Autocomplete
                                        options={config.experience.format.options}
                                        getOptionLabel={(option) => option}
                                        value={currentExperience.format || null}
                                        onChange={(event, value) => setValue(`experience.${selectedExperienceIndex}.format`, value)}
                                        isOptionEqualToValue={(option, value) => option === value || value === null}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={config.experience.format.label}
                                                placeholder=""
                                                margin="normal"
                                                size="small"
                                                helperText={errors?.experience?.[selectedExperienceIndex]?.format?.message || config.experience.format.helperText}
                                                error={!!errors?.experience?.[selectedExperienceIndex]?.format}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3} >
                                    <Box sx={{ mt: isMobile ? 0 : -2 }}>
                                        {/* Heading */}
                                        <Typography variant="body2" gutterBottom sx={{ fontSize: '0.875rem' }}>
                                            Paste any link that supports your experience
                                        </Typography>

                                        {/* Link Input Section */}
                                        <Grid display="flex" flexDirection="row" alignItems="center" gap={2}>

                                            {/* Link Holder */}
                                            <TextField
                                                inputRef={proofLinkRef}
                                                fullWidth
                                                margin="normal"
                                                size="small"
                                                helperText={(errors?.experience?.[selectedExperienceIndex]?.proof_link?.message)}
                                                error={!!errors?.experience?.[selectedExperienceIndex]?.proof_link || linkHelperText !== ''}
                                                value={watchedExperience[selectedExperienceIndex]?.proof_link || ''}
                                                onChange={(e) => setValue(`experience.${selectedExperienceIndex}.proof_link`, e.target.value)}
                                                disabled // Disable manual editing
                                            />

                                            {/* Paste Button */}
                                            <Button
                                                variant="contained"
                                                onClick={handlePasteLink}
                                                sx={{ flexShrink: 0, height: '40px' }}
                                            >
                                                Paste
                                            </Button>

                                            {/* Clear Button (Icon Button) */}
                                            <IconButton
                                                onClick={() => setValue(`experience.${selectedExperienceIndex}.proof_link`, '')}
                                                sx={{ flexShrink: 0, height: '40px' }}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        </Grid>
                                    </Box>

                                    <Controller
                                        name={`experience.${selectedExperienceIndex}.date`}
                                        control={control}
                                        defaultValue={null}
                                        render={({ field }) => (
                                            <Box display="flex" alignItems="center" sx={{ marginTop: 2 }}>
                                                <Typography
                                                    sx={{
                                                        color: "rgba(255, 255, 255, 0.7)",
                                                        fontSize: "16px",
                                                        marginRight: 2
                                                    }}
                                                >
                                                    From
                                                </Typography>

                                                <DatePicker
                                                    ref={dateRef}
                                                    selected={field.value ? new Date(field.value) : null}
                                                    onChange={(date) => {
                                                        setValue(`experience.${selectedExperienceIndex}.date`, date || null);
                                                        field.onChange(date);
                                                    }}
                                                    dateFormat="yyyy/MM"
                                                    maxDate={new Date()}
                                                    showMonthYearPicker
                                                    placeholderText="Select month and year"
                                                    customInput={
                                                        <Button variant="outlined" sx={{ textTransform: "none", padding: "12px 16px" }}>
                                                            {field.value
                                                                ? new Date(field.value).toLocaleDateString("en-US", {
                                                                    year: "numeric",
                                                                    month: "long",
                                                                })
                                                                : "Select Date"}
                                                        </Button>
                                                    }
                                                />
                                            </Box>
                                        )}
                                    />


                                    {/* To Date Picker */}
                                    <Controller
                                        name={`experience.${selectedExperienceIndex}.toDate`}
                                        control={control}
                                        defaultValue={null}
                                        render={({ field }) => (
                                            <Box display="flex" alignItems="center" sx={{ marginTop: 2 }}>
                                                <Typography
                                                    sx={{
                                                        color: "rgba(255, 255, 255, 0.7)",
                                                        fontSize: "16px",
                                                        marginRight: 2
                                                    }}
                                                >
                                                    To
                                                </Typography>

                                                {!watchedExperience[selectedExperienceIndex]?.date && (
                                                    <Typography sx={{ color: "rgba(244, 67, 54, 0.7)", marginRight: 2 }}>
                                                        Choose From Date
                                                    </Typography>
                                                )}

                                                <DatePicker
                                                    ref={toDateRef}
                                                    selected={field.value ? new Date(field.value) : null}
                                                    onChange={(toDate) => {
                                                        setValue(`experience.${selectedExperienceIndex}.toDate`, toDate || null);
                                                        field.onChange(toDate);
                                                    }}
                                                    dateFormat="yyyy/MM"
                                                    minDate={
                                                        watchedExperience[selectedExperienceIndex]?.date
                                                            ? new Date(watchedExperience[selectedExperienceIndex].date)
                                                            : null
                                                    }
                                                    disabled={!watchedExperience[selectedExperienceIndex]?.date}
                                                    showMonthYearPicker
                                                    placeholderText="Select month and year"
                                                    customInput={
                                                        <Button variant="outlined" sx={{ textTransform: "none", padding: "12px 16px" }}>
                                                            {field.value
                                                                ? new Date(field.value).toLocaleDateString("en-US", {
                                                                    year: "numeric",
                                                                    month: "long",
                                                                })
                                                                : "Select Date"}
                                                        </Button>
                                                    }
                                                />
                                            </Box>
                                        )}
                                    />


                                    {/* Error Display */}
                                    {watchedExperience[selectedExperienceIndex]?.date &&
                                        watchedExperience[selectedExperienceIndex]?.toDate &&
                                        new Date(watchedExperience[selectedExperienceIndex].toDate) < new Date(watchedExperience[selectedExperienceIndex].date) && (
                                            <Typography
                                                variant="body2"
                                                color="error"
                                                sx={{ marginTop: 1 }}
                                            >
                                                The "To" date cannot be earlier than the "From" date.
                                            </Typography>
                                        )
                                    }
                                </Grid>
                            </Grid>
                        </Box>
                    )}
                </Grid>
            </Grid>

            <Dialog open={openDialog} onClose={handleDialogClose}>
                <DialogTitle>Delete Experience</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this experience? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteExperience} color="secondary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <PreviewDialog open={openPreviewDialog} onClose={handleClosePreview} url={previewUrl} />
            <Dialog open={openInvitingMessage} onClose={() => setOpenInvitingMessage(false)}>
                <DialogTitle>{config.experience.invitingMessage.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {config.experience.invitingMessage.content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setOpenInvitingMessage(false);
                        handleAddNewExperience();
                    }} color="primary">
                        Yes
                    </Button>
                    <Button onClick={() => setOpenInvitingMessage(false)} color="secondary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Box >
    );
};

export default Experience;
