import React from 'react';
import { Box, List, ListItem, ListItemIcon, Tooltip, Divider } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import MessageIcon from '@mui/icons-material/Message';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import HomeIcon from '@mui/icons-material/Home';
import MapIcon from '@mui/icons-material/Map';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Brightness4Icon from '@mui/icons-material/Brightness4';  // For dark mode
import Brightness7Icon from '@mui/icons-material/Brightness7';  // For light mode
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import GridViewIcon from '@mui/icons-material/GridView';
import { useTheme } from '../../contexts/ThemeContext';
import { useNavigate } from 'react-router-dom';
import { useLogin } from '../../contexts/LoginContext';

function Sidebar({ username, onMenuClick }) {
  const { theme, toggleTheme } = useTheme();
  const navigate = useNavigate();
  const { logout } = useLogin();

  const menuItems = [
    { text: 'Dashboard', icon: <DashboardIcon />, action: () => onMenuClick('dashboard') },
    { text: 'User List', icon: <PeopleIcon />, action: () => onMenuClick('users') },
    { text: 'Reach Out', icon: <MessageIcon />, action: () => onMenuClick('reachOuts') },
    { text: 'PublicViews', icon: <VisibilityIcon />, action: () => onMenuClick('publicView') },
    { text: 'Story Wall', icon: <GridViewIcon />, action: () => onMenuClick('stories') },
    { text: 'Location Map', icon: <MapIcon />, action: () => onMenuClick('locationMap') },
    { text: 'Ai History', icon: <AutoAwesomeMosaicIcon />, action: () => onMenuClick('aiHistory') },
    { text: 'Ideogram API', icon: <AutoAwesomeIcon />, action: () => onMenuClick('ideogram_API') },
    { text: 'Ideogram History', icon: <TipsAndUpdatesIcon />, action: () => onMenuClick('ai_V2_History') },
    { text: 'Toggle Theme', icon: theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />, action: toggleTheme },
    { text: 'Home', icon: <HomeIcon />, action: () => navigate('/') },
    { text: 'Logout', icon: <ExitToAppIcon />, action: logout },
  ];

  return (
    <Box
      variant="permanent"
      open
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{ color: "white"}}
    >
      <List>
        <ListItem>
          <Tooltip title={`Welcome, ${username}`} placement="right">
            <ListItemIcon sx={{ color: 'white' }}>
              <PeopleIcon />
            </ListItemIcon>
          </Tooltip>
        </ListItem>
        <Divider />
        {menuItems.slice(0, 11).map((item, index) => (
          <Tooltip title={item.text} placement="right" key={index}>
            <ListItem button onClick={item.action}>
              <ListItemIcon sx={{ color: 'white' }}>
                {item.icon}
              </ListItemIcon>
            </ListItem>
          </Tooltip>
        ))}
        <Divider />
        {menuItems.slice(11).map((item, index) => (
          <Tooltip title={item.text} placement="right" key={index + 4}>
            <ListItem button onClick={item.action}>
              <ListItemIcon sx={{ color: 'white' }}>
                {item.icon}
              </ListItemIcon>
            </ListItem>
          </Tooltip>
        ))}
      </List>
    </Box>
  );
}

export default Sidebar;
