import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

function RedirectWithParams() {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);

        // Redirect to "/landing" with preserved query parameters
        navigate({
            pathname: "/",
            search: params.toString(),
        });
    }, [location, navigate]);

    return null; // This component doesn't render anything
}

export default RedirectWithParams;
