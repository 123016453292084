import React, { useState, useEffect } from 'react';
import { IconButton, Button, Box } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const baseURL = process.env.REACT_HOSTED_DOMAIN || 'https://justwrite.soozh.app/';

const PublicShare = ({ pubProfileLink, isLabel = false }) => {
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [fullURL, setFullURL] = useState('');

    useEffect(() => {
        // Update the fullURL whenever pubProfileLink changes
        if (pubProfileLink) {
            setFullURL(`${baseURL}p/${pubProfileLink}`);
        } else {
            // Fallback to localStorage if pubProfileLink is not available
            const storedLink = localStorage.getItem('pubProfileLink');
            setFullURL(`${baseURL}p/${storedLink}`);
        }
    }, [pubProfileLink]);

    const copyToClipboard = (link) => {
        navigator.clipboard.writeText(link)
            .then(() => setOpenSnackbar(true))
            .catch(err => console.error('Failed to copy:', err));
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const handleShare = () => {
        copyToClipboard(fullURL);
        if (navigator.share) {
            navigator.share({
                title: 'Check out this profile!',
                url: fullURL
            }).catch((error) => console.error('Error sharing', error));
        }
    };

    return (
        <div>
            {isLabel ? (
                <Button
                    onClick={handleShare}
                    variant="contained"
                    startIcon={<ShareIcon />}
                    sx={{ borderRadius: '8px', padding: '8px 16px', width:'100%' }}
                >
                    Share Your Profile With Friends
                </Button>
            ) : (
                <IconButton onClick={handleShare} style={{ color: 'inherit' }}>
                    <ShareIcon />
                </IconButton>
            )}
            <Snackbar
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                style={{ bottom: '100px' }}
            >
                <Alert onClose={handleCloseSnackbar} severity="success">
                    Public Shareable Link copied to your clipboard!
                </Alert>
            </Snackbar>
        </div>
    );
};

export default PublicShare;
