import React, { useState, useRef, useEffect } from 'react';
import {
    Box, Grid, TextField, IconButton, Button, Typography, Tooltip, Divider, Chip,
    Autocomplete, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@mui/material';
import { useFormContext, Controller, useFieldArray, useWatch } from 'react-hook-form';
import { CloudUpload as CloudUploadIcon, Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon, DeleteForever as DeleteForeverIcon, Add as AddIcon, Close as CloseIcon } from '@mui/icons-material';
import GroupsIcon from '@mui/icons-material/Groups';
import UploadModel from '../FormAddons/uploadFrame'; // Updated import
import config from "../config.json";
import CoWritersDialog from '../FormAddons/CoWritersDialog';

const MyStories = ({ userId, handleNextSection, theme, isMobile = { isMobile }, handleSave }) => {
    const [openCoWritersDialog, setOpenCoWritersDialog] = useState(false);
    const { control, setValue, getValues, trigger, formState: { errors } } = useFormContext();
    const { fields: ownWorksFields, append: appendOwnWorks, remove: removeOwnWorks } = useFieldArray({
        control,
        name: 'ownWorks.portfolio'
    });
    const watchedOwnWorks = useWatch({ control, name: 'ownWorks.portfolio' });
    const [selectedStoryIndex, setSelectedStoryIndex] = useState(0);
    const [openDialog, setOpenDialog] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);

    const titleRef = useRef(null);
    const logLineRef = useRef(null);
    const genreRef = useRef(null);
    const formatRef = useRef(null);
    const statusRef = useRef(null);
    const languageRef = useRef(null);
    const pitchReasonRef = useRef(null);
    const assetsLanguageRef = useRef(null);
    const assetsAvailableRef = useRef(null);
    const [openInvitingMessage, setOpenInvitingMessage] = useState(false);
    const isDark = theme.palette.mode === "dark"
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        if (ownWorksFields.length === 0) {
            setOpenInvitingMessage(true);
        }
    }, [ownWorksFields.length]);


    useEffect(() => {
        if (titleRef.current) {
            titleRef.current.value = watchedOwnWorks[selectedStoryIndex]?.name || '';
        }
        if (logLineRef.current) {
            logLineRef.current.value = watchedOwnWorks[selectedStoryIndex]?.logLine || '';
        }
        if (genreRef.current) {
            genreRef.current.value = watchedOwnWorks[selectedStoryIndex]?.genre_v2 || '';
        }
        if (formatRef.current) {
            formatRef.current.value = watchedOwnWorks[selectedStoryIndex]?.format || '';
        }
        if (statusRef.current) {
            statusRef.current.value = watchedOwnWorks[selectedStoryIndex]?.status || '';
        }
        if (languageRef.current) {
            languageRef.current.value = watchedOwnWorks[selectedStoryIndex]?.language || '';
        }
        if (assetsLanguageRef.current) {
            assetsLanguageRef.current.value = watchedOwnWorks[selectedStoryIndex]?.assetsLanguage || '';
        }
        if (pitchReasonRef.current) {
            pitchReasonRef.current.value = watchedOwnWorks[selectedStoryIndex]?.pitchReason || '';
        }
        if (assetsAvailableRef.current) {
            assetsAvailableRef.current.value = watchedOwnWorks[selectedStoryIndex]?.assetsAvailable || [];
        }
    }, [selectedStoryIndex, watchedOwnWorks]);

    const handleUploadSuccess = (s3Url, index) => {
        setValue(`ownWorks.portfolio.${index}.poster`, s3Url);
    };

    const handlePrivacyToggle = () => {
        const currentStory = watchedOwnWorks[selectedStoryIndex];
        if (currentStory) {
            const currentPrivacy = currentStory.privacy;
            const newPrivacy = currentPrivacy !== undefined ? !currentPrivacy : false;
            setValue(`ownWorks.portfolio.${selectedStoryIndex}.privacy`, newPrivacy);
        }
    };

    const handleAddNewStory = () => {
        appendOwnWorks({
            poster: '',
            name: '',
            logLine: '',
            genre_v2: '',
            format: '',
            privacy: false, // Initialize privacy with a default value
            status: '',
            credit: [],
            language: '',
            proof_link: '',
            formatDetails: { runtime: null, seasons: null, episodes: null, episodeRuntime: null } // Initialize with null
        });
        setTimeout(() => {
            setSelectedStoryIndex(ownWorksFields.length);
        }, 100);
    };

    useEffect(() => {
        if (ownWorksFields.length > 0) {
            setSelectedStoryIndex(0); // Select the first story by default
        }
    }, [ownWorksFields.length]);


    const handleNextStory = async (index) => {
        const isValid = await trigger(`ownWorks.portfolio.${selectedStoryIndex}`);
        if (isValid) {
            setSelectedStoryIndex(index);
        } else {
            const firstErrorField = document.querySelector(`[name^="ownWorks.portfolio.${selectedStoryIndex}"].Mui-error`);
            if (firstErrorField) {
                firstErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
                firstErrorField.focus();
            }
        }
    };

    const handleDeleteStory = () => {
        removeOwnWorks(deleteIndex);
        setDeleteIndex(null);
        setOpenDialog(false);
        if (selectedStoryIndex === deleteIndex) {
            setSelectedStoryIndex(0);
        }
    };

    const handleDialogOpen = (index) => {
        setDeleteIndex(index);
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
        setDeleteIndex(null);
    };

    const currentStory = watchedOwnWorks[selectedStoryIndex] || {};

    return (
        <Box >
            <Grid container spacing={2}>
                <Grid
                    item
                    xs={12}
                    style={{
                        height: 'auto', // Make height auto so it can grow based on content
                        minHeight: isMobile ? '75px' : '25%', // Set a minimum height, but allow it to grow
                        overflowY: 'visible', // Allow overflow so the content is not cut off
                        overflowX: 'auto', // Still allow horizontal scrolling if necessary
                        whiteSpace: 'nowrap',
                        display: 'flex',
                        alignItems: 'center',
                        margin: 'auto',
                        marginTop: '8px',
                        position: 'relative', // Keep relative positioning to allow absolute content within
                        scrollbarWidth: 'none',  // For Firefox
                        '&::-webkit-scrollbar': {
                            display: 'none', // For Chrome, Safari, and Edge
                        },
                    }}
                    sx={{
                        paddingTop: '8px', // Custom padding top
                        paddingBottom: '8px', // Custom padding bottom
                    }}
                >
                    {/* Add New Story Box */}
                    <Box
                        display="inline-block"
                        onClick={handleAddNewStory}
                        sx={{
                            cursor: 'pointer',
                            width: isMobile ? '50px' : '100px',
                            height: isMobile ? '75px' : '100px', // Height changes on hover
                            // backgroundColor: isDark ? '' : '#f0f0f0',
                            border: `2px solid white`,
                            borderRadius: '12px', // Rounded soft corners for iOS-like effect
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginRight: 2,
                            position: 'relative',
                            transition: 'height 0.3s ease', // Smooth transition for height change
                        }}
                    >
                        <AddIcon style={{ fontSize: '48px' }} />
                    </Box>

                    {/* Poster Boxes */}
                    {ownWorksFields.map((item, index) => (
                        <Box
                            key={item.id}
                            display="inline-block"
                            onClick={() => handleNextStory(index)}
                            sx={{
                                cursor: 'pointer',
                                border: selectedStoryIndex === index
                                    ? `1px solid ${!isDark ? theme.palette.primary.main : 'white'}` // Thin border when selected
                                    : 'none',
                                marginRight: 2,
                                position: 'relative',
                                width: isMobile ? 'auto' : 'auto', // Consistent width
                                maxWidth: isMobile ? '100px' : '150px',
                                height: isMobile ? '75px' : '100px', // Height changes on hover
                                transform: selectedStoryIndex === index ? 'scale(1.1)' : 'scale(1)', // Slight growth when selected
                                transition: 'transform 0.3s ease-in-out, border 0.3s ease', // Smooth transition for scaling and border
                                flexShrink: 0, // Prevent shrinking
                                borderRadius: '12px', // Rounded soft corners for iOS-like effect
                                overflow: 'visible', // Ensure content overflow is not hidden
                                boxShadow: selectedStoryIndex === index
                                    ? `0px 4px 12px rgba(0, 0, 0, 0.1)` // Optional: subtle shadow for selected item
                                    : 'none',
                                boxSizing: 'border-box', // Include border in box size calculation
                            }}
                        >

                            {watchedOwnWorks.length > index && watchedOwnWorks[index]?.poster ? (
                                <img
                                    src={watchedOwnWorks[index].poster}
                                    alt="Poster"
                                    style={{
                                        width: '100%', // Ensure the image fills the box
                                        height: '100%', // Ensure the image takes the height of the parent
                                        objectFit: 'cover', // Ensures image fits without distortion
                                        borderRadius: '12px', // Ensure image respects rounded corners
                                        boxSizing: 'border-box', // Include border in image sizing
                                    }}
                                />
                            ) : (
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    sx={{
                                        width: isMobile ? '50px' : '100px',
                                        height: isMobile ? '75px' : '100px', // Height changes on hover
                                        backgroundColor: 'ligh grey',
                                        boxSizing: 'border-box', // Include border in box size calculation
                                        borderRadius: '12px', // Rounded soft corners for iOS-like effect
                                    }}
                                >
                                    <Typography variant="caption">
                                        {watchedOwnWorks[index]?.name || 'No Title'}
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    ))}

                </Grid>


                <Grid item xs={12} >
                    {ownWorksFields.length > 0 && (
                        <Box>
                            <Box display="flex" alignItems="center" justifyContent={"space-between"} >
                                {/* {!isMobile && <Divider sx={{ color: 'grey', flexGrow: 1 }}>{`Story: ${currentStory.name || 'No Title'}`}</Divider>} */}
                                <Box display={'flex'} flexDirection={'row'} gap={1} alignItems={'center'} >
                                    <Grid item xs={2}>
                                        <Button
                                            onClick={handlePrivacyToggle}
                                            variant="outlined"
                                            color={watchedOwnWorks[selectedStoryIndex]?.privacy ? 'error' : 'success'}
                                            startIcon={watchedOwnWorks[selectedStoryIndex]?.privacy ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                        >
                                            {(watchedOwnWorks[selectedStoryIndex]?.privacy ? 'Private' : 'Public')}
                                        </Button>

                                    </Grid>
                                    {!isMobile && <Typography item > ← Toggle Between Private and Public mode to be shown in public profile</Typography>}
                                </Box>
                                <Tooltip title="Remove Story">

                                    <Button
                                        onClick={() => handleDialogOpen(selectedStoryIndex)}
                                        variant="outlined"
                                        color="error"
                                        startIcon={<DeleteForeverIcon />}
                                    >
                                        Delete
                                    </Button>

                                </Tooltip>

                            </Box>
                            <Grid container display="flex" spacing={2} marginTop={1} >
                                {/* <Grid item xs={12} sm={3} sx={{
                                    ...(isMobile && {
                                        display: 'flex',
                                        flexDirection: isMobile ? 'column' : 'row', alignItems: 'center',
                                    })
                                }}>
                                    {currentStory.poster ? (
                                        <img
                                            src={currentStory.poster}
                                            alt="Poster"
                                            style={{ width: '100%', height: isMobile ? '200px' : '300px', objectFit: 'contain' }}
                                            sx={{
                                                width: isMobile ? '50px' : '100px',
                                                height: isMobile ? '75px' : '100px', // Height changes on hover
                                                backgroundColor: 'ligh grey',
                                                boxSizing: 'border-box', // Include border in box size calculation
                                                border: `2px solid white`, borderRadius: '12px', // Rounded soft corners for iOS-like effect
                                            }}
                                        />
                                    ) : (
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            style={{
                                                width: '100%', height: '300px', backgroundColor: 'light grey',
                                                border: `2px solid white`, borderRadius: '12px', // Rounded soft corners for iOS-like effect

                                            }}
                                            onClick={() => {
                                                handleSave();  // Trigger saveForm when user clicks on this area
                                                // Optionally, trigger the file upload dialog here as well if needed
                                            }}
                                        >
                                            <CloudUploadIcon className="upload-icon" />
                                        </Box>
                                    )}
                                    <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', alignContent: 'flex-end', marginTop: '-20px', marginBottom: '10px' }}>
                                        <UploadModel
                                            key={`${selectedStoryIndex}-${currentStory.logLine || ''}-${currentStory.genre_v2 || ''}-${currentStory.name || ''}`}
                                            upload_state={!currentStory.poster}
                                            initialLogline={currentStory.logLine || ''}
                                            initialFormat={currentStory.format || ''}
                                            initialGenre={currentStory.genre_v2 || []}
                                            initialTitle={currentStory.name || ''}
                                            ip_id={currentStory._id || ''}
                                            remainingGenerations={100}
                                            onSuccess={(s3Url) => handleUploadSuccess(s3Url, selectedStoryIndex)}
                                            selectedStoryIndex={selectedStoryIndex}  // Pass the index
                                            isMobile={isMobile}
                                        />
                                        <Button onClick={() => setValue(`ownWorks.portfolio.${selectedStoryIndex}.poster`, '')}><CloseIcon variant="filled" sx={{ marginTop: 'auto' }} /> </Button>
                                    </div>
                                </Grid> */}
                                <Grid item xs={12} sm={6}>
                                    <Grid container spacing={2} alignItems={'center'}>
                                        <Grid item xs={12} md={8}>
                                            <TextField
                                                inputRef={titleRef}
                                                fullWidth
                                                label={config.ownWorks.portfolio.title.label}
                                                margin="normal"
                                                size="small"
                                                helperText={errors?.ownWorks?.portfolio?.[selectedStoryIndex]?.name?.message || config.ownWorks.portfolio.title.helperText}
                                                error={!!errors?.ownWorks?.portfolio?.[selectedStoryIndex]?.name}
                                                onChange={(e) => setValue(`ownWorks.portfolio.${selectedStoryIndex}.name`, e.target.value)}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={4}>
                                            <Button
                                                onClick={() => setOpenCoWritersDialog(true)}
                                                color="primary"
                                                variant="outlined"
                                                startIcon={<GroupsIcon />}
                                                sx={{
                                                    marginTop: '-18px',
                                                    whiteSpace: 'nowrap', // Prevents the text from wrapping
                                                    // minWidth: '150px',    // Optional: Ensures enough width for the label and icon
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                Co-writers
                                            </Button>
                                        </Grid>

                                        {/* CoAuthorsDialog */}
                                        <CoWritersDialog
                                            key={selectedStoryIndex}  // This will force remount on selectedStoryIndex change
                                            open={openCoWritersDialog}
                                            onClose={() => setOpenCoWritersDialog(false)}
                                            originalIpId={getValues(`ownWorks.portfolio[${selectedStoryIndex}]._id`)}
                                            coWriters={getValues(`ownWorks.portfolio[${selectedStoryIndex}].coWriters`)}
                                        />
                                    </Grid>

                                    <TextField
                                        inputRef={logLineRef}
                                        fullWidth
                                        label={config.ownWorks.portfolio.logLine.label}
                                        margin="normal"
                                        size="small"
                                        multiline
                                        minRows={4}
                                        maxRows={5}
                                        helperText={errors?.ownWorks?.portfolio?.[selectedStoryIndex]?.logLine?.message || config.ownWorks.portfolio.logLine.helperText}
                                        error={!!errors?.ownWorks?.portfolio?.[selectedStoryIndex]?.logLine}
                                        onChange={(e) => setValue(`ownWorks.portfolio.${selectedStoryIndex}.logLine`, e.target.value)}
                                    />
                                    <Autocomplete
                                        multiple // Enable multiple selections
                                        options={config.ownWorks.portfolio.genre.options}
                                        getOptionLabel={(option) => option}
                                        value={currentStory.genre_v2 || []} // Ensure value is an array for multiple selections
                                        onChange={(event, value) => setValue(`ownWorks.portfolio.${selectedStoryIndex}.genre_v2`, value)} // Update with selected array of values
                                        isOptionEqualToValue={(option, value) => option === value || value === null}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={config.ownWorks.portfolio.genre.label}
                                                placeholder=""
                                                margin="normal"
                                                size="small"
                                                helperText={errors?.ownWorks?.portfolio?.[selectedStoryIndex]?.genre_v2?.message || config.ownWorks.portfolio.genre.helperText}
                                                error={!!errors?.ownWorks?.portfolio?.[selectedStoryIndex]?.genre_v2}
                                            />
                                        )}
                                    />
                                    <TextField
                                        inputRef={pitchReasonRef}
                                        fullWidth
                                        label={
                                            config.ownWorks.portfolio?.pitchReason?.label ||
                                            `Why ${getValues(`ownWorks.portfolio.${selectedStoryIndex}.name`)
                                                ? `"${getValues(`ownWorks.portfolio.${selectedStoryIndex}.name`)}"`
                                                : "is this story"
                                            } ${getValues(`ownWorks.portfolio.${selectedStoryIndex}.format`) || "Film/Series/Game"
                                            } worthy?`
                                        }
                                        margin="normal"
                                        size="small"
                                        multiline
                                        minRows={4}
                                        maxRows={5}
                                        // helperText={errors?.ownWorks?.portfolio?.[selectedStoryIndex]?.pitchReason?.message || ''}
                                        error={!!errors?.ownWorks?.portfolio?.[selectedStoryIndex]?.pitchReason}
                                        onChange={(e) => setValue(`ownWorks.portfolio.${selectedStoryIndex}.pitchReason`, e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Autocomplete 
                                        options={config.ownWorks.portfolio.format.options}
                                        getOptionLabel={(option) => option}
                                        value={currentStory.format || null}
                                        onChange={(event, value) => setValue(`ownWorks.portfolio.${selectedStoryIndex}.format`, value)}
                                        isOptionEqualToValue={(option, value) => option === value || value === null}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={config.ownWorks.portfolio.format.label}
                                                placeholder=""
                                                margin="normal"
                                                size="small"
                                                // helperText={errors?.ownWorks?.portfolio?.[selectedStoryIndex]?.format?.message || config.ownWorks.portfolio.format.helperText}
                                                error={!!errors?.ownWorks?.portfolio?.[selectedStoryIndex]?.format}
                                            />
                                        )}
                                    />
                                    {config.ownWorks.portfolio.formatDetails.conditionalFormats.runtimeOnly.includes(currentStory.format) && (
                                        <TextField 
                                            type="number"
                                            // label={config.ownWorks.portfolio.formatDetails.runtime.label}
                                            helperText={config.ownWorks.portfolio.formatDetails.runtime.helperText}
                                            value={currentStory.formatDetails?.runtime || ''}
                                            onChange={(e) => setValue(`ownWorks.portfolio.${selectedStoryIndex}.formatDetails.runtime`, Number(e.target.value))}
                                            error={!!errors?.ownWorks?.portfolio?.[selectedStoryIndex]?.formatDetails?.runtime}
                                        />
                                    )}

                                    {config.ownWorks.portfolio.formatDetails.conditionalFormats.seriesDetails.includes(currentStory.format) && (
                                        <Box>
                                            <Box display="flex" gap={2}>
                                                <TextField
                                                    type="number"
                                                    label={config.ownWorks.portfolio.formatDetails.seasons.label}
                                                    // helperText={config.ownWorks.portfolio.formatDetails.seasons.helperText}
                                                    value={currentStory.formatDetails?.seasons || ''}
                                                    onChange={(e) => setValue(`ownWorks.portfolio.${selectedStoryIndex}.formatDetails.seasons`, Number(e.target.value))}
                                                    error={!!errors?.ownWorks?.portfolio?.[selectedStoryIndex]?.formatDetails?.seasons}
                                                    sx={{ flex: 1 }}
                                                />

                                                <TextField
                                                    type="number"
                                                    label={config.ownWorks.portfolio.formatDetails.episodes.label}
                                                    // helperText={config.ownWorks.portfolio.formatDetails.episodes.helperText}
                                                    value={currentStory.formatDetails?.episodes || ''}
                                                    onChange={(e) => setValue(`ownWorks.portfolio.${selectedStoryIndex}.formatDetails.episodes`, Number(e.target.value))}
                                                    error={!!errors?.ownWorks?.portfolio?.[selectedStoryIndex]?.formatDetails?.episodes}
                                                    sx={{ flex: 1 }}
                                                />
                                            </Box>

                                            <TextField
                                                type="number"
                                                label={config.ownWorks.portfolio.formatDetails.episodeRuntime.label}
                                                // helperText={config.ownWorks.portfolio.formatDetails.episodeRuntime.helperText}
                                                value={currentStory.formatDetails?.episodeRuntime || ''}
                                                onChange={(e) => setValue(`ownWorks.portfolio.${selectedStoryIndex}.formatDetails.episodeRuntime`, Number(e.target.value))}
                                                error={!!errors?.ownWorks?.portfolio?.[selectedStoryIndex]?.formatDetails?.episodeRuntime}
                                                sx={{ marginTop: 2 }}
                                            />
                                        </Box>
                                    )}

                                    <Autocomplete
                                        options={config.ownWorks.portfolio.status.options}
                                        getOptionLabel={(option) => option}
                                        value={currentStory.status || null}
                                        onChange={(event, value) => setValue(`ownWorks.portfolio.${selectedStoryIndex}.status`, value)}
                                        isOptionEqualToValue={(option, value) => option === value || value === null}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={config.ownWorks.portfolio.status.label}
                                                placeholder=""
                                                margin="normal"
                                                size="small"
                                                helperText={errors?.ownWorks?.portfolio?.[selectedStoryIndex]?.status?.message || config.ownWorks.portfolio.status.helperText}
                                                error={!!errors?.ownWorks?.portfolio?.[selectedStoryIndex]?.status}
                                            />
                                        )}
                                    />
                                    {currentStory.status === "Available" && (
                                        <Autocomplete
                                            multiple
                                            options={config.ownWorks.portfolio.assetsAvailable.options}
                                            getOptionLabel={(option) => option}
                                            value={currentStory.assetsAvailable || []}
                                            onChange={(event, value) => setValue(`ownWorks.portfolio.${selectedStoryIndex}.assetsAvailable`, value)}
                                            isOptionEqualToValue={(option, value) => option === value || value.length === 0}
                                            renderTags={(value, getTagProps) =>
                                                value.map((option, index) => (
                                                    <Chip key={index} label={option} {...getTagProps({ index })} />
                                                ))
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    inputRef={assetsAvailableRef}
                                                    label={config.ownWorks.portfolio.assetsAvailable.label}
                                                    placeholder="Select available assets"
                                                    margin="normal"
                                                    size="small"
                                                    helperText={errors?.ownWorks?.portfolio?.[selectedStoryIndex]?.assetsAvailable?.message || config.ownWorks.portfolio.assetsAvailable.helperText}
                                                    error={!!errors?.ownWorks?.portfolio?.[selectedStoryIndex]?.assetsAvailable}
                                                />
                                            )}
                                        />
                                    )}
                                    {currentStory.status === "Acquired" && (
                                        <Autocomplete
                                            multiple
                                            options={config.ownWorks.portfolio.credit.options}
                                            getOptionLabel={(option) => option}
                                            value={currentStory.credit || []}
                                            onChange={(event, value) => setValue(`ownWorks.portfolio.${selectedStoryIndex}.credit`, value)}
                                            isOptionEqualToValue={(option, value) => option === value || value.length === 0}
                                            renderTags={(value, getTagProps) =>
                                                value.map((option, index) => {
                                                    const { key, ...tagProps } = getTagProps({ index });
                                                    return <Chip key={index} label={option} {...tagProps} />;
                                                })
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={config.ownWorks.portfolio.credit.label}
                                                    placeholder=""
                                                    margin="normal"
                                                    size="small"
                                                    helperText={errors?.ownWorks?.portfolio?.[selectedStoryIndex]?.credit?.message || config.ownWorks.portfolio.credit.helperText}
                                                    error={!!errors?.ownWorks?.portfolio?.[selectedStoryIndex]?.credit}
                                                />
                                            )}
                                        />
                                    )}
                                    <Autocomplete
                                        options={config.userDetails.languageOptions.availableLanguages}
                                        getOptionLabel={(option) => option}
                                        value={currentStory.language || null}
                                        onChange={(event, value) => setValue(`ownWorks.portfolio.${selectedStoryIndex}.language`, value)}
                                        isOptionEqualToValue={(option, value) => option === value || value === null}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                inputRef={languageRef}
                                                label="Original Language"
                                                placeholder="Select language"
                                                margin="normal"
                                                size="small"
                                                // helperText={errors?.ownWorks?.portfolio?.[selectedStoryIndex]?.language?.message || ''}
                                                error={!!errors?.ownWorks?.portfolio?.[selectedStoryIndex]?.language}
                                            />
                                        )}
                                    />
                                    <Autocomplete
                                        options={config.userDetails.languageOptions.availableLanguages}
                                        getOptionLabel={(option) => option}
                                        value={currentStory.assetsLanguage || null}
                                        onChange={(event, value) => setValue(`ownWorks.portfolio.${selectedStoryIndex}.assetsLanguage`, value)}
                                        isOptionEqualToValue={(option, value) => option === value || value === null}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                inputRef={assetsLanguageRef}
                                                label="Assets Language"
                                                placeholder="Select language"
                                                margin="normal"
                                                size="small"
                                                // helperText={errors?.ownWorks?.portfolio?.[selectedStoryIndex]?.assetsLanguage?.message || ''}
                                                error={!!errors?.ownWorks?.portfolio?.[selectedStoryIndex]?.assetsLanguage}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3} sx={{
                                    ...(isMobile && {
                                        display: 'flex',
                                        flexDirection: isMobile ? 'column' : 'row', alignItems: 'center',
                                    })
                                }}>
                                    {currentStory.poster ? (
                                        <img
                                            src={currentStory.poster}
                                            alt="Poster"
                                            style={{ width: '100%', height: isMobile ? '200px' : '300px', objectFit: 'contain' }}
                                            sx={{
                                                width: isMobile ? '50px' : '100px',
                                                height: isMobile ? '75px' : '100px', // Height changes on hover
                                                backgroundColor: 'ligh grey',
                                                boxSizing: 'border-box', // Include border in box size calculation
                                                border: `2px solid white`, borderRadius: '12px', // Rounded soft corners for iOS-like effect
                                            }}
                                        />
                                    ) : (
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            style={{
                                                width: '100%', height: '300px', backgroundColor: 'light grey',
                                                border: `2px solid white`, borderRadius: '12px', // Rounded soft corners for iOS-like effect

                                            }}
                                            onClick={() => {
                                                handleSave();  // Trigger saveForm when user clicks on this area
                                                // Optionally, trigger the file upload dialog here as well if needed
                                            }}
                                        >
                                            <CloudUploadIcon className="upload-icon" />
                                        </Box>
                                    )}
                                    <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', alignContent: 'flex-end', marginTop: '-20px', marginBottom: '10px' }}>
                                        <UploadModel
                                            key={`${selectedStoryIndex}-${currentStory.logLine || ''}-${currentStory.genre_v2 || ''}-${currentStory.name || ''}`}
                                            upload_state={!currentStory.poster}
                                            initialLogline={currentStory.logLine || ''}
                                            initialFormat={currentStory.format || ''}
                                            initialGenre={currentStory.genre_v2 || []}
                                            initialTitle={currentStory.name || ''}
                                            ip_id={currentStory._id || ''}
                                            remainingGenerations={100}
                                            onSuccess={(s3Url) => handleUploadSuccess(s3Url, selectedStoryIndex)}
                                            selectedStoryIndex={selectedStoryIndex}  // Pass the index
                                            isMobile={isMobile}
                                        />
                                        <Button onClick={() => setValue(`ownWorks.portfolio.${selectedStoryIndex}.poster`, '')}><CloseIcon variant="filled" sx={{ marginTop: 'auto' }} /> </Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </Box>
                    )}
                </Grid>
            </Grid>

            <Dialog open={openDialog} onClose={handleDialogClose}>
                <DialogTitle>Delete Story</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this story? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteStory} color="secondary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openInvitingMessage} onClose={() => setOpenInvitingMessage(false)}>
                <DialogTitle>{config.ownWorks.invitingMessage.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {config.ownWorks.invitingMessage.content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setOpenInvitingMessage(false);
                        handleAddNewStory();
                    }} color="primary">
                        Yes
                    </Button>
                    <Button onClick={() => setOpenInvitingMessage(false)} color="secondary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>


        </Box>
    );
};

export default MyStories;
