import React, { useState, useEffect } from 'react';
import {
    Dialog, DialogActions, DialogContent, DialogTitle, Divider, Chip, Autocomplete, CircularProgress,
    TextField, Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Typography, Tooltip, Paper
} from '@mui/material';
import { DeleteForever as DeleteForeverIcon } from '@mui/icons-material';
import { useAxios } from '../../../contexts/AxiosContext';

const CoWritersDialog = ({ open, onClose, originalIpId, coWriters: initialCoWriters }) => {
    const axios = useAxios();
    const [searchText, setSearchText] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [coWriters, setCoWriters] = useState(initialCoWriters || []); // Co-writers from props
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        if (searchText.trim()) {
            handleSearch();
        }
    }, [searchText]);

    // Handle search for users to invite
    const handleSearch = async () => {
        if (!searchText.trim()) return;
        setLoading(true);
        try {
            setLoading(true); // Start loading
            const response = await axios.get(`/content/search-users/${originalIpId}`, { params: { searchText } });
            console.log(response.data)

            if (response.data.length === 0) {
                setError('No users found matching your criteria.'); // Specific message for no results
            } else {
                setSearchResults(response.data); // Display search results below search bar
                setError(''); // Clear any previous errors
            }
        } catch (error) {
            if (error.response) {
                // Server responded with a status other than 200
                setError(error.response.data.message || 'Failed to search for users');
            } else {
                // Network error or some other issue
                setError('An unexpected error occurred. Please try again later.');
            }
        } finally {
            setLoading(false); // End loading
        }
    };

    // Invite a co-writer
    const handleInvite = async (userId) => {
        try {
            const response = await axios.post(`/content/originalip/${originalIpId}/invite-cowriter`, { userId });
            setCoWriters([...coWriters, { userId, status: 'pending', invitedAt: new Date().toISOString() }]);
            setSearchResults(searchResults.filter(user => user.userId !== userId)); // Remove from search results
        } catch (error) {
            setError('Failed to invite user');
        }
    };

    // Delete a co-writer
    const handleDelete = async (userId) => {
        if (!window.confirm('Are you sure you want to remove this invitation?')) return;
        try {
            await axios.delete(`/content/originalip/${originalIpId}/remove-cowriter`, { data: { userId } });
            setCoWriters(coWriters.filter(ca => ca.userId !== userId));
        } catch (error) {
            setError('Failed to delete invitation');
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Manage Co-writers</DialogTitle>
            <DialogContent>
                {error && <Typography color="error">{error}</Typography>}

                {/* Search Bar */}
                <Autocomplete
                    freeSolo
                    fullWidth
                    options={searchResults}
                    getOptionLabel={(option) => option.fullName || option.email} // Fallback to email if fullName is missing
                    onInputChange={(event, value) => setSearchText(value)} // Dynamically update search text
                    onChange={(event, newValue) => {
                        if (newValue && newValue.userId) {
                            handleInvite(newValue.userId); // Invite on selecting a result
                        }
                    }}
                    loading={loading}
                    renderOption={(props, option) => (
                        <li {...props}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {/* Profile Picture */}
                                {option.profilePath ? (
                                    <img
                                        src={option.profilePath}
                                        alt="Profile"
                                        style={{ width: 40, height: 40, borderRadius: '50%', marginRight: 10 }}
                                    />
                                ) : (
                                    // Graceful fallback if no profile picture
                                    <div
                                        style={{
                                            width: 40,
                                            height: 40,
                                            borderRadius: '50%',
                                            backgroundColor: '#ccc',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            marginRight: 10
                                        }}
                                    >
                                        <span>?</span>
                                    </div>
                                )}

                                {/* Name and Location */}
                                <div>
                                    <div>{option.fullName || 'Unknown Name'}</div>
                                    <div style={{ fontSize: '0.8em', color: '#888' }}>
                                        {option.location || 'Location not available'}
                                    </div>
                                </div>
                            </div>
                        </li>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Search for users to invite"
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                        />
                    )}
                />

                <Divider style={{ margin: '20px 0' }} />

                {/* Co-writers Table */}
                {coWriters.length === 0 ? (
                    <Typography>No co-writers yet.</Typography>
                ) : (
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>Co-Writer</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Invited At</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {coWriters.map((ca) => (
                                    <TableRow key={ca.userId}>
                                        <TableCell>
                                            {ca.profilePath ? (
                                                <img
                                                    src={ca.profilePath}
                                                    alt="Profile"
                                                    style={{ width: 40, height: 40, borderRadius: '50%', marginRight: 10 }}
                                                />
                                            ) : (
                                                // Graceful fallback if no profile picture
                                                <div
                                                    style={{
                                                        width: 40,
                                                        height: 40,
                                                        borderRadius: '50%',
                                                        backgroundColor: '#ccc',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        marginRight: 10
                                                    }}
                                                >
                                                    <span>?</span>
                                                </div>
                                            )}
                                        </TableCell>
                                        <TableCell>{ca.fullName}</TableCell> {/* Replace with user name if available */}
                                        <TableCell>
                                            <Chip
                                                label={ca.status}
                                                color={ca.status === 'pending' ? 'warning' : ca.status === 'accepted' ? 'success' : 'error'}
                                            />
                                        </TableCell>
                                        <TableCell>{new Date(ca.invitedAt).toLocaleString()}</TableCell>
                                        <TableCell>
                                            <Tooltip title="Remove co-writer">
                                                <IconButton onClick={() => handleDelete(ca.userId)} color="error">
                                                    <DeleteForeverIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default CoWritersDialog;
