// InviteMemberDialog.js
import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, Autocomplete, CircularProgress, Avatar, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { useAxios } from '../../../contexts/AxiosContext'; // Adjust the import as per your project structure

function InviteMemberDialog({ open, onClose, teamId, excludeUserIds }) {
    const [searchText, setSearchText] = useState('');
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const axios = useAxios();

    useEffect(() => {
        if (searchText.trim()) {
            const delayDebounceFn = setTimeout(() => {
                fetchUsers();
            }, 300);
            return () => clearTimeout(delayDebounceFn);
        }
    }, [searchText]);

    const fetchUsers = async () => {
        setLoading(true);
        try {
            const response = await axios.post('/event/team/search-users', { searchText, exclude: excludeUserIds });
            setUsers(response.data);
        } catch (error) {
            console.error('Failed to search users:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleInvite = async (userId) => {
        try {
            await axios.post(`/event/team/${teamId}/invite`, { userId, role:"team_member" });
            onClose(); // Close the dialog on successful invite
        } catch (error) {
            console.error('Failed to invite user:', error);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>Invite Members</DialogTitle>
            <DialogContent>
                <Autocomplete
                    freeSolo
                    options={users}
                    getOptionLabel={(option) => option.fullName || option.email}
                    onChange={(event, newValue) => {
                        if (newValue) {
                            handleInvite(newValue.userId);
                        }
                    }}
                    renderOption={(props, option) => (
                        <ListItem {...props} key={option.userId}>
                            <ListItemAvatar>
                                <Avatar src={option.profilePath || "/path/to/default/avatar.jpg"} />
                            </ListItemAvatar>
                            <ListItemText primary={option.fullName} secondary={option.email} />
                        </ListItem>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Search for users to invite"
                            variant="outlined"
                            onChange={(e) => setSearchText(e.target.value)}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                        />
                    )}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

export default InviteMemberDialog;
