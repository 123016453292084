
import React, { useState } from 'react';
import { Grid, TextField, Button, Stepper, Step, StepLabel, Autocomplete, Typography, Box, Tooltip, IconButton, Card, CardContent, MenuItem, Slider, Checkbox } from '@mui/material';
import { useFormContext, Controller, useFieldArray } from 'react-hook-form';
import config from '../config.json';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useAxios } from '../../../contexts/AxiosContext';
import { handleFileUpload, handleRemoveFile } from '../helpers/FileUploadRemove';
import Lottie from 'lottie-react';
import uploadAnimation from '../lottie/uploadAnimation.json';
import LocationField from '../FormAddons/LocationField';
import Qualifications from './Qualifications';
import LanguageProficiencyComponent from './LanguageProficiencyComponent';
import { FaLinkedin, FaImdb, FaGlobe } from 'react-icons/fa';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import PersonIcon from '@mui/icons-material/Person';
import LanguageIcon from '@mui/icons-material/Language';
import LinkIcon from '@mui/icons-material/Link';
import SchoolIcon from '@mui/icons-material/School';
const defaultProfileImg = 'https://via.placeholder.com/150';  // You can replace this URL with any suitable dummy image URL
const getMaxDateFor10YearsAgo = () => {
    const today = new Date();
    today.setFullYear(today.getFullYear() - 10); // 10 years back
    return today.toISOString().substring(0, 10); // Format YYYY-MM-DD
};

const jobTitlesOptions = [
    "Writer", "Director", "DOP", "Producer", "Editor", "Accountant", "Academic Advisor", "Academic Researcher", "Actor/Actress", "Advertising Account Manager",
    "Affiliate Marketer", "AI/Machine Learning Engineer", "Animator", "Architect", "Art Conservator", "Art Director", "Aerospace Engineer", "Automotive Engineer", "Auditor", "Barista",
    "Blockchain Developer", "Brand Manager", "Budget Analyst", "Business Analyst", "Business Consultant", "Business Manager", "Catering Manager", "Chef", "Cinematographer", "Cloud Architect",
    "Compliance Officer", "Concierge", "Content Creator (YouTuber, Blogger)", "Content Strategist", "Copywriter", "Corporate Lawyer", "Corporate Treasurer", "Costume Designer", "Creative Director", "Criminal Defense Lawyer",
    "Customer Relationship Manager", "Cybersecurity Specialist", "Data Analyst", "Data Scientist", "Database Administrator", "Dentist", "DevOps Engineer", "Dietitian/Nutritionist", "Digital Marketing Specialist", "Doctor (General Practitioner)",
    "Diversity and Inclusion Officer", "E-Commerce Manager", "Early Childhood Educator", "Electrical Engineer", "E-Learning Specialist", "Electrical Engineer", "Employee Engagement Manager", "Engineer", "Environmental Consultant",
    "Environmental Engineer", "Environmental Lawyer", "Event Coordinator", "Event Manager", "Event Planner", "Export Manager", "Fashion Designer", "Financial Analyst", "Financial Planner", "Firefighter",
    "Fleet Manager", "Flight Attendant", "Florist", "Food and Beverage Manager", "Forensic Accountant", "Freight Forwarder", "Full Stack Developer", "Game Designer", "Game Developer", "Graphic Designer",
    "Growth Hacker", "Healthcare Administrator", "Hedge Fund Manager", "Hotel Manager", "HR Analyst", "HR Business Partner", "HR Consultant", "HR Generalist", "Human Resources Manager", "Illustrator",
    "Immigration Lawyer", "Industrial Designer", "Information Security Analyst", "Instructional Designer", "Insurance Underwriter", "Interior Designer", "Investment Banker", "IT Support Specialist", "Judge", "Labor Relations Specialist",
    "Landscape Architect", "Lawyer/Attorney", "Lead Generation Specialist", "Legal Assistant", "Legal Consultant", "Legal Researcher", "Logistics Coordinator", "Logistics Manager", "Marketing Manager", "Market Research Analyst",
    "Mechanical Engineer", "Medical Lab Technician", "Mobile App Developer", "Mortgage Broker", "Music Composer", "Network Engineer", "Nurse", "Occupational Therapist", "Operations Analyst", "Operations Manager",
    "Paramedic", "Pastry Chef", "Pet Groomer", "Pharmacist", "Photographer", "Physical Therapist", "Plumber", "Podcast Producer", "Procurement Manager", "Product Manager (Tech)",
    "Product Marketing Manager", "Project Engineer", "Project Manager", "Psychiatrist", "Psychologist", "Public Relations Specialist", "Radiologist", "Real Estate Agent", "Research Scientist", "Restaurant Manager",
    "Risk Manager", "Robotics Engineer", "Sales Manager", "School Administrator", "School Counselor", "SEO Specialist", "Set Designer", "Social Media Manager", "Software Developer", "Software Engineer",
    "Solutions Architect", "Sound Engineer", "Special Education Teacher", "Speech-Language Pathologist", "Structural Engineer", "Supply Chain Manager", "Surgeon", "Surveyor", "Systems Administrator", "Tax Advisor",
    "Teacher", "Technical Writer", "Technology & Software", "Tour Guide", "Training and Development Manager", "UX/UI Designer", "Venture Capitalist", "Veterinarian", "Video Editor", "Voiceover Artist",
    "Warehouse Manager", "Web Developer"
];

const steps = [
    { label: 'Bio', key: 'bio', icon: <PersonIcon /> },
    { label: 'Languages Known', key: 'languages_v2', icon: <LanguageIcon /> },
    { label: 'Social Links', key: 'socialLinks', icon: <LinkIcon /> },
    { label: 'Qualification', key: 'qualifications', icon: <SchoolIcon /> },
];

const UserDetails = ({ userId, handleNextSection, theme, isMobile, step }) => {
    const { control, formState: { errors }, trigger, watch, handleSubmit, setValue, getValues } = useFormContext();
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'userDetails.languages'
    });
    const [activeStep, setActiveStep] = useState(step || 0);
    const axios = useAxios();
    const profilePath = watch('userDetails.profile_path.value', '');
    const [isUploading, setIsUploading] = useState(false);
    const isDark = theme.palette.mode === "dark"
    const maxDate = getMaxDateFor10YearsAgo(); // Max date (10 years ago)

    const handleNext = async () => {
        const result = await trigger();  // Trigger validation
        if (result) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
            const firstError = Object.keys(errors)[0];
            if (firstError) {
                const element = document.querySelector(`[name="${firstError}"]`);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    element.focus();
                }
            }
        }
    };

    const handleBack = async () => {
        const result = await trigger();  // Trigger validation
        if (result) {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        } else {
            const firstError = Object.keys(errors)[0];
            if (firstError) {
                const element = document.querySelector(`[name="${firstError}"]`);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    element.focus();
                }
            }
        }
    };

    const handleStepClick = async (index) => {
        const result = await trigger();  // Trigger validation
        if (result) {
            setActiveStep(index);
        } else {
            const firstError = Object.keys(errors)[0];
            if (firstError) {
                const element = document.querySelector(`[name="${firstError}"]`);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    element.focus();
                }
            }
        }
    };

    // const onSubmit = (data) => console.log(data);

    const handleAddLanguage = () => {
        append({ value: '', privacy: false, lsrw: { l: false, s: 0, r: 0, w: 0 } });
    };

    const handleRemoveLanguage = (index) => {
        remove(index);
    };

    const selectedLanguages = fields.map(field => field.value);

    return (
        <Box>
            {/* <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 3 }}>
                {steps.map((step, index) => (
                    <Step key={step.label} onClick={() => handleStepClick(index)}>
                        <Tooltip title={config.userDetails.tooltips[step.key]} arrow>
                            <StepLabel>{step.label}</StepLabel>
                        </Tooltip>
                    </Step>
                ))}
            </Stepper> */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    ...(isMobile && { marginLeft: '-30px', marginRight: '-30px' })
                }}
            >
                {!isMobile && <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1, marginTop: "3px" }}
                    startIcon={<ArrowBackIcon />}
                >
                    Back
                </Button>}
                <Box sx={{ flex: '1 1 auto', overflowX: "auto" }}>
                    <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 3, '& .MuiStepConnector-root': { display: 'none' } }}>
                        {steps.map((step, index) => (
                            <Step key={step.label} onClick={() => handleStepClick(index)}>
                                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                    <IconButton
                                        onClick={() => handleStepClick(index)}
                                        sx={{
                                            borderRadius: '8px',
                                            border: activeStep === index ? `2px solid ${!isDark ? theme.palette.primary.main : 'white'}` : '2px solid transparent',
                                            color: activeStep === index ? (!isDark ? theme.palette.primary.main : 'white') : 'inherit',
                                            transition: 'border 0.3s ease-in-out',
                                            padding: '6px 6px',
                                            margin: '0 10px',
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {step.icon}
                                        {!isMobile && (<Typography variant="button" sx={{ ml: 1 }}>
                                            {step.label}
                                        </Typography>)}
                                    </IconButton>
                                    {!isMobile && (index < steps.length - 1 && (
                                        <Box sx={{ flexGrow: 1, height: '1px', backgroundColor: '#DDD' }} />
                                    ))}
                                </Box>
                            </Step>
                        ))}
                    </Stepper>
                </Box>
                {!isMobile && <Button
                    onClick={activeStep === steps.length - 1 ? handleNextSection : handleNext}
                    endIcon={<ArrowForwardIcon />}
                    sx={{ marginTop: "3px" }}
                >
                    {activeStep === steps.length - 1 ? 'Go To Next Section' : 'Next'}
                </Button>}
            </Box>
            <Grid container spacing={2} style={{ justifyContent: 'center' }}>
                {activeStep === 0 && (
                    <>
                        <Grid item style={{ width: isMobile ? '95vw' : '60vw' }}
                            container
                            sx={{
                                flexDirection: 'row',
                                ...(isMobile && { flexWrap: 'nowrap', flexDirection: 'column' })
                            }}
                        >
                            <Grid item xs={12} style={{
                                display: 'flex', justifyContent: 'center', alignItems: 'center',
                                ...(isMobile && { flexDirection: 'column', flexWrap: 'wrap' })

                            }}>
                                {isUploading ? (
                                    <Lottie animationData={uploadAnimation} style={{ width: '50%', height: 'auto' }} />
                                ) : (
                                    <div
                                        className="upload-placeholder"
                                        style={{
                                            width: isMobile ? '100px' : '150px',
                                            height: isMobile ? '100px' : '150px',
                                            borderRadius: '50%',
                                            overflow: 'hidden',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            position: 'relative',
                                            cursor: 'pointer',
                                            margin: '0 auto', // Ensures the div stays centered
                                            marginBottom: isMobile ? '10px' : '10px',
                                            border: '2px solid', // Border to create the circular outline
                                            borderColor: theme.palette.primary.main, // Use the theme's primary color for the outline
                                        }}
                                        onClick={() => document.getElementById('fileInput').click()}
                                    >
                                        {profilePath ? (
                                            <img
                                                src={profilePath}
                                                alt="Profile"
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'cover'
                                                }}
                                            />
                                        ) : (
                                            <CloudUploadIcon
                                                className="upload-icon"
                                                style={{
                                                    width: '75%',
                                                    height: '75%',
                                                    maxHeight: '250px',
                                                    objectFit: 'contain'
                                                }}
                                            />
                                        )}
                                        <input
                                            id="fileInput"
                                            type="file"
                                            accept="image/*"
                                            onChange={async (event) => {
                                                setIsUploading(true);
                                                await handleFileUpload(event, {
                                                    folderName: 'UserProfile',
                                                    fileName: `${userId}`,
                                                    fieldArrayName: 'userDetails.profile_path',
                                                    index: 0,
                                                    setValue,
                                                    getValues,
                                                    userId,
                                                    axios
                                                });
                                                setIsUploading(false);
                                            }}
                                            style={{ display: 'none' }}
                                        />
                                    </div>
                                )}
                                {profilePath && (
                                    <IconButton
                                        className="remove-button"
                                        onClick={() => handleRemoveFile('userDetails.profile_path', 0, setValue, getValues)}
                                    // style={{ position: 'absolute', top: '10px', right: '10px' }}
                                    >
                                        <span className="remove-icon">×</span>
                                    </IconButton>
                                )}
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name="userDetails.fullName.value"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <TextField
                                                fullWidth
                                                label="Full Name"
                                                {...field}
                                                error={!!errors.userDetails?.fullName?.value}
                                                helperText={errors.userDetails?.fullName?.value?.message}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name="userDetails.email.value"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <TextField
                                                fullWidth
                                                label="Email"
                                                {...field}
                                                error={!!errors.userDetails?.email?.value}
                                                helperText={errors.userDetails?.email?.value?.message}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} flexDirection={'row'}>
                                    <Typography>DOB: </Typography>
                                    <Controller
                                        name="userDetails.DOB.value"
                                        control={control}
                                        render={({ field }) => (
                                            <input
                                                type="date"
                                                value={field.value ? new Date(field.value).toISOString().substring(0, 10) : ''}
                                                onChange={(e) => {
                                                    const value = e.target.value ? new Date(e.target.value) : null; // Convert the string date to Date object or null
                                                    field.onChange(value);
                                                }}
                                                max={maxDate} // Restrict to max 10 years ago
                                                style={{
                                                    width: '100%',
                                                    padding: '8px',
                                                    fontSize: '16px',
                                                    backgroundColor: 'white',
                                                    color: 'black',
                                                    border: '1px solid black',
                                                }}
                                                className={errors.userDetails?.DOB?.value ? 'is-invalid' : ''}
                                            />
                                        )}
                                    />

                                    {errors.userDetails?.DOB?.value && (
                                        <span style={{ color: 'red' }}>{errors.userDetails?.DOB?.value.message}</span>
                                    )}
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name="userDetails.phone.value"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                fullWidth
                                                label={config.userDetails.phone}
                                                error={!!errors.userDetails?.phone?.value}
                                                helperText={errors.userDetails?.phone?.value?.message}
                                                {...field}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name="userDetails.jobTitles"
                                        control={control}
                                        render={({ field }) => (
                                            <Autocomplete
                                                multiple
                                                options={jobTitlesOptions} // Replace with your dynamic options
                                                getOptionLabel={(option) => option}
                                                filterSelectedOptions
                                                value={field.value || []}
                                                onChange={(_, newValue) => field.onChange(newValue)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Job Titles"
                                                        fullWidth
                                                        error={!!errors.userDetails?.jobTitles}
                                                        helperText={errors.userDetails?.jobTitles?.message}
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <LocationField />
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                )}
                {activeStep === 1 && (
                    <LanguageProficiencyComponent isDark={isDark} />
                )}
                {activeStep === 2 && (
                    <>
                        <Grid container spacing={2} justifyContent="center" style={{ minHeight: '100vh' }}>
                            <Grid item xs={12} sm={6} md={6} lg={6} style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
                                <Card>
                                    <CardContent>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item>
                                                <FaLinkedin color="#0A66C2" size={24} />
                                            </Grid>
                                            <Grid item xs>
                                                <Controller
                                                    name="userDetails.linkedin.value"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            fullWidth
                                                            label={config.userDetails.linkedin}
                                                            error={!!errors.userDetails?.linkedin?.value}
                                                            helperText={errors.userDetails?.linkedin?.value?.message}
                                                            {...field}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                                <Card style={{ marginTop: '20px' }}> {/* Add margin for spacing between cards */}
                                    <CardContent>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item>
                                                <FaImdb color="#F5C518" size={24} />
                                            </Grid>
                                            <Grid item xs>
                                                <Controller
                                                    name="userDetails.imdbProfileLink.value"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            fullWidth
                                                            label={config.userDetails.imdbProfileLink}
                                                            error={!!errors.userDetails?.imdbProfileLink?.value}
                                                            helperText={errors.userDetails?.imdbProfileLink?.value?.message}
                                                            {...field}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                                <Card style={{ marginTop: '20px' }}> {/* Add margin for spacing between cards */}
                                    <CardContent>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item>
                                                <FaGlobe color="#4285F4" size={24} />
                                            </Grid>
                                            <Grid item xs>
                                                <Controller
                                                    name="userDetails.website.value"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            fullWidth
                                                            label={config.userDetails.website}
                                                            error={!!errors.userDetails?.website?.value}
                                                            helperText={errors.userDetails?.website?.value?.message}
                                                            {...field}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </>
                )}
                {activeStep === 3 && (
                    <Qualifications />
                )}
            </Grid>
        </Box >
    );
};

export default UserDetails;