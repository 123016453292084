import React, { useState, useEffect, useContext } from 'react';
import {
    Dialog, DialogTitle, DialogContent, DialogActions, Button, Table, Skeleton,
    Paper, TableBody, TableCell, TableContainer, TableHead, TableRow, Chip, Typography, CircularProgress
} from '@mui/material';
import { useAxios } from '../../../contexts/AxiosContext'; // Axios context
import { useMediaQuery } from '@mui/material'; // For mobile responsiveness
import { useLogin } from '../../../contexts/LoginContext'; // Importing LoginContext to get userId
import PreviewIcon from '@mui/icons-material/Preview'; // Icon for the image preview

const CoWriterManagementDialog = ({ isOpen, onClose }) => {
    const axios = useAxios(); // Access Axios from context
    const { userId } = useLogin(); // Access userId from Login context
    const [ips, setIps] = useState([]); // State to store fetched IPs
    const [loading, setLoading] = useState(true); // Loading state for data fetching
    const [selectedIpForPreview, setSelectedIpForPreview] = useState(null); // State for tracking preview

    // Media query for mobile responsiveness
    const isMobile = useMediaQuery('(max-width:600px)');
    const posterSize = isMobile ? '75px' : '200px';

    // Fetch the list of IPs where the user is involved
    useEffect(() => {
        if (isOpen) {
            fetchIps();
        }
    }, [isOpen]);

    // Function to fetch IPs
    const fetchIps = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`/content/collaborations/${userId}`); // Fetch based on logged in userId
            console.log(userId, " ", response.data)
            setIps(response.data);
        } catch (error) {
            console.error('Error fetching IPs:', error);
        } finally {
            setLoading(false);
        }
    };

    // Handle Accept Invitation
    const handleAccept = async (ipId) => {
        try {
            await axios.put(`/content/originalip/${ipId}/accept-invitation`, { userId: userId });
            fetchIps(); // Refresh the list after accepting
        } catch (error) {
            console.error('Error accepting invitation:', error);
        }
    };

    // Handle Reject Invitation
    const handleReject = async (ipId) => {
        try {
            await axios.put(`/content/originalip/${ipId}/reject-invitation`, { userId: userId });
            fetchIps(); // Refresh the list after rejecting
        } catch (error) {
            console.error('Error rejecting invitation:', error);
        }
    };

    // Handle preview toggle
    const handlePreview = (ip) => {
        setSelectedIpForPreview(ip);
    };

    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="lg">
            <DialogTitle>Manage Your Co-writer Invitations</DialogTitle>
            <DialogContent>
                {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                        {ips.length === 0 ? (
                            <Typography variant="body1" align="center">
                                You have no IPs you have been invited to collaborate on.
                            </Typography>
                        ) : (
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Poster</TableCell>
                                            <TableCell>IP Name</TableCell>
                                            <TableCell>Log Line</TableCell>
                                            <TableCell>Genre</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {ips.map((ip) => (
                                            <TableRow key={ip.id}>
                                                {/* Poster: Render thumbnail */}
                                                <TableCell>
                                                    <div style={{ width: posterSize, height: posterSize, position: 'relative' }}>
                                                        {ip.poster ? (
                                                            <img
                                                                src={ip.poster}
                                                                alt={ip.name}
                                                                style={{
                                                                    position: 'absolute',
                                                                    top: 0,
                                                                    left: 0,
                                                                    width: '100%',
                                                                    height: '100%',
                                                                    objectFit: 'cover',
                                                                    borderRadius: '4px',
                                                                }}
                                                            />
                                                        ) : (
                                                            <Skeleton variant="rectangular" width="100%" height="100%" />
                                                        )}
                                                    </div>
                                                </TableCell>


                                                {/* IP Name */}
                                                <TableCell>{ip.name}</TableCell>

                                                {/* Log Line: Scrollable if too long */}
                                                <TableCell>
                                                    <Typography
                                                        style={{ maxHeight: isMobile ? '50px' : '72px', overflowY: 'auto' }}
                                                        variant="body2"
                                                    >
                                                        {ip.logLine}
                                                    </Typography>
                                                </TableCell>

                                                {/* Genre */}
                                                <TableCell>
                                                    {Array.isArray(ip.genre) && ip.genre.length > 0 ? (
                                                        ip.genre.map((genre, index) => (
                                                            <Typography key={index}>{genre}</Typography>
                                                        ))
                                                    ) : (
                                                        <Typography>No genre available</Typography>
                                                    )}
                                                </TableCell>


                                                {/* Status: Pending, Accepted, or Rejected */}
                                                <TableCell>
                                                    <Chip
                                                        label={ip.status}
                                                        color={
                                                            ip.status === 'pending' ? 'warning' :
                                                                ip.status === 'accepted' ? 'success' :
                                                                    'error'
                                                        }
                                                    />
                                                </TableCell>

                                                {/* Actions */}
                                                <TableCell>
                                                    {ip.status === 'pending' ? (
                                                        <>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={() => handleAccept(ip.id)}
                                                            >
                                                                Accept
                                                            </Button>
                                                            <Button
                                                                variant="contained"
                                                                color="secondary"
                                                                onClick={() => handleReject(ip.id)}
                                                                style={{ marginLeft: '8px' }}
                                                            >
                                                                Reject
                                                            </Button>
                                                        </>
                                                    ) : (
                                                        <Typography>No further action available</Typography>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CoWriterManagementDialog;
