import React, { useState } from 'react';
import OperationsSidebar from './OperationsSidebar';
import TeamManagement from './Teams/TeamManagement';
import TeamMembership from './Teams/TeamMembership';
import EventManagement from './Events/EventManagement'
import ReferralManagement from './Referral/ReferralManagement'
import OperationDashboard from './OperationsDashboard'
import { Box } from '@mui/material';

const OperationsPage = () => {
    const [view, setView] = useState('dashboard'); // This maintains the current view

    return (
        <Box display="flex">
            <OperationsSidebar setView={setView} /> {/* Passing setView to OperationsSidebar */}
            <Box component="main" flexGrow={1} p={3}>
                {view === 'teamManagement' && <TeamManagement />}
                {view === 'teamMembership' && <TeamMembership />}
                {view === 'eventManagement' && <EventManagement />}
                {view === 'referralManagement' && <ReferralManagement />}
                {view === 'dashboard' && <OperationDashboard setView={setView} />}
            </Box>
        </Box>
    );
};

export default OperationsPage;
