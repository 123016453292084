import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Chip,
  OutlinedInput,
} from '@mui/material';
import { useLogin } from '../../../contexts/LoginContext';  // Assuming this provides the logged-in user
import { useAxios } from '../../../contexts/AxiosContext';

const CreateEventDialog = ({ open, onClose, onSuccess }) => {
  const axios = useAxios();
  const { userId } = useLogin();  // Logged-in user's ID
  const [referralPrograms, setReferralPrograms] = useState([]);
  const [selectedReferrals, setSelectedReferrals] = useState([]);
  const [name, setName] = useState('');
  const [eventEpisode, setEventEpisode] = useState('');
  const [description, setDescription] = useState('');
  const [location, setLocation] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [eventLink, setEventLink] = useState('');
  const [images, setImages] = useState('');
  const [documentation, setDocumentation] = useState('');
  const [ownerName, setOwnerName] = useState('');

  // Fetch referral programs
  useEffect(() => {
    const fetchReferralPrograms = async () => {
      try {
        const response = await axios.get('/referral/list');
        setReferralPrograms(response.data.activePrograms);  // Only show active programs
      } catch (error) {
        console.error('Error fetching referral programs:', error);
      }
    };
    fetchReferralPrograms();
  }, [axios]);

  // Handle form submission
  const handleSubmit = async () => {
    const newEvent = {
      name,
      eventEpisode,
      description,
      location,
      fromDate,
      toDate,
      eventLink,
      images: images.split(',').map((img) => img.trim()),  // Split by comma and trim
      documentation: documentation.split(',').map((doc) => doc.trim()),  // Split by comma and trim
      createdBy: userId,
      eventOwner: { ownerName },
      criteria: { referral: selectedReferrals.map((ref) => ref._id) },  // Map selected referrals by ID
    };

    try {
      await axios.post('/event/create', newEvent);
      alert('Event created successfully');
      onSuccess();  // Trigger success callback
      onClose();  // Close the dialog
    } catch (error) {
      console.error('Error creating event:', error);
    }
  };

  // Handle referral selection (multi-select)
  const handleReferralChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedReferrals(
      typeof value === 'string' ? value.split(',') : value
    );
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle>Create New Event</DialogTitle>
      <DialogContent>
        <TextField
          label="Event Name"
          fullWidth
          margin="dense"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <TextField
          label="Event Episode"
          fullWidth
          margin="dense"
          value={eventEpisode}
          onChange={(e) => setEventEpisode(e.target.value)}
          required
        />
        <TextField
          label="Description"
          fullWidth
          margin="dense"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <TextField
          label="Location"
          fullWidth
          margin="dense"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          required
        />
        <TextField
          label="From Date"
          type="datetime-local"
          fullWidth
          margin="dense"
          value={fromDate}
          onChange={(e) => setFromDate(e.target.value)}
          required
        />
        <TextField
          label="To Date"
          type="datetime-local"
          fullWidth
          margin="dense"
          value={toDate}
          onChange={(e) => setToDate(e.target.value)}
          required
        />
        <TextField
          label="Event Link"
          fullWidth
          margin="dense"
          value={eventLink}
          onChange={(e) => setEventLink(e.target.value)}
        />
        <TextField
          label="Images (Comma-separated URLs)"
          fullWidth
          margin="dense"
          value={images}
          onChange={(e) => setImages(e.target.value)}
        />
        <TextField
          label="Documentation (Comma-separated URLs)"
          fullWidth
          margin="dense"
          value={documentation}
          onChange={(e) => setDocumentation(e.target.value)}
        />
        <TextField
          label="Event Owner Name"
          fullWidth
          margin="dense"
          value={ownerName}
          onChange={(e) => setOwnerName(e.target.value)}
          required
        />

        {/* Multi-select for Referral Programs */}
        <FormControl fullWidth margin="dense">
          <InputLabel>Referral Programs</InputLabel>
          <Select
            multiple
            value={selectedReferrals}
            onChange={handleReferralChange}
            input={<OutlinedInput id="select-multiple-chip" label="Referral Programs" />}
            renderValue={(selected) => (
              <div>
                {selected.map((referral) => (
                  <Chip key={referral._id} label={referral.eventName} />
                ))}
              </div>
            )}
          >
            {referralPrograms.map((referral) => (
              <MenuItem key={referral._id} value={referral}>
                {referral.eventName} - {referral.eventEpisode}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button variant="contained" onClick={handleSubmit}>
          Create Event
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateEventDialog;
