import React, { useState, useEffect } from 'react';
import { useAxios } from '../../contexts/AxiosContext';
import { useLocation } from 'react-router-dom';

const ConceptPage = () => {
    const [description, setDescription] = useState('');
    const [token, setToken] = useState('');
    const axios = useAxios();
    const location = useLocation();

    // Function to extract query parameters from the URL
    const getTokenFromUrl = () => {
        const params = new URLSearchParams(location.search);
        return params.get('token'); // Get the 'token' parameter from the URL
    };

    useEffect(() => {
        const extractedToken = getTokenFromUrl();
        if (extractedToken) {
            setToken(extractedToken); // Store the token in state
        } else {
            alert('Token not found in URL.');
        }
    }, [location.search]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            if (!token) {
                alert('No token found. Please log in.');
                return;
            }

            const response = await axios.post('/auth/accept-terms', 
                { description },
                { headers: { Authorization: `Bearer ${token}` } } // Include the token in the headers
            );
            alert('Concept submitted successfully: ' + response.data.message);
        } catch (error) {
            alert('Failed to submit concept: ' + (error.response ? error.response.data.message : error.message));
        }
    };

    return (
        <div>
            <h1>Concept Page</h1>
            <form onSubmit={handleSubmit}>
                <label>
                    Description:
                    <input
                        type="text"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        required
                    />
                </label>
                <button type="submit">Submit</button>
            </form>
        </div>
    );
};

export default ConceptPage;
