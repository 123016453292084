import { Badge } from '@mui/material';
import { Tabs, Tab } from '@mui/material';
import WorkIcon from '@mui/icons-material/Work'; // For reach outs
import PersonIcon from '@mui/icons-material/Person'; // For hire a writer
import CloseIcon from '@mui/icons-material/Close';  // For close button
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'; // For Admin button
import BuildIcon from '@mui/icons-material/Build'; // For Operations button
import Popover from '@mui/material/Popover';  // Import Popover
import DoneIcon from '@mui/icons-material/Done';    // For single tick (unread notification)
import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import {
    Box, IconButton, Tooltip, useMediaQuery, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress,
    Button, Typography, Drawer, List, ListItem, ListItemText, Card, CardActionArea, CardContent, ListItemIcon
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import PublicIcon from '@mui/icons-material/Public';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import LogoutIcon from '@mui/icons-material/Logout';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import NotificationsIcon from '@mui/icons-material/Notifications';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import PublicShare from "../utils/PublicShare";
import { AnimatedTextSVG } from './ui/textSVG';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAxios } from "../contexts/AxiosContext";
import { useLogin } from "../contexts/LoginContext";

const Navbar = ({ isLoggedIn, logout, children }) => {
    const axios = useAxios()
    const theme = useTheme();
    const { userId } = useLogin()
    const [currentPubProfileLink, setCurrentPubProfileLink] = useState(null);
    const [currentGlobalRole, setCurrentGlobalRole] = useState()
    const { pubProfileLink: contextPubProfileLink, globalRoles } = useLogin();  // Get pubProfileLink from context
    const [publicLinkId, setPublicLinkId] = useState(null);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const location = useLocation();
    const navigate = useNavigate();
    const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [openNotificationDrawer, setOpenNotificationDrawer] = useState(false);
    const [loadingNotifications, setLoadingNotifications] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);  // State for anchor element
    const [tabValue, setTabValue] = useState(0);
    const [reachOuts, setReachOuts] = useState([]);
    const [unreadCount, setUnreadCount] = useState(0); // Define the unread count state
    const [updatingNotificationId, setUpdatingNotificationId] = useState(null);  // Tracks which notification is updating

    useEffect(() => {
        const fetchPublicLinkId = async () => {
            if (!userId) {
                return;
            }

            try {
                const { data } = await axios.get(`/profile/${userId}/publicProfileLink`);

                if (data && data.pubProfileLink) {
                    setPublicLinkId(data.pubProfileLink);
                } else {
                    console.log("No public link ID found in response");
                }
            } catch (error) {
                console.error("Error fetching public link ID:", error);
            }
        };

        fetchPublicLinkId(); // Trigger the fetch operation

    }, [userId, axios]); // Make sure userId is in the dependency array



    useEffect(() => {
        // Update the state when contextPubProfileLink changes
        if (contextPubProfileLink) {
            setCurrentPubProfileLink(contextPubProfileLink);
        }

        if (globalRoles) {
            setCurrentGlobalRole(globalRoles)
        }

    }, [contextPubProfileLink, globalRoles]);

    const fetchReachOuts = async () => {
        try {
            const { data } = await axios.get(`/profile/reachouts/${userId}`);
            const sortedReachOuts = data.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));  // Sort by latest first
            setReachOuts(sortedReachOuts);  // Set reachOuts with the sorted data
        } catch (error) {
            console.error("Error fetching reach outs:", error);
        }
    };


    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        if (newValue === 1) {
            fetchReachOuts();  // Fetch reach-outs only when the user switches to the reach-out tab
        }
    };



    const fetchNotifications = async () => {
        setLoadingNotifications(true);
        try {
            const response = await axios.get(`/notifications/list-notifications/${userId}`);
            setNotifications(response.data.notifications);

            // Calculate the unread notifications count and set it
            const unreadCount = response.data.notifications.filter(n => !n.isRead).length;
            setUnreadCount(unreadCount); // Update the unread count state
        } catch (error) {
            console.error('Error fetching notifications:', error);
        } finally {
            setLoadingNotifications(false);
        }
    };



    const markNotificationAsRead = async (notificationId) => {
        // Optimistic UI Update
        const updatedNotifications = notifications.map(n =>
            n._id === notificationId ? { ...n, isRead: true } : n
        );
        setNotifications(updatedNotifications);  // Update the UI optimistically
        setUpdatingNotificationId(notificationId);  // Track the one being updated

        try {
            await axios.put('/notifications/mark-read', { userId, notificationId, isRead: true });
            // Optionally: refetchNotifications to ensure data integrity
        } catch (error) {
            console.error('Error marking notification as read:', error);
            // Revert UI in case of error
            setNotifications(notifications);  // Reset to original state if the request fails
        } finally {
            setUpdatingNotificationId(null);  // Clear the loading state
        }
    };


    const markAllAsRead = async () => {
        try {
            await axios.put(`/notifications/mark-all-read/${userId}`);
            fetchNotifications(); // Refresh notifications after marking all as read
        } catch (error) {
            console.error('Error marking all notifications as read:', error);
        }
    };

    const handleNotificationClick = (notification) => {
        if (!notification.isRead) {
            markNotificationAsRead(notification._id);
        }
        navigate(notification.route);  // Always navigate regardless of read status
    };

    const handleOpenNotifications = (event) => {
        fetchNotifications();
        if (tabValue === 1) {
            fetchReachOuts();  // Fetch reach-outs if "Reach Outs" tab is selected
        }
        if (isMobile) {
            setOpenNotificationDrawer(true); // Open the dialog in mobile view
        } else {
            setAnchorEl(event.currentTarget);  // Set anchor for popover in desktop view
        }
    };

    const formatTimestamp = (dateString) => {
        const options = { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
        return new Date(dateString).toLocaleString('en-US', options).replace(',', ' ');
    };

    const handleCloseNotifications = () => {
        setAnchorEl(null);  // Close popover on desktop
        setOpenNotificationDrawer(false);  // Close dialog on mobile
    };

    useEffect(() => {
        // Listen for messages from the service worker
        if (navigator.serviceWorker) {
            navigator.serviceWorker.addEventListener('message', (event) => {
                if (event.data && event.data.type === 'NEW_NOTIFICATION') {
                    // Fetch notifications to refresh the list and badge
                    fetchNotifications();
                }
            });
        }
    }, []);



    const open = Boolean(anchorEl);  // Check if the popover is open
    const id = open ? 'notifications-popover' : undefined;

    const isPublicProfile = /^\/p\/[^/]+$/.test(location.pathname);

    // Only show the SVG if it's a public profile view
    if (isPublicProfile) {
        return (
            <Box
                sx={{
                    width: '100%',
                    height: isMobile ? '60px' : '10vh',
                    backgroundColor: theme.palette.background.default,
                    display: 'flex',
                    justifyContent: isMobile ? 'start' : 'center',
                    alignItems: 'center',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    zIndex: 1,
                }}
            >
                {/* SVG Logo */}
                <AnimatedTextSVG text="JustWrite" fontSize={isMobile ? '24px' : '30px'} />
            </Box>
        );
    }

    // Your normal navbar logic goes here for logged-in users, etc.
    if (!isLoggedIn) return null;
    // Function to determine which page is active
    const getActivePage = (path) => location.pathname === path;

    const glowEffect = {
        boxShadow: `0 0 50px ${theme.palette.primary.main}`,
    };

    const navbarHeightDesktop = '10vh';
    const navbarHeightMobile = '60px';
    // for top and bottom navbars

    return (
        <>
            {/* Mobile View */}
            {isMobile ? (
                <>
                    {/* Top Navbar for Mobile */}
                    <Box
                        sx={{
                            width: '100%',
                            height: navbarHeightMobile,
                            backgroundColor: 'black',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '0 20px',
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            zIndex: 1,
                        }}
                    >
                        <AnimatedTextSVG text="JustWrite" fontSize="24px" />
                        <Tooltip title="Notifications" placement="bottom">
                            <IconButton color="primary" onClick={handleOpenNotifications}>
                                <Badge
                                    badgeContent={unreadCount}  // Use unreadCount for the badge
                                    color="error"
                                    overlap="circular"
                                >
                                    <NotificationsIcon />
                                </Badge>
                            </IconButton>
                        </Tooltip>



                        <PublicShare pubProfileLink={publicLinkId} />
                    </Box>

                    {/* Bottom Navbar for Mobile */}
                    <Box
                        sx={{
                            width: '100%',
                            height: '90px',
                            backgroundcolor: 'rgba(255, 255, 255, 0.7)',  // Slightly transparent background        
                            backdropFilter: 'blur(30px)',  // Apply blur for frosted glass effect      
                            borderTop: '1px solid rgba(0, 0, 0, 0.1)',
                            display: 'flex',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                            paddingBottom: '5px', // for modern phones with curved displays
                            position: 'fixed',
                            bottom: 0,
                            left: 0,
                            zIndex: 1,
                        }}
                    >
                        <Tooltip title="Home" placement="top">
                            <IconButton
                                color="primary"
                                onClick={() => navigate('/')}
                                sx={getActivePage('/') ? glowEffect : {}}
                            >
                                <HomeIcon sx={{ color: theme.palette.text.primary }} />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Build Profile" placement="top">
                            <IconButton
                                color="primary"
                                onClick={() => navigate(`/buildprofile`)}
                                sx={getActivePage('/buildprofile') ? glowEffect : {}}
                            >
                                <AutoFixHighIcon sx={{ color: theme.palette.text.primary }} />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Events" placement="bottom">
                            <IconButton
                                color="primary"
                                onClick={() => navigate(`/events`)}
                                sx={getActivePage(`/events`) ? glowEffect : {}}
                            >
                                <LocalActivityIcon sx={{ color: theme.palette.text.primary }} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Log Out" placement="top">
                            <IconButton
                                color="primary"
                                onClick={() => setLogoutDialogOpen(true)} // Open confirmation dialog
                            >
                                <LogoutIcon sx={{ color: theme.palette.text.primary }} />
                            </IconButton>
                        </Tooltip>

                    </Box>
                </>
            ) : (
                /* Desktop View */
                <Box
                    sx={{
                        width: '100%',
                        height: navbarHeightDesktop,
                        backgroundColor: theme.palette.background.default,
                        display: 'flex',
                        alignItems: 'center',
                        paddingLeft: '10px',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        zIndex: 1,
                    }}
                >
                    <AnimatedTextSVG text="JustWrite" fontSize="30px" />
                    <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 2 }}>
                        {globalRoles.includes('system_admin') && (
                            <>
                                <Tooltip title="Admin Dashboard" placement="bottom">
                                    <Button variant='outlined' color="primary" onClick={() => navigate('/admin')} startIcon={<AdminPanelSettingsIcon />}>
                                        ADMIN
                                    </Button>
                                </Tooltip>
                                <Tooltip title="Operations" placement="bottom">
                                    <Button variant='outlined' color="primary" onClick={() => navigate('/operations')} startIcon={<BuildIcon />}>
                                        OPERATIONS
                                    </Button>
                                </Tooltip>
                            </>
                        )}
                        {globalRoles.includes('organization_admin') && !globalRoles.includes('system_admin') && (
                            <Tooltip title="Operations" placement="bottom">
                                <Button variant='outlined' color="primary" onClick={() => navigate('/operations')} startIcon={<BuildIcon />}>
                                    OPERATIONS
                                </Button>
                            </Tooltip>
                        )}
                    </Box>
                    <Box
                        sx={{
                            width: '300px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            paddingRight: '50px',
                        }}
                    >
                        <Tooltip title="Notifications" placement="bottom">
                            <IconButton color="primary" onClick={handleOpenNotifications}>
                                <Badge
                                    badgeContent={unreadCount}  // Use unreadCount for the badge
                                    color="error"
                                    overlap="circular"
                                >
                                    <NotificationsIcon />
                                </Badge>
                            </IconButton>
                        </Tooltip>


                        <Tooltip title="Events" placement="bottom">
                            <IconButton
                                color="primary"
                                onClick={() => navigate(`/events`)}
                                sx={getActivePage(`/events`) ? glowEffect : {}}
                            >
                                <LocalActivityIcon sx={{ color: theme.palette.text.primary }} />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Build Profile" placement="bottom">
                            <IconButton
                                color="primary"
                                onClick={() => navigate(`/buildprofile`)}
                                sx={getActivePage('/buildprofile') ? glowEffect : {}}
                            >
                                <AutoFixHighIcon sx={{ color: theme.palette.text.primary }} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Home" placement="bottom">
                            <IconButton
                                color="primary"
                                onClick={() => navigate('/')}
                                sx={getActivePage('/') ? glowEffect : {}}
                            >
                                <HomeIcon sx={{ color: theme.palette.text.primary }} />
                            </IconButton>
                        </Tooltip>
                        <PublicShare pubProfileLink={currentPubProfileLink} />
                        <Tooltip title="Log Out" placement="bottom">
                            <IconButton
                                color="primary"
                                onClick={logout}
                            >
                                <LogoutIcon sx={{ color: theme.palette.text.primary }} />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>
            )}
            <Dialog
                open={logoutDialogOpen}
                onClose={() => setLogoutDialogOpen(false)}
                aria-labelledby="logout-dialog-title"
                aria-describedby="logout-dialog-description"
            >
                <DialogTitle id="logout-dialog-title">Confirm Logout</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to log out?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setLogoutDialogOpen(false)} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            setLogoutDialogOpen(false);
                            logout(); // Call logout function after confirmation
                        }}
                        color="primary"
                        autoFocus
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            {isMobile ? (
                // Mobile View (Dialog)
                <Dialog fullScreen open={openNotificationDrawer} onClose={handleCloseNotifications}>
                    <DialogTitle sx={{ backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary, position: 'relative' }}>
                        {/* Tab Header */}
                        <Box sx={{ position: 'sticky', top: 0, backgroundColor: theme.palette.background.paper, zIndex: 1 }}>
                            <Tabs value={tabValue} onChange={handleTabChange} aria-label="notification tabs">
                                <Tab label="Notifications" />
                                <Tab label="Reach Outs" />
                            </Tabs>
                            {/* Close Button */}
                            <IconButton
                                aria-label="close"
                                onClick={handleCloseNotifications}
                                sx={{ position: 'absolute', right: 8, top: 8, color: theme.palette.grey[500] }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </DialogTitle>

                    {/* Scrollable Content Area */}
                    <DialogContent sx={{ backgroundColor: theme.palette.background.default, paddingBottom: '90px', overflow: 'auto' }}>
                        {tabValue === 0 && (
                            // Notifications Tab Content
                            loadingNotifications ? (
                                <p>Loading...</p>
                            ) : (
                                notifications.length === 0 ? (
                                    <Typography>No notifications</Typography>
                                ) : (
                                    notifications
                                        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))  // Sort by date (newest first)
                                        .map(notification => (
                                            <Card key={notification._id} sx={{
                                                marginBottom: '8px',
                                                backgroundColor: notification.isRead ? theme.palette.background.default : theme.palette.action.hover,
                                                borderLeft: notification.isRead ? 'none' : `5px solid ${theme.palette.primary.main}`,
                                                fontWeight: notification.isRead ? 'normal' : 'bold',
                                            }}>
                                                <CardActionArea
                                                    onClick={() => handleNotificationClick(notification)}
                                                    disabled={updatingNotificationId === notification._id}  // Disable interaction when updating
                                                >
                                                    <CardContent>
                                                        <Box display="flex" justifyContent="space-between" alignItems="center">
                                                            <Typography
                                                                variant="h6"
                                                                sx={{
                                                                    color: theme.palette.text.primary,
                                                                    fontWeight: notification.isRead ? 'normal' : 'bold',
                                                                    fontSize: isMobile ? '14px' : '1rem',  // Smaller font size for mobile
                                                                    lineHeight: isMobile ? '1.2' : '1.5',  // Adjust line height for compactness
                                                                }}
                                                            >
                                                                {notification.title}
                                                            </Typography>
                                                            {!notification.isRead ? (
                                                                updatingNotificationId === notification._id ? (
                                                                    <CircularProgress size={20} />  // Show loading spinner if updating
                                                                ) : (
                                                                    <DoneIcon sx={{ color: theme.palette.success.main }} />
                                                                )
                                                            ) : (
                                                                <DoneAllIcon sx={{ color: theme.palette.success.main }} />
                                                            )}
                                                        </Box>
                                                        <Typography
                                                            variant="body2"
                                                            sx={{
                                                                color: theme.palette.text.secondary,
                                                                display: notification.isRead ? 'none' : 'block',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                whiteSpace: 'nowrap'
                                                            }}
                                                        >
                                                            {notification.body}
                                                        </Typography>
                                                        <Typography
                                                            variant="caption"
                                                            sx={{ color: "white" }}
                                                        >
                                                            {formatTimestamp(notification.createdAt)}
                                                        </Typography>

                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        ))
                                )
                            )
                        )}

                        {tabValue === 1 && (
                            // Reach Outs Tab Content
                            <List>
                                {reachOuts.map(reachOut => (
                                    <ListItem
                                        key={reachOut._id}
                                        sx={{
                                            marginBottom: '8px',
                                            backgroundColor: theme.palette.background.default,
                                            borderLeft: `5px solid ${theme.palette.primary.main}`,
                                        }}
                                    >
                                        <ListItemIcon>
                                            {reachOut.option === "Enquire a story" ? <WorkIcon /> : <PersonIcon />}
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={reachOut.name}
                                            secondary={
                                                <>
                                                    <Typography variant="body2">{reachOut.message}</Typography>
                                                    <Typography variant="caption" sx={{ color: theme.palette.text.disabled }}>
                                                        {formatTimestamp(reachOut.timestamp)}
                                                    </Typography>

                                                </>
                                            }
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        )}
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={markAllAsRead} fullWidth>Mark all as read</Button>
                    </DialogActions>
                </Dialog>
            ) : (
                // Desktop View (Popover)
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleCloseNotifications}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    PaperProps={{
                        sx: {
                            width: '350px',
                            maxHeight: '400px',
                            overflowY: 'auto',
                            backgroundColor: theme.palette.background.paper,
                            borderRadius: 0, // Sharp edges
                            boxShadow: theme.shadows[5],
                            border: '1px solid white', // Add the white border here
                        }
                    }}
                >
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tabValue} onChange={handleTabChange} aria-label="notification tabs">
                            <Tab label="Notifications" />
                            <Tab label="Reach Outs" />
                        </Tabs>
                    </Box>

                    {tabValue === 0 && (
                        <List>
                            {notifications
                                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))  // Sort by date (newest first)
                                .map(notification => (
                                    <ListItem
                                        key={notification._id}
                                        button
                                        onClick={() => handleNotificationClick(notification)}
                                        sx={{
                                            backgroundColor: notification.isRead ? theme.palette.background.default : theme.palette.action.hover,
                                            borderLeft: notification.isRead ? 'none' : `5px solid ${theme.palette.primary.main}`,
                                            fontWeight: notification.isRead ? 'normal' : 'bold',
                                            marginBottom: '8px',
                                            '&:hover': { backgroundColor: theme.palette.action.selected },
                                        }}
                                    >
                                        <ListItemText
                                            primary={notification.title}
                                            secondary={new Date(notification.createdAt).toLocaleString()}
                                            sx={{ color: theme.palette.text.secondary }}
                                        />
                                        {!notification.isRead && (
                                            updatingNotificationId === notification._id ? (
                                                <CircularProgress size={20} />  // Show loading spinner if updating
                                            ) : (
                                                <IconButton onClick={() => markNotificationAsRead(notification._id)}>
                                                    <DoneAllIcon sx={{ color: theme.palette.text.primary }} />
                                                </IconButton>
                                            )
                                        )}
                                    </ListItem>
                                ))}
                        </List>
                    )}

                    {tabValue === 1 && (
                        <List>
                            {reachOuts.map(reachOut => (
                                <ListItem
                                    key={reachOut._id}
                                    sx={{
                                        marginBottom: '8px',
                                        backgroundColor: theme.palette.background.default,
                                        borderLeft: `5px solid ${theme.palette.primary.main}`,
                                    }}
                                >
                                    <ListItemIcon>
                                        {reachOut.option === "Enquire a story" ? <WorkIcon /> : <PersonIcon />}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={reachOut.name}
                                        secondary={
                                            <>
                                                <Typography variant="body2">{reachOut.message}</Typography>
                                                <Typography variant="caption" sx={{ color: theme.palette.text.disabled }}>
                                                    {new Date(reachOut.timestamp).toLocaleString()}
                                                </Typography>
                                            </>
                                        }
                                    />
                                </ListItem>
                            ))}
                        </List>
                    )}

                    <Button onClick={markAllAsRead} fullWidth>Mark all as read</Button>
                </Popover>
            )}

            {children}
        </>
    );
};

export default Navbar;
