// FormUtils.js

// Function to format a date as 'MMM YYYY'
export const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
        month: 'short',
        year: 'numeric',
    });
};

// Function to calculate the duration between two dates in 'Xy Xm' format
export const calculateDuration = (fromDate, toDate = new Date()) => {
    const from = new Date(fromDate);
    const to = new Date(toDate);
    const diffYears = to.getFullYear() - from.getFullYear();
    const diffMonths = to.getMonth() - from.getMonth() + diffYears * 12;
    const years = Math.floor(diffMonths / 12);
    const months = diffMonths % 12;

    return `${years ? `${years}y ` : ''}${months ? `${months}m` : ''}`.trim();
};