import React, { createContext, useContext, useState, useMemo, useEffect } from 'react';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { jwtDecode } from 'jwt-decode';

// Create a context to store and manage the theme state globally
const ThemeContext = createContext({
    toggleTheme: () => { },  // Default implementation for toggling theme
    theme: createTheme({
        palette: { mode: 'light' },  // Default light theme mode
        typography: { fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif' },  // Default global font
    }),
});

// Custom hook to access the ThemeContext
export const useTheme = () => useContext(ThemeContext);

// Utility function to check if the JWT token is valid
const isTokenValid = () => {
    try {
        const token = localStorage.getItem('token');
        if (token) {
            const decoded = jwtDecode(token);
            return decoded.exp * 1000 > Date.now(); // Check if the token has expired
        }
    } catch (error) {
        console.error('Invalid token', error);
        return false; // Return false if there's an error decoding the token
    }
    return false;
};

// Utility function to get the user's preferred theme mode (light or dark)
const getPreferredThemeMode = () => {
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        return 'dark';
    }
    return 'light';
};

// Custom hook to manage the theme mode (light or dark) and handle side effects
const useThemeMode = () => {
    const [mode, setMode] = useState(() => {
        const savedMode = isTokenValid() ? localStorage.getItem('themeMode') : null;
        return savedMode || getPreferredThemeMode(); // Use saved mode if valid, otherwise use preferred mode
    });

    useEffect(() => {
        if (isTokenValid()) {
            localStorage.setItem('themeMode', mode); // Save the current theme mode in localStorage
        }
    }, [mode]);

    // Function to toggle between light and dark mode
    const toggleTheme = () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
    };

    return [mode, toggleTheme];
};

// ThemeProvider component that wraps the entire application
export const ThemeProvider = ({ children }) => {
    const [mode, toggleTheme] = useThemeMode(); // Use the custom hook to get the current mode and toggle function

    // useMemo to create a theme object that will only recompute when the mode changes
    const theme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: mode,
                    ...(mode === 'light'
                        ? {
                            primary: {
                                main: '#ffffff', // Light Blue for dark mode primary color
                            },
                            secondary: {
                                main: '#f48fb1', // Light Pink for dark mode secondary color
                            },
                            background: {
                                default: '#000000', // Dark Grey for dark mode background
                                paper: '#1e1e1e', // Slightly Lighter Dark Grey for paper background
                            },
                            text: {
                                primary: '#ffffff', // White for primary text
                                secondary: '#bbbbbb', // Light Grey for secondary text
                            },
                        }
                        : {
                            primary: {
                                main: '#ffffff', // Light Blue for dark mode primary color
                            },
                            secondary: {
                                main: '#f48fb1', // Light Pink for dark mode secondary color
                            },
                            background: {
                                default: '#000000', // Dark Grey for dark mode background
                                paper: '#1e1e1e', // Slightly Lighter Dark Grey for paper background
                            },
                            text: {
                                primary: '#ffffff', // White for primary text
                                secondary: '#bbbbbb', // Light Grey for secondary text
                            },
                        }),
                },
                typography: {
                    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
                },
                breakpoints: {
                    values: {
                        xs: 0,
                        sm: 600,
                        md: 960,
                        lg: 1280,
                        xl: 1920,
                    },
                },
            }),
        [mode] // Only recreate the theme when mode changes
    );

    return (
        // Provide the theme context to children components
        <ThemeContext.Provider value={{ toggleTheme, theme }}>
            <MuiThemeProvider theme={theme}>
                <CssBaseline /> {/* Reset CSS baseline for consistent styling */}
                {children}
            </MuiThemeProvider>
        </ThemeContext.Provider>
    );
};
