// combinedSchema.js
import { z } from 'zod';
import userDetailsSchema from './userDetailsSchema';
import ownWorksSchema from './ownWorksSchema';
import experienceSchema from './experienceSchema';
import storyofmylifeSchema from './storyofmylifeSchema';
import favouritesSchema from './favouritesSchema'; // Importing the favourites schema
import qualificationsSchema from './qualificationsSchema';

const combinedSchema = userDetailsSchema.extend({
    ownWorks: ownWorksSchema.shape.ownWorks,
    experience: experienceSchema.shape.experience,
    storyOfmyLife: storyofmylifeSchema.shape.storyOfmyLife,
    interests: favouritesSchema.shape.interests, // Adding favourites schema
    qualifications: qualificationsSchema.shape.qualifications,
    userPreference: z.object({
        publicOrder: z.array(z.string()).min(1, { message: 'Public order cannot be empty' }).default(['ownWorks', 'experience', 'favourites'])
      })
});

export default combinedSchema;
