import React, { useEffect, useState } from 'react';
import StatsWidget from './widgets/StatsWidget'; // Adjust the import path as per your project structure
import { useAxios } from '../../contexts/AxiosContext'; // Make sure the path matches your project structure

const OperationDashboard = ({setView}) => {
    const axios = useAxios();
    const [stats, setStats] = useState({
        eventStats: { count: 0, names: [] },
        activeEvents: { count: 0, names: [] },
        expiredEvents: { count: 0, names: [] },
        referralStats: { count: 0, names: [] },
        expiredReferrals: { count: 0, names: [] },
        terminatedReferrals: { count: 0, names: [] },
        teamStats: { count: 0, names: [] }
    });
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchStats = async () => {
            try {
                const response = await axios.get('/operations/dashboard-stats');
                setStats(response.data); // Adjust according to the actual response structure
                setIsLoading(false);
            } catch (err) {
                setError(err.message);
                setIsLoading(false);
            }
        };

        fetchStats();
    }, [axios]);

    return (
        <div>
            <h1>Dashboard</h1>
            <p>Welcome to the Operations Dashboard.</p>
            {error && <p>Error loading stats: {error}</p>}
            {!isLoading ? <StatsWidget stats={stats} setView={setView} /> : <p>Loading...</p>}
            </div>
    );
};

export default OperationDashboard;
