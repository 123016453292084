import React, { useState, useEffect } from 'react';
import { List, ListItem, ListItemText, Button, Autocomplete, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import InviteMemberDialog from './InviteMemberDialog'; // Adjust path as necessary
import { useAxios } from '../../../contexts/AxiosContext';

const TeamMembership = () => {
    const [teams, setTeams] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [members, setMembers] = useState([]);
    const axios = useAxios();

    useEffect(() => {
        fetchTeams();
    }, []);

    const fetchTeams = async () => {
        try {
            const response = await axios.get('/event/team');
            setTeams(response.data.teams);
        } catch (error) {
            console.error('Failed to fetch teams:', error);
        }
    };

    useEffect(() => {
        if (selectedTeam) {
            fetchMembers(selectedTeam._id);
        }
    }, [selectedTeam]);

    const fetchMembers = async (teamId) => {
        try {
            const response = await axios.get(`/event/team/${teamId}/members`);
            console.log(response.data.members)
            setMembers(response.data.members);
        } catch (error) {
            console.error('Failed to fetch team members:', error);
        }
    };

    const [openDialog, setOpenDialog] = useState(false);
    const [currentMember, setCurrentMember] = useState(null);

    const handleConfirmMemberChange = (member) => {
        setCurrentMember(member);
        setOpenDialog(true);
    };

    const handleRemoveMember = async () => {
        if (!currentMember) return;
        try {
            console.log("currentMember.isRemoved: ", currentMember.isRemoved === false ? 'remove' : (currentMember.isRemoved ? 'unremove' : 'remove'))
            await axios.put(`/event/team/${selectedTeam._id}/remove-or-unremove`, {
                userId: currentMember.userId,
                action: currentMember.isRemoved === false ? 'remove' : (currentMember.isRemoved ? 'unremove' : 'remove')
            });
            fetchMembers(selectedTeam._id); // Refresh members list
            setOpenDialog(false);
            setCurrentMember(null);
        } catch (error) {
            console.error('Failed to remove/unremove member:', error);
        }
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const [inviteDialogOpen, setInviteDialogOpen] = useState(false);

    const handleOpenInviteDialog = () => {
        setInviteDialogOpen(true);
    };

    const handleCloseInviteDialog = () => {
        setInviteDialogOpen(false);
        fetchMembers(selectedTeam._id);
    };


    return (
        <div>
            <Autocomplete
                variant='outlined'
                disablePortal
                options={teams}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} label="Select Team" />}
                onChange={(_, newValue) => setSelectedTeam(newValue)}
                fullWidth
            />
            {selectedTeam && (
                <>
                    <Button variant='contained' onClick={handleOpenInviteDialog}>Invite New Member</Button>
                    <InviteMemberDialog
                        open={inviteDialogOpen}
                        onClose={handleCloseInviteDialog}
                        teamId={selectedTeam._id}
                        excludeUserIds={members.map(member => member.userId)}
                    />
                </>
            )}
            {selectedTeam ? (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell align="right">Role</TableCell>
                                <TableCell align="right">Accepted</TableCell>
                                {/* <TableCell align="right">Accepted At</TableCell>
                                <TableCell align="right">Removed At</TableCell>
                                */}
                                <TableCell align="right">Status</TableCell>
                                <TableCell align="right">Actions</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {members.map((member) => (
                                <TableRow key={member.userId}>
                                    <TableCell component="th" scope="row">{member.name}</TableCell>
                                    <TableCell align="right">{member.role}</TableCell>
                                    <TableCell align="right">{member.isAccepted ? "Yes" : "No"}</TableCell>
                                    {/* <TableCell align="right">{member.acceptedAt}</TableCell>
                                    <TableCell align="right">{member.removedAt}</TableCell>
                                    {/*  */}
                                    <TableCell align="right">{member.isRemoved ? "Removed" : "Active"}</TableCell>
                                    <TableCell align="right">
                                        <Button onClick={() => handleConfirmMemberChange(member)}>
                                            {member.isRemoved ? 'Unremove' : 'Remove'}
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

            ) : (
                <p>Select a team to view members.</p>
            )}
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>{currentMember?.isRemoved ? 'Unremove Member' : 'Remove Member'}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to {currentMember?.isRemoved ? 'unremove' : 'remove'} this member?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Cancel</Button>
                    <Button onClick={handleRemoveMember} color="primary">
                        {currentMember?.isRemoved ? 'Unremove' : 'Remove'}
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
};

export default TeamMembership;
