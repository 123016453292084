import React from 'react';
import { List, ListItem, ListItemText, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const OperationsSidebar = ({ setView }) => {
    return (
        <div style={{ width: '250px', height: '100vh', background: '#333', color: '#fff' }}>
            <List component="nav" style={{ padding: '20px 0' }}>
                <ListItem button onClick={() => setView('dashboard')}>
                    <ListItemText primary="Dashboard" />
                </ListItem>
                <ListItem button onClick={() => setView('referralManagement')}>
                    <ListItemText primary="Referral" />
                </ListItem>
                <ListItem button onClick={() => setView('eventManagement')}>
                    <ListItemText primary="Events" />
                </ListItem>
                <Accordion defaultExpanded={false}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Teams</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List component="nav">
                            <ListItem button onClick={() => setView('teamManagement')}>
                                <ListItemText primary="Team Management" />
                            </ListItem>
                            <ListItem button onClick={() => setView('teamMembership')}>
                                <ListItemText primary="Team Membership" />
                            </ListItem>
                        </List>
                    </AccordionDetails>
                </Accordion>
            </List>
        </div>
    );
};

export default OperationsSidebar;
