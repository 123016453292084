import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const SkillLevelGauge = ({ level, size = 40 }) => {
  const displayLevel = (level || 1) - 1; // Convert DB value (1-6) to display value (0-5)

  // Function to determine color based on level
  const getColor = (lvl) => {
    if (lvl === 0) return "#e0e0e0"; // Gray for 0 level
    if (lvl <= 2) return "#ffcc00";  // Yellow for levels 1-2
    if (lvl <= 4) return "#42a5f5";  // Blue for levels 3-4
    return "#66bb6a";                // Green for levels 5-6
  };

  return (
    <div style={{ width: size, height: size }}>
      <CircularProgressbar
        value={(displayLevel / 5) * 100} // Convert level to percentage based on 0-5 scale
        text={`${displayLevel}`} // Display the level inside the circle (0-5)
        styles={buildStyles({
          textSize: '24px', // Adjust text size for better readability
          textColor: "#ffffff",  // Ensure text color is white
          pathColor: getColor(displayLevel),  // Circle color based on display level
          trailColor: "#383838",  // Darker trail for better contrast
        })}
      />
    </div>
  );
};

export default SkillLevelGauge;
