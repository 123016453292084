import React from 'react';

const AppleInstallDrawer = ({ isOpen, onClose }) => {
  const handleClose = () => {
    // First slide the drawer down, then close it
    const drawer = document.getElementById('apple-install-drawer');
    drawer.style.transform = 'translateY(100%)';
    setTimeout(onClose, 400); // Corresponds to the transition time
  };

  const handleInstall = () => {
    // Open the share sheet if supported
    if (navigator.share) {
      navigator.share({
        title: 'Install App',
        url: window.location.href, // Share the current URL
      }).then(() => {
        console.log('Share was successful.');
      }).catch((error) => {
        console.error('Error sharing', error);
      });
    } else {
      alert('Share not supported in this browser.');
    }
  };

  return (
    <div
      id="apple-install-drawer"
      style={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        backgroundColor: '#fff',
        borderTopLeftRadius: '20px',
        borderTopRightRadius: '20px',
        boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.2)',
        transform: isOpen ? 'translateY(0)' : 'translateY(100%)',
        transition: 'transform 0.4s ease-in-out',
        zIndex: 1000,
        padding: '20px',
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h2 style={{ fontFamily: 'sans-serif', margin: 0 }}>Install this app</h2>
        <button
          onClick={handleClose}
          style={{
            background: 'none',
            border: 'none',
            fontSize: '20px',
            cursor: 'pointer',
          }}
        >
          &#10006;
        </button>
      </div>
      <div style={{ marginTop: '10px', textAlign: 'center' }}>
        <p style={{ fontFamily: 'sans-serif', fontSize: '16px', color: '#333' }}>
          1. Press the <strong>Share</strong> button in Safari.
        </p>
        <p style={{ fontFamily: 'sans-serif', fontSize: '16px', color: '#333' }}>
          2. Then select <strong>Add to Home Screen</strong>.
        </p>
        <button
          onClick={handleInstall}
          style={{
            marginTop: '20px',
            padding: '10px 20px',
            borderRadius: '30px',
            backgroundColor: '#007AFF',
            color: 'white',
            border: 'none',
            fontSize: '16px',
            cursor: 'pointer',
            fontFamily: 'sans-serif',
          }}
        >
          Install
        </button>
      </div>
    </div>
  );
};

export default AppleInstallDrawer;
