import React from 'react';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';

const TermsAndCondition = () => {
  const docs = [
    {
      uri: require('../../assets/legal/termsAndConditions/JustWrite_Terms_and_Conditions_V1.docx'), // Adjust path if necessary
    },
  ];

  return (
    <div>
      <h1>Terms and Conditions</h1>
      <DocViewer documents={docs} pluginRenderers={DocViewerRenderers} />
    </div>
  );
};

export default TermsAndCondition;
