import React from 'react';
import { Box, Card, Typography, Tooltip, CardActionArea } from '@mui/material';

const StatsWidget = ({ stats, setView }) => {
  // Helper function to render each card
  const renderStatCard = (title, stats, viewName) => (
    <Card sx={{
      m: 1,
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      p: 2,
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.12)',
      borderRadius: '12px',
      backgroundColor: '#ffffff',
    }}>
      <CardActionArea onClick={() => setView(viewName)} sx={{ width: '100%', '&:hover': { backgroundColor: 'transparent' } }}>
        <Typography variant="h6" sx={{ fontSize: 16, fontWeight: 'bold', color: '#333' }}>
          {title}
        </Typography>
        <Tooltip title={stats.names.join(', ') || 'No details available'} disableHoverListener={stats.count === 0}>
          <Typography sx={{ fontSize: 14, cursor: 'pointer', color: '#666' }}>
            {title !== 'Teams' ? `Active: ${stats.active || 0} | Expired: ${stats.expired || 0} | Terminated: ${stats.terminated || 0}` : `${stats.count}`}
          </Typography>
        </Tooltip>
      </CardActionArea>
    </Card>
  );

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      padding: '12px',
      borderRadius: '12px',
    }}>
      {renderStatCard("Referrals", {
        active: stats.referralStats.count,
        expired: stats.expiredReferrals.count,
        terminated: stats.terminatedReferrals.count,
        names: [...stats.referralStats.names, ...stats.expiredReferrals.names]
      }, 'referralManagement')}
      {renderStatCard("Events", {
        active: stats.activeEvents.count,
        expired: stats.expiredEvents.count,
        terminated: 0, // Assuming no terminated events
        names: [...stats.activeEvents.names, ...stats.expiredEvents.names]
      }, 'eventManagement')}
      {renderStatCard("Teams", {
        count: stats.teamStats.count, // Just showing the count
        names: stats.teamStats.names
      }, 'teamManagement')}
    </Box>
  );
};

export default StatsWidget;
