import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, List, ListItem, ListItemText } from '@mui/material';

const ErrorDialog = ({ open, onClose, errorMessages }) => (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>Validation Errors</DialogTitle>
        <DialogContent>
            <List>
                {errorMessages.map((message, index) => (
                    <ListItem key={index}>
                        <ListItemText primary={`• ${message}`} />
                    </ListItem>
                ))}
            </List>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} color="primary">
                Close
            </Button>
        </DialogActions>
    </Dialog>
);

export default ErrorDialog;
