import React from 'react';
import { useTheme, useMediaQuery, Box } from '@mui/material';

const GlassBox = ({ onClick, color, width, height, border, boxShadow, borderRadius, children, sx = {} }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box
            onClick={onClick}
            sx={{
                background: 'rgba(255, 255, 255, 0)',
                boxShadow: boxShadow || '', //'0 8px 32px 0 rgba(31, 38, 135, 0.20)',
                backdropFilter: 'blur(4px)',
                WebkitBackdropFilter: 'blur(4px)',
                borderRadius: borderRadius || '15px',
                // borderRight: '1px solid rgba(255, 255, 255, 0.1)',
                border: border || '1px solid rgba(255, 255, 255, 0.1)',
                padding: '20px',
                color: color || theme.palette.text.primary,
                width: isMobile ? (width?.mobile || '100%') : (width?.desktop || '500px'),
                height: isMobile ? (height?.mobile || 'auto' ) : (height?.desktop || 'auto'),
                ...sx, // Apply additional sx styles
            }}
        >
            {children}
        </Box>
    );
};

export default GlassBox;
