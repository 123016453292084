import React, { useEffect, useState } from 'react';
import { TextField, Select, MenuItem, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'; // Import MUI components
import { useAxios } from '../../../contexts/AxiosContext'; // Axios context for API calls
import { useLogin } from '../../../contexts/LoginContext'; // Login context for user data
import { useTheme } from '../../../contexts/ThemeContext'; // Theme context for styling
import './ReferralManagement.css'; // Import CSS for custom styles if needed
import ReferralReport from './ReferralReport'; // Adjust the path based on your folder structure

const ReferralManagement = () => {
    const axios = useAxios();
    const { theme } = useTheme();
    const { userId } = useLogin()

    // State to manage referral programs
    const [referralPrograms, setReferralPrograms] = useState({
        activePrograms: [],
        expiredPrograms: [],
        terminatedPrograms: []
    });

    const [newProgram, setNewProgram] = useState({
        eventName: '',
        eventEpisode: '',
        fromDate: '',
        toDate: ''
    });

    const [searchTerm, setSearchTerm] = useState(''); // Search term for filtering
    const [sortBy, setSortBy] = useState('eventName'); // Sort criteria
    const [createDialogOpen, setCreateDialogOpen] = useState(false); // For the create dialog
    const [terminateDialogOpen, setTerminateDialogOpen] = useState(false); // For the terminate dialog
    const [selectedProgram, setSelectedProgram] = useState(null); // Program to terminate/extend/reactivate
    const [reactivateDialogOpen, setReactivateDialogOpen] = useState(false); // For reactivating dialog
    const [copySuccess, setCopySuccess] = useState(''); // To show copy success message

    // Fetch referral programs from backend
    useEffect(() => {
        const fetchReferralPrograms = async () => {
            try {
                const response = await axios.get('/referral/list');
                console.log(response.data)
                setReferralPrograms({
                    activePrograms: response.data.activePrograms,
                    expiredPrograms: response.data.expiredPrograms,
                    terminatedPrograms: response.data.terminatedPrograms
                });
            } catch (error) {
                console.error('Error fetching referral programs', error);
            }
        };

        fetchReferralPrograms();
    }, [axios]);

    const handleInputChange = (e) => {
        setNewProgram({
            ...newProgram,
            [e.target.name]: e.target.value
        });
    };

    // Open Reactivate Dialog
    const handleOpenReactivateDialog = (program) => {
        setSelectedProgram(program);
        setReactivateDialogOpen(true);
    };

    // Close Reactivate Dialog
    const handleCloseReactivateDialog = () => {
        setReactivateDialogOpen(false);
        setSelectedProgram(null);
    };

    // Filtering and sorting logic
    const filterAndSortPrograms = (programs) => {
        let filteredPrograms = [...programs];

        // Apply search
        if (searchTerm) {
            filteredPrograms = filteredPrograms.filter(program =>
                program.eventName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                program.eventEpisode.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }

        // Apply sorting
        if (sortBy === 'eventName') {
            filteredPrograms.sort((a, b) => a.eventName.localeCompare(b.eventName));
        } else if (sortBy === 'toDate') {
            filteredPrograms.sort((a, b) => new Date(a.toDate) - new Date(b.toDate));
        }

        return filteredPrograms;
    };

    const handleCreateProgram = async () => {
        try {
            const response = await axios.post('/referral/create', {
                eventName: newProgram.eventName,
                eventEpisode: newProgram.eventEpisode,
                fromDate: newProgram.fromDate,
                toDate: newProgram.toDate,
                creatorId: userId
            });

            // Update the list with the new program and close dialog
            setReferralPrograms(prev => ({
                ...prev,
                activePrograms: [...prev.activePrograms, response.data.referral]
            }));
            handleCloseCreateDialog();  // Close dialog after creation
        } catch (error) {
            console.error('Error creating referral program:', error);
        }
    };

    // Function to copy referral URL to clipboard
    const handleCopyReferralId = (referralId) => {
        const referralUrl = `http://justwrite.soozh.app/?utm_source=google&referral=${referralId}`;
        navigator.clipboard.writeText(referralUrl)
            .then(() => {
                setCopySuccess('Referral URL copied to clipboard!');
                setTimeout(() => setCopySuccess(''), 3000); // Reset after 3 seconds
            })
            .catch(err => {
                console.error('Error copying to clipboard', err);
            });
    };


    // API call for reactivating a program
    const handleReactivateProgram = async () => {
        try {
            await axios.post('/referral/reactivate', { referralId: selectedProgram._id });

            // Move program from terminated to active
            setReferralPrograms(prev => ({
                ...prev,
                terminatedPrograms: prev.terminatedPrograms.filter(program => program._id !== selectedProgram._id),
                activePrograms: [...prev.activePrograms, { ...selectedProgram, terminated: false }]
            }));
            handleCloseReactivateDialog();
        } catch (error) {
            console.error('Error reactivating referral program', error);
        }
    };


    const filteredActivePrograms = filterAndSortPrograms(referralPrograms.activePrograms);
    const filteredExpiredPrograms = filterAndSortPrograms(referralPrograms.expiredPrograms);
    const filteredTerminatedPrograms = filterAndSortPrograms(referralPrograms.terminatedPrograms);

    // Function to open Create Program dialog
    const handleOpenCreateDialog = () => {
        setCreateDialogOpen(true);
    };

    // Function to close Create Program dialog
    const handleCloseCreateDialog = () => {
        setCreateDialogOpen(false);
    };

    // Function to open Terminate Program dialog
    const handleOpenTerminateDialog = (program) => {
        setSelectedProgram(program);
        setTerminateDialogOpen(true);
    };

    // Function to close Terminate Program dialog
    const handleCloseTerminateDialog = () => {
        setTerminateDialogOpen(false);
        setSelectedProgram(null);
    };

    // API call for terminating a program (with confirmation)
    const handleTerminateProgram = async () => {
        try {
            await axios.post('/referral/terminate', { referralId: selectedProgram._id });
            // Update the list after termination
            setReferralPrograms(prev => ({
                ...prev,
                activePrograms: prev.activePrograms.filter(program => program._id !== selectedProgram._id),
                terminatedPrograms: [...prev.terminatedPrograms, { ...selectedProgram, terminated: true }]
            }));
            handleCloseTerminateDialog();
        } catch (error) {
            console.error('Error terminating referral program', error);
        }
    };

    return (
        <div className={`admin-referral-console ${theme}`}>
            <h2>Admin Referral Console</h2>
            <ReferralReport />


            {/* Search + Filter Bar */}
            <div className="search-filter-bar">
                <TextField
                    label="Search by event name or episode"
                    variant="outlined"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <Select
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value)}
                    displayEmpty
                    variant="outlined"
                >
                    <MenuItem value="eventName">Sort by Event Name</MenuItem>
                    <MenuItem value="toDate">Sort by Expiry Date</MenuItem>
                </Select>
                <Button variant="contained" onClick={handleOpenCreateDialog}>Create New Program</Button>
            </div>

            {/* Active Programs */}
            <h3>Active Programs</h3>
            <ul className="referral-program-list">
                {filteredActivePrograms.map(program => (
                    <li key={program._id} className="referral-program-item">
                        <span className="status-indicator"><span className="dot green"></span></span>
                        <span className="event-details">
                            <strong>{program.eventName}</strong> - {program.eventEpisode}
                        </span>

                        {/* Copy Referral ID Button */}
                        <Button onClick={() => handleCopyReferralId(program._id)}>Copy Referral URL</Button>

                        {/* Terminate Button */}
                        <Button onClick={() => handleOpenTerminateDialog(program)}>Terminate</Button>
                    </li>
                ))}
            </ul>


            {/* Expired Programs */}
            <h3>Expired Programs</h3>
            <ul className="referral-program-list">
                {filteredExpiredPrograms.map(program => (
                    <li key={program._id} className="referral-program-item">
                        <span className="status-indicator"><span className="dot yellow"></span></span>
                        <span className="event-details">
                            <strong>{program.eventName}</strong> - {program.eventEpisode}
                        </span>
                        <Button onClick={() => handleOpenTerminateDialog(program)}>Terminate</Button>
                    </li>
                ))}
            </ul>

            {/* Terminated Programs */}
            <h3>Terminated Programs</h3>
            <ul className="referral-program-list">
                {filteredTerminatedPrograms.map(program => (
                    <li key={program._id} className="referral-program-item">
                        <span className="status-indicator"><span className="dot red"></span></span>
                        <span className="event-details">
                            <strong>{program.eventName}</strong> - {program.eventEpisode}
                        </span>
                        <Button onClick={() => handleOpenReactivateDialog(program)}>Reactivate</Button>
                    </li>
                ))}
            </ul>

            <Dialog open={reactivateDialogOpen} onClose={handleCloseReactivateDialog}>
                <DialogTitle>Reactivate Program</DialogTitle>
                <DialogContent>
                    <p>
                        Are you sure you want to reactivate the program <strong>{selectedProgram?.eventName}</strong>?
                    </p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseReactivateDialog}>Cancel</Button>
                    <Button variant="contained" onClick={handleReactivateProgram}>Reactivate</Button>
                </DialogActions>
            </Dialog>


            {/* Terminate Program Dialog */}
            <Dialog open={terminateDialogOpen} onClose={handleCloseTerminateDialog}>
                <DialogTitle>Terminate Program</DialogTitle>
                <DialogContent>
                    <p>
                        Are you sure you want to terminate the program <strong>{selectedProgram?.eventName}</strong>?
                        Please confirm by entering the program name below:
                    </p>
                    {/* Add input to confirm event name */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseTerminateDialog}>Cancel</Button>
                    <Button variant="contained" onClick={handleTerminateProgram}>Terminate</Button>
                </DialogActions>
            </Dialog>

            {/* Create Program Dialog */}
            <Dialog open={createDialogOpen} onClose={handleCloseCreateDialog}>
                <DialogTitle>Create New Referral Program</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Event Name - only supports -SJW-"
                        name="eventName"
                        value={newProgram.eventName}
                        onChange={handleInputChange}
                        fullWidth
                        margin="dense"
                    />
                    <TextField
                        label="Event Episode"
                        name="eventEpisode"
                        value={newProgram.eventEpisode}
                        onChange={handleInputChange}
                        fullWidth
                        margin="dense"
                    />
                    <TextField
                        label="From Date"
                        name="fromDate"
                        type="datetime-local"
                        value={newProgram.fromDate}
                        onChange={handleInputChange}
                        fullWidth
                        margin="dense"
                    />
                    <TextField
                        label="To Date"
                        name="toDate"
                        type="datetime-local"
                        value={newProgram.toDate}
                        onChange={handleInputChange}
                        fullWidth
                        margin="dense"
                    />
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleCloseCreateDialog}>Cancel</Button>
                    <Button variant="contained" onClick={handleCreateProgram}>Create</Button>
                </DialogActions>

            </Dialog>
            {copySuccess && (
                <div style={{ color: 'green', marginTop: '10px' }}>
                    {copySuccess}
                </div>
            )}

        </div>
    );
};

export default ReferralManagement;
