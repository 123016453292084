import React, { createContext, useContext } from 'react';
import axios from 'axios';
import { useLogin } from './LoginContext'; // Assuming you have a LoginContext for managing login/logout

// Create an axios instance
const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL || 'http://localhost:3001/api'
});

// Add a request interceptor to include the JWT token
api.interceptors.request.use((config) => {
    // Retrieve the token from local storage
    const token = localStorage.getItem('token');
    
    // If a token is present, add it to the headers of all requests
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
}, (error) => {
    // Handle request errors
    return Promise.reject(error);
});

// Add a response interceptor to handle token expiration or other errors
api.interceptors.response.use((response) => {
    // If the response is successful, simply return it
    return response;
}, (error) => {
    // Handle response errors
    if (error.response && error.response.status === 401) {
        // Token is either missing, expired, or invalid
        const { message } = error.response.data;

        if (message === 'Session expired, please log in again.') {
            // Optionally, notify the user that their session has expired
            alert('Your session has expired. Please log in again.');

            // Perform logout (assuming you have a `logout` function in `useLogin`)
            const { logout } = useLogin();
            logout();

            // Optionally, redirect to the login page if required
            window.location.href = '/';
        }
    }

    // Reject all other errors to be handled by individual components or contexts
    return Promise.reject(error);
});

const AxiosContext = createContext(api);

export const AxiosProvider = ({ children }) => {
    return (
        <AxiosContext.Provider value={api}>
            {children}
        </AxiosContext.Provider>
    );
};

export const useAxios = () => {
    return useContext(AxiosContext);
};
