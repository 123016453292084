import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div style={{ padding: '20px' }}>
      <h1>Privacy Policy</h1>
      <p>Last updated: 1 Sep 2024</p>

      <p>
        Your privacy is important to us. This privacy policy explains how our app collects, uses, and shares personal information
        when you use our service. We are committed to ensuring that your privacy is protected.
      </p>

      <h2>Information We Collect</h2>
      <p>
        When you sign in using Google OAuth, we collect the following information:
      </p>
      <ul>
        <li>Your Google account information, including your email address, name, and profile picture</li>
      </ul>

      <h2>How We Use Your Information</h2>
      <p>
        The information we collect is used for the following purposes:
      </p>
      <ul>
        <li>To authenticate and log you into our app</li>
        <li>To personalize your experience within the app</li>
      </ul>

      <h2>Sharing of Information</h2>
      <p>
        We do not share, sell, or rent your personal information to third parties. We only use the data as required for the proper functioning of the app.
      </p>

      <h2>Security</h2>
      <p>
        We are committed to ensuring that your information is secure. To prevent unauthorized access or disclosure, we have put in place suitable physical, electronic, and managerial procedures to safeguard the information we collect.
      </p>

      <h2>Changes to This Policy</h2>
      <p>
        We may update this privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page.
      </p>

      <h2>Data Retention and Deletion</h2>
      <p>
        We retain your information for as long as your account is active or as needed to provide you with services. You may request the deletion of your account and personal data at any time by contacting us at [Insert Contact Information].
      </p>

      <h2>Cookies and Tracking</h2>
      <p>
        We use cookies to enhance your experience on our app. These cookies help us understand how users interact with our app, and improve its performance. You may disable cookies through your browser settings.
      </p>

      <h2>Third-Party Services</h2>
      <p>
        We may use third-party services such as analytics providers to improve the performance of our app. These services may collect data as part of their operations. We ensure that any third-party services we use comply with our data protection standards.
      </p>

      <h2>Contact Us</h2>
      <p>
        If you have any questions about this privacy policy, please contact us at: [Insert Contact Information]
      </p>
    </div>
  );
};

export default PrivacyPolicy;
