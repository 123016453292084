import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Accordion, AccordionSummary, AccordionDetails, Card, Badge, Typography, Dialog, CardMedia, CardContent, Button, IconButton, Box,
    DialogTitle, DialogContent, DialogActions
 } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAxios } from '../../contexts/AxiosContext';
import { useLogin } from '../../contexts/LoginContext';
import { useTheme } from "../../contexts/ThemeContext";
import HomeIcon from '@mui/icons-material/Home';
import EventDetailsDialog from './EventDetailsDialog'; // Import the EventDetailsDialog component
import { AnimatedTextSVG } from "../ui/textSVG";
import ReactConfetti from 'react-confetti';

const UserEvents = () => {
    const { theme } = useTheme();
    const navigate = useNavigate()
    const { userId, logout, pubProfileLink } = useLogin();
    const [userEvents, setUserEvents] = useState([]);
    const [expanded, setExpanded] = useState('active');
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [showCongratsDialog, setShowCongratsDialog] = useState(false);
    const axios = useAxios()

    useEffect(() => {
        if (userEvents.some(event => !event.read)) {
            setShowCongratsDialog(true);
        }
    }, [userEvents]);

    useEffect(() => {
        // Fetch user events on component load
        const fetchUserEvents = async () => {
            try {
                const response = await axios.get(`/event/user-events/${userId}`);
                setUserEvents(response.data.userEvents);
            } catch (error) {
                console.error('Error fetching user events:', error);
            }
        };
        fetchUserEvents();
    }, [userId]);

    // Categorize events into active, expired, and archived
    const now = new Date();
    const activeEvents = userEvents.filter(event => !event.eventId.terminated && new Date(event.eventId.toDate) > now);
    const expiredEvents = userEvents.filter(event => !event.eventId.terminated && new Date(event.eventId.toDate) <= now);
    const archivedEvents = userEvents.filter(event => event.archived);

    const handleExpand = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleOpenDialog = (eventData) => {
        setSelectedEvent(eventData);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setSelectedEvent(null);
    };

    const updateEventDetails = (updatedDetails) => {
        setUserEvents((prevEvents) =>
            prevEvents.map(event =>
                event.eventId._id === updatedDetails.eventId._id
                    ? { ...event, ...updatedDetails }
                    : event
            )
        );
        setSelectedEvent((prevEvent) => ({
            ...prevEvent,
            ...updatedDetails
        }));
    };


    return (
        <div style={{ padding: '30px' }}>
            <Typography variant="h3" gutterBottom>  {/* Add gutterBottom for spacing below */}
                Welcome to Events
            </Typography>

            <Typography variant="body1" gutterBottom>  {/* Add gutterBottom for spacing below */}
                Here you find the best opportunity to showcase your talent! Do Not Miss!
            </Typography>

            <div style={{ height: '20px' }}></div> {/* Add a gap before the accordion */}
            <div height="100px"></div>
            {/* Active Events */}
            <Accordion
                expanded={expanded === 'active'}
                onChange={handleExpand('active')}
                disabled={activeEvents.length === 0} // Disable if no active events
                style={{ backgroundColor: 'black', border: '1px solid #ccc', color: '#fff' }} // Black background and outlined style
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">Active Events {activeEvents.length === 0 && ' - No Events'}</Typography> {/* Update title */}
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {activeEvents.map((userEvent, index) => (
                            <Card
                                key={index}
                                style={{
                                    width: '100%',
                                    maxWidth: '300px',
                                    margin: '10px',
                                    position: 'relative',
                                    border: userEvent.read ? '1px solid #ccc' : '2px solid #1976d2', // Use a thicker, colored border for unread cards
                                    boxShadow: userEvent.read ? 'none' : '0 0 10px rgba(25, 118, 210, 0.5)', // Add a subtle shadow for unread cards
                                }}
                            >
                                {!userEvent.read && (
                                    <Badge
                                        badgeContent="Unread"
                                        color="error"
                                        style={{ position: 'absolute', top: '10px', left: '25px' }}
                                        sx={{ '& .MuiBadge-badge': { backgroundColor: 'black', color: 'white' } }}  // Black background for the badge
                                    />
                                )}
                                {/* Existing Badge */}
                                <Badge
                                    badgeContent={userEvent.optedIn ? 'In' : 'Out'}
                                    color={userEvent.optedIn ? 'success' : 'error'}
                                    style={{ position: 'absolute', top: '10px', right: '20px' }}
                                    sx={{ '& .MuiBadge-badge': { backgroundColor: 'black', color: 'white' } }}  // Black background for the badge
                                />

                                <CardMedia
                                    component="img"
                                    height="140"
                                    image={userEvent.eventId.images?.[0] || 'https://via.placeholder.com/400x300.png?text=No+Image'}
                                    alt={userEvent.eventId.name}
                                />
                                <CardContent>
                                    <Typography variant="h6">{userEvent.eventId.name}</Typography>
                                    <Typography variant="body2">{userEvent.eventId.location}</Typography>
                                    <Typography variant="body2">From: {new Date(userEvent.eventId.fromDate).toLocaleString()}</Typography>
                                    <Typography variant="body2">To: {new Date(userEvent.eventId.toDate).toLocaleString()}</Typography>
                                    <Button
                                        variant="outlined"
                                        onClick={() => handleOpenDialog(userEvent)}
                                        style={{ marginTop: '10px' }}
                                    >
                                        Open Details
                                    </Button>
                                </CardContent>
                            </Card>
                        ))}
                    </div>
                </AccordionDetails>
            </Accordion>

            {/* Expired Events */}
            <Accordion
                expanded={expanded === 'expired'}
                onChange={handleExpand('expired')}
                disabled={expiredEvents.length === 0} // Disable if no active events
                style={{ backgroundColor: 'black', border: '1px solid #ccc', color: '#fff' }} // Black background and outlined style
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">Expired Events {expiredEvents.length === 0 && ' - No Events'}</Typography> {/* Update title */}
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {expiredEvents.map((userEvent, index) => (
                            <Card
                                key={index}
                                style={{
                                    width: '100%',  // Make the card take up full width on smaller screens
                                    maxWidth: '300px',  // Set a maximum width for larger screens
                                    margin: '10px',
                                    position: 'relative'
                                }}
                            >
                                <Badge badgeContent="Expired" color="error" style={{ position: 'absolute', top: '10px', right: '10px' }} />
                                <CardMedia
                                    component="img"
                                    height="140"
                                    image={userEvent.eventId.images?.[0] || 'https://via.placeholder.com/400x300.png?text=No+Image'}
                                    alt={userEvent.eventId.name}
                                />
                                <CardContent>
                                    <Typography variant="h6">{userEvent.eventId.name}</Typography>
                                    <Typography variant="body2">{userEvent.eventId.location}</Typography>
                                    <Typography variant="body2">From: {new Date(userEvent.eventId.fromDate).toLocaleString()}</Typography>
                                    <Typography variant="body2">To: {new Date(userEvent.eventId.toDate).toLocaleString()}</Typography>
                                </CardContent>
                            </Card>
                        ))}
                    </div>
                </AccordionDetails>
            </Accordion>

            {/* Archived Events */}
            <Accordion
                expanded={expanded === 'archived'}
                onChange={handleExpand('archived')}
                disabled={archivedEvents.length === 0} // Disable if no active events
                style={{ backgroundColor: 'black', border: '1px solid #ccc', color: '#fff' }} // Black background and outlined style
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">Archived Events {archivedEvents.length === 0 && ' - No Events'}</Typography> {/* Update title */}
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {archivedEvents.map((userEvent, index) => (
                            <Card
                                key={index}
                                style={{
                                    width: '100%',  // Make the card take up full width on smaller screens
                                    maxWidth: '300px',  // Set a maximum width for larger screens
                                    margin: '10px',
                                    position: 'relative'
                                }}
                            >
                                <Badge badgeContent="Archived" color="default" style={{ position: 'absolute', top: '10px', right: '10px' }} />
                                <CardMedia
                                    component="img"
                                    height="140"
                                    image={userEvent.eventId.images?.[0] || 'https://via.placeholder.com/400x300.png?text=No+Image'}
                                    alt={userEvent.eventId.name}
                                />
                                <CardContent>
                                    <Typography variant="h6">{userEvent.eventId.name}</Typography>
                                    <Typography variant="body2">{userEvent.eventId.location}</Typography>
                                    <Typography variant="body2">From: {new Date(userEvent.eventId.fromDate).toLocaleString()}</Typography>
                                    <Typography variant="body2">To: {new Date(userEvent.eventId.toDate).toLocaleString()}</Typography>
                                    {/* Button to open event details dialog */}

                                </CardContent>
                            </Card>
                        ))}
                    </div>
                </AccordionDetails>
            </Accordion>

            {/* Render the EventDetailsDialog */}
            {selectedEvent && (
                <EventDetailsDialog
                    open={dialogOpen}
                    onClose={handleCloseDialog}
                    eventDetails={selectedEvent}
                    userId={userId} // Replace with actual user ID
                    updateEventDetails={updateEventDetails} // Pass the update function
                />
            )}

            <Dialog
                open={showCongratsDialog}
                onClose={() => setShowCongratsDialog(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>🎉 Congratulations! 🎉</DialogTitle>
                <DialogContent>
                    <Typography variant="h6">
                        You have a new event that you haven't checked out yet!
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowCongratsDialog(false)} color="primary" variant="contained">
                        Awesome!
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Confetti Animation */}
            {showCongratsDialog && <ReactConfetti width={window.innerWidth} height={window.innerHeight} />}
        </div>
    );
};

export default UserEvents;
