import React from 'react';
import { Navigate } from 'react-router-dom';
import { useLogin } from '../contexts/LoginContext';

const PrivateRoute = ({ element, roles = [] }) => {
    const { isLoggedIn, globalRoles } = useLogin();

    // Check if the user is logged in
    if (!isLoggedIn) {
        return <Navigate to="/" replace />;
    }

    // Check if any roles are required and if the user's globalRoles include any of the required roles
    if (roles.length > 0 && !roles.some(role => globalRoles.includes(role))) {
        return <Navigate to="/" replace />;
    }

    return element;
};

export default PrivateRoute;
