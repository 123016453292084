import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions, List, ListItem, ListItemText, Autocomplete, Typography } from '@mui/material';
import { useAxios } from '../../../contexts/AxiosContext';

const TeamManagement = () => {
    const [teams, setTeams] = useState([]);
    const [events, setEvents] = useState([]);
    const [open, setOpen] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [newTeamName, setNewTeamName] = useState('');
    const [selectedEventId, setSelectedEventId] = useState('');
    const axios = useAxios();

    useEffect(() => {
        fetchEvents();
        fetchTeams();
    }, [selectedEventId]);

    const fetchEvents = async () => {
        try {
            const response = await axios.get('/event/list');
            setEvents(response.data.activeEvents);
            // Remove the auto-selection line here
        } catch (error) {
            console.error('Failed to fetch events:', error);
        }
    };


    const fetchTeams = async () => {
        try {
            const response = await axios.get(`/event/team/?eventId=${selectedEventId}`);
            setTeams(response.data.teams);
        } catch (error) {
            console.error('Failed to fetch teams:', error);
        }
    };

    const handleCreateTeam = async () => {
        if (!selectedEventId) return;
        try {
            const response = await axios.post('/event/team/create', { name: newTeamName, eventId: selectedEventId });
            setTeams([...teams, response.data.team]);
            setOpen(false);
            setNewTeamName('');
        } catch (error) {
            console.error('Failed to create team:', error);
        }
    };

    const handleToggleTeamStatus = async () => {
        if (!selectedTeam) return;  // Ensure there is a selected team
        try {
            console.log(selectedTeam);
            const updatedTeam = await axios.put(`/event/team/${selectedTeam._id}/status`, { status: !selectedTeam.isactive });
            const updatedTeams = teams.map(t => t._id === selectedTeam._id ? { ...t, isactive: !selectedTeam.isactive } : t);
            setTeams(updatedTeams);
            setConfirmOpen(false); // Close dialog after updating
        } catch (error) {
            console.error('Error updating team status:', error);
        }
    };

    const handleDialogClose = () => {
        setOpen(false);
    };

    const handleConfirmOpen = (team) => {
        setSelectedTeam(team);
        setConfirmOpen(true);
    };

    const handleConfirmClose = () => {
        setConfirmOpen(false);
    };

    return (
        <div>
            <Button variant="contained" onClick={() => setOpen(true)}>Create Team</Button>
            <List>
                {teams.length > 0 ? teams.map((team) => (
                    <ListItem key={team._id}>
                        <ListItemText
                            primary={team.name}
                            secondary={
                                <>
                                    {`Created By: ${team.createdBy.name}, Created On: ${team.createdAt}`} <br />
                                    {`Updated By: ${team.updatedBy.name}, Updated On: ${team.updatedAt}`}
                                </>
                            }
                        />

                        <Button
                            variant="contained"
                            style={{ backgroundColor: team.isactive ? 'green' : 'red', color: 'white', marginRight: '10px' }}
                            onClick={() => handleToggleTeamStatus(team)}
                        >
                            {team.isactive ? 'Active' : 'Inactive'}
                        </Button>
                        <Button variant="outlined" onClick={() => handleConfirmOpen(team)}>
                            Toggle Status
                        </Button>
                    </ListItem>
                )) : <ListItem>No teams found</ListItem>}
            </List>
            <Dialog open={open} onClose={handleDialogClose}>
                <DialogTitle>Create New Team</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Team Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={newTeamName}
                        onChange={(e) => setNewTeamName(e.target.value)}
                    />
                    <Autocomplete
                        disablePortal
                        options={events}
                        getOptionLabel={(option) => option.name || ""}
                        renderInput={(params) => <TextField {...params} label="Choose an Active Event" margin="dense" variant="standard" placeholder="Choose an Active Event" />}
                        onChange={(_, newValue) => {
                            setSelectedEventId(newValue ? newValue._id : "");
                        }}
                        isOptionEqualToValue={(option, value) => option._id === value._id}
                        value={events.find(event => event._id === selectedEventId) || null}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Cancel</Button>
                    <Button onClick={handleCreateTeam} disabled={!selectedEventId || !newTeamName}>Create</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={confirmOpen} onClose={handleConfirmClose}>
                <DialogTitle>Confirm Action</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to change the status of this team?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmClose}>No</Button>
                    <Button onClick={handleToggleTeamStatus} color="primary">Yes</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default TeamManagement;
