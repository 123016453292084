import React from 'react';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from "@mui/material";

// Update: Added onClick prop to handle clicks
export const AnimatedTextSVG = ({ text, fontSize, onClick }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // Set different x and y values based on the device type
    const xValue = isMobile ? 15 : 30; // Adjust the x value for mobile vs desktop
    const yValue = isMobile ? 60 : 60; // Adjust the y value for mobile vs desktop
    const width = isMobile? "200" : "300"

    return (
        // Wrap the SVG in a div or button to handle the onClick event
        <div onClick={onClick} style={{ cursor: 'pointer' }}>
            <svg width={width} height="100" xmlns="http://www.w3.org/2000/svg">
                <text
                    x={xValue}
                    y={yValue}
                    fontSize={fontSize}
                    fill="none"
                    fontWeight="800"
                    stroke={theme.palette.text.primary}
                    strokeWidth="1"
                    strokeDasharray="100"
                    strokeDashoffset="100"
                >
                    <tspan>{text}</tspan>
                    <animate
                        attributeName="stroke-dashoffset"
                        from="100"
                        to="0"
                        dur="3s"
                        fill="freeze"
                    />
                    <animate
                        attributeName="fill"
                        from="none"
                        to={theme.palette.text.primary}
                        begin="3s"
                        dur="5s"
                        fill="freeze"
                    />
                    <animate
                        attributeName="stroke-dashoffset"
                        from="0"
                        to="100"
                        begin="4.5s"
                        dur="1.5s"
                        fill="freeze"
                    />
                </text>
            </svg>
        </div>
    );
};
