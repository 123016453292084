function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

const sendSubscriptionToBackend = async (subscription, axiosInstance, userId) => {
  try {
    await axiosInstance.post('/notifications/subscribe', {
      userId,
      subscription
    });
    console.log('Subscription sent to backend successfully');
  } catch (error) {
    console.error('Failed to send subscription to backend:', error);
  }
};

export const subscribeUserToPush = (VAPID_PUBLIC_KEY, axiosInstance, userId) => {
  if ('serviceWorker' in navigator && 'PushManager' in window) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.pushManager
        .subscribe({
          userVisibleOnly: true, 
          applicationServerKey: urlBase64ToUint8Array(VAPID_PUBLIC_KEY)
        })
        .then((subscription) => {
          console.log('User is subscribed to push:', subscription);
          sendSubscriptionToBackend(subscription, axiosInstance, userId);
        })
        .catch((error) => {
          console.error('Failed to subscribe the user:', error);
        });
    });
  }
};

export const registerServiceWorkerAndSubscribe = (VAPID_PUBLIC_KEY, axiosInstance, userId) => {
  if ('serviceWorker' in navigator && 'PushManager' in window) {
    navigator.serviceWorker
      .register('/service-worker.js')
      .then((registration) => {
        console.log('Service Worker registered with scope:', registration.scope);

        // Send the API base URL to the service worker
        if (registration.active) {
          registration.active.postMessage({
            apiBaseUrl: process.env.REACT_APP_API_BASE_URL || 'http://localhost:3001/api'
          });
        }

        Notification.requestPermission().then((permission) => {
          if (permission === 'granted') {
            console.log('Notification permission granted.');

            // Wait for the service worker to be ready
            navigator.serviceWorker.ready.then((registration) => {
              subscribeUserToPush(VAPID_PUBLIC_KEY, axiosInstance, userId);
            });
          } else {
            console.log('Notification permission denied.');
          }
        });
      })
      .catch((error) => {
        console.error('Service Worker registration failed:', error);
      });
  }
};
