import React, { useState, useEffect } from 'react';
import {
  Box, Typography, Grid, Button, Dialog, DialogContent, DialogTitle, Autocomplete, TextField, IconButton, ToggleButton, ToggleButtonGroup
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useFormContext, useFieldArray, useWatch } from 'react-hook-form';
import formConfig from "../config.json";
import SkillLevelGauge from '../../ui/SkillLevelGauge';  // Import the gauge component

// Configuration Object
const config = formConfig.userDetails.languageOptions;

const skills = ['l', 's', 'r', 'w'];

const skillLabels = {
  l: 'Listening',
  s: 'Speaking',
  r: 'Reading',
  w: 'Writing'
};

const LanguageProficiencyComponent = (isDark) => {
  const { control } = useFormContext();
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'userDetails.languages_v2'
  });
  const watchedLanguages = useWatch({
    control,
    name: 'userDetails.languages_v2',
    defaultValue: []
  });

  const [openDialog, setOpenDialog] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState({ value: '', privacy: false, lsrw: { l: '', s: '', r: '', w: '' } });
  const [editMode, setEditMode] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [languageToDelete, setLanguageToDelete] = useState(null);

  useEffect(() => {
    if (watchedLanguages.length === 0) {
      handleOpenDialog();
    }
  }, [watchedLanguages]);

  const availableLanguages = config.availableLanguages.filter(
    lang => !watchedLanguages.some(l => l.value === lang)
  );

  const handleOpenDialog = (language = { value: '', privacy: false, lsrw: { l: '', s: '', r: '', w: '' } }) => {
    setCurrentLanguage(language);
    setEditMode(!!language.value);
    setOpenDialog(true);
  };

  const handleSaveLanguage = () => {
    if (!currentLanguage.value || Object.values(currentLanguage.lsrw).some(level => !level)) {
      alert(config.messages.selectLevel);
      return;
    }
    if (editMode) {
      update(watchedLanguages.findIndex(lang => lang.value === currentLanguage.value), currentLanguage);
    } else {
      append(currentLanguage);
    }
    setOpenDialog(false);
  };

  const handleLanguageChange = (event, newValue) => {
    setCurrentLanguage(prev => ({ ...prev, value: newValue, lsrw: { l: '', s: '', r: '', w: '' } }));
  };

  const handleSkillChange = (skill, newValue) => {
    setCurrentLanguage(prev => ({ ...prev, lsrw: { ...prev.lsrw, [skill]: newValue + 1 } }));  // Storing value as 1-6
  };

  const handleOpenConfirmDialog = (language) => {
    setLanguageToDelete(language);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    if (languageToDelete) {
      remove(watchedLanguages.findIndex(lang => lang.value === languageToDelete.value));
    }
    setOpenConfirmDialog(false);
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Button variant="contained" startIcon={<AddIcon />} onClick={() => handleOpenDialog()}>
        {config.buttonTexts.addLanguage} ({watchedLanguages.length})
      </Button>
      <Grid container spacing={2} sx={{ marginTop: 2 }}>
        {watchedLanguages.map((lang, index) => (
          <Grid item key={index}>
            <Box
              sx={{
                boxShadow: 1,
                borderRadius: 2,
                backgroundColor: 'background.paper',
                width: '280px',
                padding: 2,
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mb: 2,
                }}
              >
                <Typography variant="h6">{lang.value}</Typography>
                <Box>
                  <IconButton size="small" onClick={() => handleOpenDialog(lang)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton size="small" onClick={() => remove(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
              <Grid container spacing={2}>
                {Object.keys(skillLabels).map(skill => (
                  <Grid item xs={6} key={skill} sx={{ textAlign: 'center' }}>
                    <Typography variant="body2">{skillLabels[skill]}</Typography>
                    <SkillLevelGauge level={lang.lsrw[skill]} size={40} /> {/* Use SkillLevelGauge to display the level */}
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
        ))}
      </Grid>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} fullWidth maxWidth="md">
        <DialogTitle>{editMode ? config.dialogTitles.edit : config.dialogTitles.add}</DialogTitle>
        <DialogContent>
          <Autocomplete
            options={availableLanguages}
            value={currentLanguage.value}
            onChange={handleLanguageChange}
            renderInput={(params) => <TextField {...params} label="Select Language" variant="outlined" fullWidth required />}
            disabled={editMode}
          />
          <Box sx={{ marginTop: 2 }}>
            {Object.keys(skillLabels).map(skill => (
              <Box key={skill} sx={{ marginBottom: 3 }}>
                <Typography>{skillLabels[skill]}</Typography>
                <ToggleButtonGroup
                  value={currentLanguage.lsrw[skill] - 1}  // Displaying 0-5 but storing 1-6
                  exclusive
                  onChange={(event, newValue) => handleSkillChange(skill, newValue)}
                  sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}
                >
                  {[0, 1, 2, 3, 4, 5].map(value => (
                    <ToggleButton
                      key={value}
                      value={value}
                      sx={{ width: 20, height: 20 }}
                    >
                      {value}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Box>
            ))}
          </Box>
          <Button onClick={handleSaveLanguage} sx={{ marginTop: 2, float: 'right' }}>
            {!editMode?config.buttonTexts.save:'Update Proficiency'}
          </Button>
        </DialogContent>
      </Dialog>

      <Dialog open={openConfirmDialog} onClose={handleCancelDelete} maxWidth="sm" fullWidth>
        <DialogTitle>{config.dialogTitles.confirmDelete}</DialogTitle>
        <DialogContent>
          <Typography>{config.messages.confirmDelete.replace("${language}", languageToDelete?.value)}</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
            <Button onClick={handleCancelDelete} color="primary">
              {config.buttonTexts.cancel}
            </Button>
            <Button onClick={handleConfirmDelete} color="error" sx={{ marginLeft: 1 }}>
              {config.buttonTexts.delete}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default LanguageProficiencyComponent;
