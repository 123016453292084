import React from 'react';
import { Button } from "@mui/material";
import googleIcon from '../assets/SVG/socialLogins/google_colour_icon.svg';

const GoogleLoginButton = () => {
    const handleGoogleLogin = () => {
        const queryParams = new URLSearchParams(window.location.search);
        const referral = queryParams.get('referral');
        const utmSource = queryParams.get('utm_source');
        const backendUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

        // Redirect to backend Google OAuth route
        window.location.href = `${backendUrl}/auth/google?referral=${referral}&utm_source=${utmSource}`;
    };

    return (
        <Button
            className="relative group/btn block w-full h-10 font-medium rounded-md"
            onClick={handleGoogleLogin}
            style={{
                backgroundColor: "#FFFFFF",
                color: "#000",
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
                padding: "12px",
            }}
        >
            <img src={googleIcon} alt="Google icon" style={{ width: '20px', height: '20px', marginRight: '10px' }} />
            Continue with Google
        </Button>
    );
};

export default GoogleLoginButton;
