import Popover from "@mui/material/Popover";
import React, { useEffect, useState } from "react";
import { useAxios } from "../contexts/AxiosContext";
import { useTheme } from "../contexts/ThemeContext";
import { useLogin } from "../contexts/LoginContext";
import { useNavigate } from "react-router-dom";

import {
  Tabs, Tab, IconButton, Drawer, useMediaQuery, Box,
  Typography, Tooltip, Button, Grid, Collapse, Avatar, Card, CardContent
} from "@mui/material";
import {
  Movie as MovieIcon, Tv as TvIcon, Work as WorkIcon, Book as BookIcon,
  School as SchoolIcon, Description as DescriptionIcon, Language as LanguageIcon,
  ImportContacts as ImportContactsIcon, CastForEducation as CastForEducationIcon,
  AddCircleOutline as AddCircleOutlineIcon, InfoOutlined as InfoOutlinedIcon,
  ArrowBackIos as ArrowBackIosIcon, ArrowForwardIos as ArrowForwardIosIcon,
  ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon,
  ExitToApp as ExitToAppIcon,
  BorderColor
} from "@mui/icons-material";
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import GlassBox from "./ui/GlassBox";
import logger from "../logger";
import SkillLevelGauge from "./ui/SkillLevelGauge"
import FavoriteIcon from '@mui/icons-material/Favorite';
import { formatDate, calculateDuration } from '../utils/ViewUtils';

const ICON_SIZE = { fontSize: "20px" };

const iconsMap = {
  0: <BookIcon sx={ICON_SIZE} />,
  1: <SchoolIcon sx={ICON_SIZE} />,
  2: <DescriptionIcon sx={ICON_SIZE} />,
  3: <LanguageIcon sx={ICON_SIZE} />,
  4: <ImportContactsIcon sx={ICON_SIZE} />,
  5: <CastForEducationIcon sx={ICON_SIZE} />,
};

export function WelcomeUserPage() {
  const { theme } = useTheme();
  const api = useAxios();
  const { userId, logout, isNewUser } = useLogin();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [media, setMedia] = useState({
    movie: [], series: [], documentary: [], game: [], book: [], author: [], writer: [], director: [],
  });
  const [selectedPoster, setSelectedPoster] = useState(null);
  const [currentExperienceIndex, setCurrentExperienceIndex] = useState(0);
  const [selectedWorkDetails, setSelectedWorkDetails] = useState(null);
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedAddTab, setSelectedAddTab] = useState(20);
  const [favouritesExpanded, setFavouritesExpanded] = useState(false);
  const [currentPortfolioIndex, setCurrentPortfolioIndex] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [currentMediaIndex, setCurrentMediaIndex] = useState({
    movie: 0, series: 0, documentary: 0, game: 0, book: 0, author: 0, writer: 0, director: 0,
  });
  const [collaborations, setCollaborations] = useState([]); // Add this line
  const [anchorEl, setAnchorEl] = useState(null); // Anchor element for the popover
  const [selectedItem, setSelectedItem] = useState(null);
  const handleItemClick = (event, item) => {
    setAnchorEl(event.currentTarget); // Set the clicked element as the anchor
    setSelectedItem(item);             // Store the selected item (creator or creation)
  };


  // Function to handle tab change
  const handleAddTabChange = (event, newValue) => {
    setSelectedAddTab(newValue);
  };

  const handleExperienceNext = () => {
    setCurrentExperienceIndex((prev) => (prev + 1) % profile.experience.length);
  };

  const handleExperiencePrev = () => {
    setCurrentExperienceIndex((prev) => (prev - 1 + profile.experience.length) % profile.experience.length);
  };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const { data } = await api.get(`/profile/${userId}`);
        if (data) {
          setProfile(data);
          // console.log(data)
          logger(`Profile data fetched successfully: ${JSON.stringify(data)}`, "debug");

          if (data.originalIps.length > 0) {
            setSelectedPoster(data.originalIps[0].poster);
            setSelectedWorkDetails(data.originalIps[0]);
          }
        } else {
          logger("Profile data is null, navigating to edit profile", "warn");
        }
      } catch (error) {
        logger(`Error fetching profile: ${error.message}`, "error");
      }
    };

    const fetchMedia = async () => {
      try {
        const { data } = await api.get(`/mediaquery/${userId}/media_v2`);
        if (data) setMedia(data);
        // console.log(data)
        // console.log("Fetched media data: ", data.author);
      } catch (err) {
        console.error("Error fetching media:", err.message);
      }
    };

    const fetchCollaborations = async () => {
      try {
        const { data } = await api.get(`/content/collaborations/${userId}`);
        // console.log(data)
        setCollaborations(data);
      } catch (error) {
        console.error("Error fetching collaborations:", error);
      }
    };

    setLoading(true);
    Promise.all([fetchProfile(), fetchMedia(), fetchCollaborations()])
      .finally(() => setLoading(false));
  }, [userId, api]);

  const [currentCollaborationIndex, setCurrentCollaborationIndex] = useState(0);

  const handleCollaborationPrev = () => {
    setCurrentCollaborationIndex((prev) => (prev > 0 ? prev - 1 : 0));
  };

  const handleCollaborationNext = () => {
    setCurrentCollaborationIndex((prev) => (prev < collaborations.length - 1 ? prev + 1 : collaborations.length - 1));
  };

  const handleTabChange = (event, newValue) => {
    // If the "My Work" or "My Experience" tabs (value 0 or 1) are clicked, close the favourites section
    if (newValue === 0 || newValue === 1) {
      setFavouritesExpanded(false);
    }

    // Set the selected tab as usual
    setSelectedTab(newValue);
  };

  const handleAddButtonClick = (mediaType) => {
    const routes = {
      ownWorks: "addStory", movie: "addMovie", series: "addSeries",
      documentary: "addDocumentary", game: "addGame", director: "addStoryOfMyLife",
      book: "addBook",
    };
    navigate("/buildprofile", { state: { redirectSection: routes[mediaType] } });
  };

  const handlePortfolioNext = () => setCurrentPortfolioIndex((prev) => (prev + 1) % profile.originalIps.length);
  const handlePortfolioPrev = () => setCurrentPortfolioIndex((prev) => (prev - 1 + profile.originalIps.length) % profile.originalIps.length);
  const handleMediaNext = (type) => setCurrentMediaIndex((prev) => ({ ...prev, [type]: (prev[type] + 1) % media[type].length }));
  const handleMediaPrev = (type) => setCurrentMediaIndex((prev) => ({ ...prev, [type]: (prev[type] - 1 + media[type].length) % media[type].length }));

  const currentPortfolio = profile?.originalIps[currentPortfolioIndex];
  const currentMovie = media.movie[currentMediaIndex.movie];
  const currentSeries = media.series[currentMediaIndex.series];
  const currentDocumentary = media.documentary[currentMediaIndex.documentary];
  const currentGame = media.game[currentMediaIndex.game];
  const currentBook = media.book[currentMediaIndex.book];
  const qualifications = profile?.qualifications || {};


  const sections = [
    { key: "awards", label: "Awards", data: qualifications.awards },
    { key: "education", label: "Education", data: qualifications.education },
    { key: "workExperience", label: "Employment History", data: qualifications.workExperience },
  ].filter((section) => section.data?.length > 0);

  const currentSection = sections[currentSectionIndex];

  if (loading) {
    return <div>...</div>;
  }

  if (!profile) {
    return (
      <div>
        <Typography variant="h6" sx={{ fontSize: "20px", fontWeight: "800" }}>....</Typography>
      </div>
    );
  }

  // Create constants for Favorite Creators and Favorite Creations
  const favoriteCreators = [
    ...media.director,
    ...media.writer,
    ...media.author,
  ];

  // Ensure unique creators based on their ID
  const uniqueCreators = Array.from(new Set(favoriteCreators.map(item => item._id)))
    .map(id => favoriteCreators.find(item => item._id === id));

  const favoriteCreations = [
    ...media.movie,
    ...media.series,
    ...media.documentary,
    ...media.game,
    ...media.book,
  ];

  return (
    <div
      style={{
        minHeight: "80vh",
        width: "100%",
        backgroundColor: theme.palette.background.default,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative", // change to relative
        overflowY: isMobile ? "auto" : "hidden", // update overflow
      }}
    >
      <main
        style={{
          flexGrow: 1,
          padding: isMobile ? "" : `0 ${theme.spacing(3)}`,
          width: "100%",
          height: "100%",
          overflowY: "hidden",
        }}
      >

        <Grid container spacing={1} sx={{ width: "100%", padding: isMobile ? "10px" : "0" }}>


          {/* Left Side: GlassBox components stacked vertically */}
          <Grid item xs={12} md={3.75}>
            <Grid container direction="column" spacing={2} sx={{ width: "100%", margin: isMobile ? 'auto' : '' }}>
              <Grid item xs={12}>
                {/* Bio */}
                <GlassBox color={theme.palette.text.primary} width={{ mobile: "100%", desktop: "400px" }} height={{ mobile: "auto", desktop: "22vh" }}
                  sx={{
                    position: "relative",
                    '&:hover .add-icon': { opacity: 0.5 },
                  }}
                >
                  <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Box>
                      <Typography variant="h6" component="div" sx={{ fontSize: "20px", fontWeight: "800" }}>
                        <strong>{profile.userDetails.fullName.value}</strong>
                      </Typography>
                      <Typography variant="body1" sx={{ fontSize: "14px", fontWeight: "300" }}>
                        {profile.userDetails.jobTitles.join(", ")}
                      </Typography>

                      <Typography variant="body2" sx={{ fontSize: "14px", fontWeight: "300" }}>
                        {profile.userDetails.location.value}
                      </Typography>
                    </Box>
                    <Box>
                      {profile.userDetails.profile_path ? (
                        <Avatar
                          alt={`${profile.userDetails.fullName.value}`}
                          src={profile.userDetails.profile_path.value}
                          sx={{ width: 56, height: 56 }} // Adjust size as needed
                        />
                      ) : (
                        <Avatar sx={{ width: 56, height: 56 }}>
                          {/* If you want to show the user's initials or a placeholder */}
                          {profile.userDetails.fullName.value
                            ? profile.userDetails.fullName.value.charAt(0).toUpperCase()
                            : "?"}
                        </Avatar>
                      )}
                    </Box>
                  </Box>

                  <Box display="flex" justifyContent="left" sx={{ marginTop: "15px" }}>
                    <Tooltip title={profile.userDetails.phone.value}>
                      <IconButton color="primary" href={profile.userDetails.phone.value} target="_blank" rel="noopener noreferrer">
                        <PhoneAndroidOutlinedIcon sx={{ color: theme.palette.text.primary }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={profile.userDetails.email.value}>
                      <IconButton color="primary" href={profile.userDetails.email.value} target="_blank" rel="noopener noreferrer">
                        <AlternateEmailOutlinedIcon sx={{ color: theme.palette.text.primary }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={profile.userDetails.imdbProfileLink.value}>
                      <IconButton color="primary" href={profile.userDetails.imdbProfileLink.value} target="_blank" rel="noopener noreferrer">
                        <MovieIcon sx={{ color: theme.palette.text.primary }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={profile.userDetails.website.value}>
                      <IconButton color="primary" href={profile.userDetails.website.value} target="_blank" rel="noopener noreferrer">
                        <LanguageIcon sx={{ color: theme.palette.text.primary }} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </GlassBox>
              </Grid>

              {/* Qualifications */}
              <Grid item xs={12}>
                {sections.length > 0 && (
                  <GlassBox style={{
                    transition: "height 0.5s ease",
                  }}
                    color={theme.palette.text.primary} width={{ mobile: "100%", desktop: "400px" }} height={{ mobile: "auto", desktop: "26vh" }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px" }}>
                      <IconButton onClick={() => setCurrentSectionIndex((prevIndex) => (prevIndex - 1 + sections.length) % sections.length)} sx={{ opacity: 0.7, color: "white" }}>
                        <ArrowBackIosIcon />
                      </IconButton>
                      <Typography variant="h6" component="div" sx={{ fontSize: "20px", fontWeight: "800" }}>
                        {currentSection.label}
                      </Typography>
                      <IconButton onClick={() => setCurrentSectionIndex((prevIndex) => (prevIndex + 1) % sections.length)} sx={{ opacity: 0.7, color: "white" }}>
                        <ArrowForwardIosIcon />
                      </IconButton>
                    </Box>

                    <Box sx={{ overflowY: "auto", maxHeight: "20vh" }}>
                      {currentSection.data.map((item, index) => (
                        <Box key={index} sx={{ marginBottom: "10px" }}>
                          <Typography variant="body1" sx={{ fontSize: "14px", fontWeight: "300" }}>
                            {item.name || item.degree || item.position}
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "14px", fontWeight: "300" }}>
                            {new Date(item.year || item.institution || item.company).getFullYear() || item.institution || item.company}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </GlassBox>
                )}
              </Grid>

              {/* Langauges */}
              <Grid item xs={12}>
                <GlassBox
                  color={theme.palette.text.primary}
                  width={{ mobile: "100%", desktop: "400px" }}
                  height={{ mobile: "auto", desktop: "32vh" }}
                  sx={{
                    position: "relative",
                    padding: "20px",
                    '&:hover .add-icon': { opacity: 0.5 },
                  }}
                >
                  <Grid container spacing={1.5}>
                    <Grid item xs={12} sx={{ justifyItems: "center" }}>
                      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", zIndex: 1 }}>
                        <Typography variant="body1" component="div" sx={{ fontSize: "20px", fontWeight: "800" }}>
                          Languages Known   0-5
                        </Typography>
                      </Box>
                    </Grid>

                    {/* Table headers */}
                    <Grid item xs={4} sx={{ justifyItems: "start", marginRight: '-8px' }} >
                      <Typography variant="body1" component="div" sx={{ fontSize: "14px", fontWeight: "800" }}>
                        Name
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="body1" component="div" sx={{ fontSize: "14px", fontWeight: "800" }}>
                        Listen
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="body1" component="div" sx={{ fontSize: "14px", fontWeight: "800" }}>
                        Speak
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="body1" component="div" sx={{ fontSize: "14px", fontWeight: "800" }}>
                        Read
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="body1" component="div" sx={{ fontSize: "14px", fontWeight: "800" }}>
                        Write
                      </Typography>
                    </Grid>

                    {/* Language details */}
                    <Box sx={{ height: '100px', overflow: 'auto', margin: "10px 0px 0px 10px" }}>
                      <Grid container spacing={1}>
                        {profile.userDetails.languages_v2.map((language) => (
                          <React.Fragment key={language._id}>
                            <Grid item xs={4}>
                              <Typography variant="body2" sx={{ fontSize: "14px", fontWeight: "300" }}>
                                {language.value}
                              </Typography>
                            </Grid>
                            {/* Pass correlated size for SkillLevelGauge based on typography */}
                            <Grid item xs={2}>
                              <SkillLevelGauge level={language.lsrw.l} size={28} /> {/* Adjust size */}
                            </Grid>
                            <Grid item xs={2}>
                              <SkillLevelGauge level={language.lsrw.s} size={28} /> {/* Adjust size */}
                            </Grid>
                            <Grid item xs={2}>
                              <SkillLevelGauge level={language.lsrw.r} size={28} /> {/* Adjust size */}
                            </Grid>
                            <Grid item xs={2}>
                              <SkillLevelGauge level={language.lsrw.w} size={28} /> {/* Adjust size */}
                            </Grid>
                          </React.Fragment>
                        ))}
                      </Grid>
                    </Box>
                  </Grid>

                </GlassBox>
              </Grid>

            </Grid>
          </Grid>

          {/* Right Side: Container for both desktop and mobile view */}
          <Grid item xs={12} md={8.25}>
            <Grid container direction="column" spacing={2} sx={{ width: "100%", margin: isMobile ? 'auto' : '' }}>
              <Grid item xs={12}>
                <GlassBox color={theme.palette.text.primary} width={{ mobile: "100%", desktop: "100%" }} height={{ mobile: "auto", desktop: "49vh" }} sx={{ padding: "20px", maxWidth: isMobile ? 'auto' : "65vw" }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', maxWidth: "75vw" }}>
                    {/* Main Tabs */}
                    <Tabs
                      value={selectedTab}
                      onChange={(event, newValue) => setSelectedTab(newValue)} // Ensures only `selectedTab` is updated
                      aria-label="Main tabs"
                      gap={1}
                      variant="scrollable"
                      scrollButtons="auto"
                      TabIndicatorProps={{ style: { backgroundColor: 'white', color: 'white' } }}
                      sx={{
                        '.MuiTab-root': {
                          color: 'rgba(255, 255, 255, 0.7)',
                          '&:hover': { color: 'white', opacity: 1 },
                          minWidth: isMobile ? '58px' : '60px',
                          maxWidth: isMobile ? "75vw" : "auto",
                          padding: isMobile ? '0px 5px' : '0px 20px',
                          height: '10px',
                          display: 'flex',
                          alignItems: 'center',
                        },
                        '.Mui-selected': { color: 'white', textShadow: '0px 0px 10px white' },
                        display: 'flex',
                        justifyContent: isMobile ? 'space-between' : 'space-around',
                      }}
                    >
                      <Tab
                        label={!isMobile ? "My Stories" : "My Stories"}
                        selected={selectedTab === 0}
                      />
                      <Tab
                        label={!isMobile ? "My Experience" : "My Experience"}
                        selected={selectedTab === 1}
                      />
                      <Tab
                        label={"Story of My Life"}
                        selected={selectedTab === 2}
                      />
                      <Tab
                        label={"My Collaborations"}
                        selected={selectedTab === 3}
                      />
                    </Tabs>
                  </Box>

                  {/* Content for each tab */}
                  <Box sx={{ paddingLeft: '20px', paddingRight: '20px', paddingBottom: '20px' }}>

                    {selectedTab === 0 && (
                      <Grid container spacing={2} alignItems="center">
                        {/* Check if currentPortfolio is available */}
                        {currentPortfolio ? (
                          <>
                            <Grid item xs={2} sm={1} md={1}>
                              <IconButton
                                onClick={handlePortfolioPrev}
                                sx={{
                                  opacity: currentPortfolioIndex > 0 ? 1 : 0.3,
                                  cursor: currentPortfolioIndex > 0 ? 'pointer' : 'default',
                                  color: "white"
                                }}
                                disabled={currentPortfolioIndex === 0}
                              >
                                <ArrowBackIosIcon />
                              </IconButton>
                            </Grid>
                            <Grid item xs={8} sm={6} md={3}>
                              <Box sx={{ textAlign: "center", flexGrow: 1 }}>
                                <img src={currentPortfolio.poster} alt={currentPortfolio.name} style={{ maxWidth: "100%", maxHeight: "200px" }} />
                              </Box>
                            </Grid>
                            {isMobile && (
                              <Grid item xs={2} sm={1} md={1}>
                                <IconButton
                                  onClick={handlePortfolioNext}
                                  sx={{
                                    opacity: currentPortfolioIndex < profile.originalIps?.length - 1 ? 1 : 0.3,
                                    cursor: currentPortfolioIndex < profile.originalIps?.length - 1 ? 'pointer' : 'default',
                                    color: "white"
                                  }}
                                  disabled={currentPortfolioIndex === profile.originalIps?.length - 1}
                                >
                                  <ArrowForwardIosIcon />
                                </IconButton>
                              </Grid>
                            )}
                            <Grid item xs={12} sm={6} md={7}>
                              <Grid container spacing={1}>
                                <Grid item xs={12}>
                                  <Typography variant="h6" sx={{ mt: 2, fontSize: "20px", fontWeight: "800" }}>
                                    <strong>{currentPortfolio.name}</strong>
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Box sx={{ height: isMobile ? '150px' : '100px', overflow: 'auto' }}>
                                    <Typography variant="body1" sx={{ fontSize: "14px", fontWeight: "300" }}>
                                      {currentPortfolio.logLine}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                  <Typography variant="body2" sx={{ fontSize: "14px", fontWeight: "300" }}>
                                    <strong>Genre:</strong> {currentPortfolio.genre_v2?.join(", ") || "N/A"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                  <Typography variant="body2" sx={{ fontSize: "14px", fontWeight: "300" }}>
                                    <strong>Format:</strong> {currentPortfolio.format || "N/A"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                  <Typography variant="body2" sx={{ fontSize: "14px", fontWeight: "300" }}>
                                    <strong>Language:</strong> {currentPortfolio.language || "N/A"}
                                  </Typography>
                                </Grid>

                                {/* Co-Writers Section */}
                                <Grid
                                  item
                                  xs={12}
                                  sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
                                >
                                  <Typography
                                    variant="h6"
                                    sx={{ fontSize: "16px", fontWeight: "600" }}
                                  >
                                    Co-Writers:
                                  </Typography>

                                  <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', flexWrap: 'wrap' }}>
                                    {currentPortfolio.coWriters?.length > 0 ? (
                                      currentPortfolio.coWriters.map((writer) => (
                                        <Tooltip key={writer.userId} title={writer.fullName} arrow>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                              width: "40px",
                                              height: "40px",
                                              borderRadius: "50%",
                                              overflow: "hidden",
                                              position: "relative",
                                              backgroundColor: "lightgray" // Background color when image is missing
                                            }}
                                          >
                                            <img
                                              src={writer.profilePath}
                                              alt={writer.fullName}
                                              onError={(e) => { e.target.onerror = null; e.target.style.display = 'none'; }} // Hide image on error
                                              style={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "cover",
                                                display: "block"
                                              }}
                                            />
                                            {!writer.profilePath && (
                                              <Typography
                                                variant="h6"
                                                sx={{
                                                  position: "absolute",
                                                  top: "50%",
                                                  left: "50%",
                                                  transform: "translate(-50%, -50%)",
                                                  color: "white",
                                                  fontSize: "20px",
                                                  fontWeight: "700",
                                                  userSelect: "none"
                                                }}
                                              >
                                                {writer.fullName.charAt(0).toUpperCase()}
                                              </Typography>
                                            )}
                                          </Box>
                                        </Tooltip>
                                      ))
                                    ) : (
                                      <Typography variant="body2" sx={{ fontSize: "14px", fontWeight: "300" }}>
                                        No co-writers available
                                      </Typography>
                                    )}
                                  </Box>
                                </Grid>

                              </Grid>
                            </Grid>
                            {!isMobile && (
                              <Grid item xs={2} sm={1} md={1}>
                                <IconButton
                                  onClick={handlePortfolioNext}
                                  sx={{
                                    opacity: currentPortfolioIndex < profile.originalIps?.length - 1 ? 1 : 0.3,
                                    cursor: currentPortfolioIndex < profile.originalIps?.length - 1 ? 'pointer' : 'default',
                                    color: "white"
                                  }}
                                  disabled={currentPortfolioIndex === profile.originalIps?.length - 1}
                                >
                                  <ArrowForwardIosIcon />
                                </IconButton>
                              </Grid>
                            )}
                          </>
                        ) : (
                          <Grid item xs={12}>
                            <Typography variant="h6" sx={{ mt: 2, fontSize: "20px", fontWeight: "800" }}>
                              No original IPs available
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    )}

                    {selectedTab === 1 && (
                      <Grid container spacing={2} alignItems="center">
                        {profile?.experience?.length > 0 ? (
                          <>
                            <Grid item xs={2} sm={1} md={1}>
                              <IconButton
                                onClick={handleExperiencePrev}
                                sx={{
                                  opacity: currentExperienceIndex > 0 ? 1 : 0.3, // Make the button partially transparent if at the first item
                                  cursor: currentExperienceIndex > 0 ? 'pointer' : 'default', // Only show the pointer cursor if not at the first item
                                  color: "white"
                                }}
                                disabled={currentExperienceIndex === 0} // Disable the button if it's the first experience
                              >
                                <ArrowBackIosIcon />
                              </IconButton>
                            </Grid>
                            {console.log(profile.experience[currentExperienceIndex])}
                            <Grid item xs={8} sm={6} md={3}>
                              <Box sx={{ textAlign: "center", flexGrow: 1 }}>
                                {/* Check if the proof_link and its metadata exist */}
                                {profile.experience[currentExperienceIndex].meta?.image ? (
                                  <img
                                    src={profile.experience[currentExperienceIndex].meta?.image || profile.experience[currentExperienceIndex].meta?.favicon}
                                    alt={profile.experience[currentExperienceIndex].name}
                                    style={{ maxWidth: "100%", maxHeight: "200px" }}
                                  />
                                ) : (
                                  <Box
                                    variant="rectangular"
                                    sx={{
                                      borderRadius: '12px',
                                      background: 'linear-gradient(135deg, #6D83F2, #8C5FE1, #F296FF)',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      padding: 1,
                                      width: '100%', // Use 100% of the available width    
                                      height: '100%', // Use 100% of the available height
                                      minHeight: '100px'
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        color: 'white',
                                        fontWeight: 'bold',
                                        fontSize: '1rem',
                                        textAlign: 'center',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        width: '100%',
                                      }}
                                    >
                                      {profile.experience[currentExperienceIndex].name?.length > 20
                                        ? `${profile.experience[currentExperienceIndex].name.substring(0, 20)}...`
                                        : profile.experience[currentExperienceIndex].name || 'No Title'}
                                    </Typography>
                                  </Box>
                                )}
                              </Box>
                            </Grid>
                            {isMobile && (
                              <Grid item xs={2} sm={1} md={1}>
                                <IconButton
                                  onClick={handleExperienceNext}
                                  sx={{ opacity: currentExperienceIndex < profile.experience.length - 1 ? 1 : 0.3, cursor: currentExperienceIndex < profile.experience.length - 1 ? 'pointer' : 'default', color: "white" }}
                                  disabled={currentExperienceIndex === profile.experience.length - 1}
                                >
                                  <ArrowForwardIosIcon />
                                </IconButton>
                              </Grid>
                            )}
                            <Grid item xs={12} sm={6} md={7}>
                              <Grid container spacing={1}>
                                <Grid item xs={12}>
                                  <Typography variant="h6" sx={{ mt: 2, fontSize: "20px", fontWeight: "800" }}>
                                    {profile.experience[currentExperienceIndex].name}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Box sx={{ height: isMobile ? '200px' : '100px', overflow: 'auto' }}>
                                    <Typography variant="body1" sx={{ fontSize: "14px", fontWeight: "300" }}>
                                      {profile.experience[currentExperienceIndex].logLine}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <Typography variant="body2" sx={{ fontSize: "14px", fontWeight: "300" }}>
                                    <strong>Format:</strong> {profile.experience[currentExperienceIndex].format}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <Typography variant="body2" sx={{ fontSize: "14px", fontWeight: "300" }}>
                                    <strong>Proof Link:</strong> <a href={profile.experience[currentExperienceIndex].proof_link} target="_blank" rel="noopener noreferrer">View Proof</a>
                                  </Typography>
                                </Grid>
                                {profile.experience[currentExperienceIndex].date && (
                                  <>
                                    <Grid item xs={12} sm={6}>
                                      <Typography variant="body2" sx={{ fontSize: "14px", fontWeight: "300" }}>
                                        <strong>From Date:</strong> {formatDate(profile.experience[currentExperienceIndex].date)}
                                      </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                      <Typography variant="body2" sx={{ fontSize: "14px", fontWeight: "300" }}>
                                        <strong>To Date: </strong>
                                        {profile.experience[currentExperienceIndex].toDate ? (
                                          <>
                                            {formatDate(profile.experience[currentExperienceIndex].toDate)}
                                            <span style={{ marginLeft: '4px' }}>
                                              ({calculateDuration(profile.experience[currentExperienceIndex].date, profile.experience[currentExperienceIndex].toDate)})
                                            </span>
                                          </>
                                        ) : (
                                          <>
                                            Currently Working
                                            <span style={{ marginLeft: '4px' }}>
                                              ({calculateDuration(profile.experience[currentExperienceIndex].date)})
                                            </span>
                                          </>
                                        )}
                                      </Typography>
                                    </Grid>
                                  </>
                                )}
                              </Grid>
                            </Grid>
                            {!isMobile && (
                              <Grid item xs={2} sm={1} md={1}>
                                <IconButton
                                  onClick={handleExperienceNext}
                                  sx={{ opacity: currentExperienceIndex < profile.experience.length - 1 ? 1 : 0.3, cursor: currentExperienceIndex < profile.experience.length - 1 ? 'pointer' : 'default', color: "white" }}
                                  disabled={currentExperienceIndex === profile.experience.length - 1}
                                >
                                  <ArrowForwardIosIcon />
                                </IconButton>
                              </Grid>
                            )}
                          </>
                        ) : (
                          <Grid item xs={12}>
                            <Typography variant="h6" sx={{ mt: 2, fontSize: "20px", fontWeight: "800" }}>
                              No experience available
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    )}

                    {/* Story of my Life */}
                    {selectedTab === 2 && (
                      <Grid container spacing={2} alignItems="center">
                        <Box
                          sx={{
                            height: 250, // Set a fixed height for the box
                            overflowY: 'auto', // Enable vertical scroll if content overflows,
                            marginTop: '24px'
                          }}
                        >
                          {/* Check if profile.storyOfmyLife.value exists */}
                          {profile?.storyOfmyLife?.value ? (
                            <div dangerouslySetInnerHTML={{ __html: profile.storyOfmyLife.value }} />
                          ) : (
                            <Typography variant="h6" sx={{ fontSize: "16px", fontWeight: "400" }}>
                              No story available
                            </Typography>
                          )}
                        </Box>
                      </Grid>
                    )}

                    {selectedTab === 3 && (
                      <Grid container spacing={2} alignItems="center">
                        {/* Check if there are any collaborations */}
                        {collaborations && collaborations.length > 0 ? (
                          <>
                            <Grid item xs={2} sm={1} md={1}>
                              <IconButton
                                onClick={handleCollaborationPrev}
                                sx={{
                                  opacity: currentCollaborationIndex > 0 ? 1 : 0.3,
                                  cursor: currentCollaborationIndex > 0 ? 'pointer' : 'default',
                                  color: "white"
                                }}
                                disabled={currentCollaborationIndex === 0}
                              >
                                <ArrowBackIosIcon />
                              </IconButton>
                            </Grid>
                            <Grid item xs={8} sm={6} md={3}>
                              <Box sx={{ textAlign: "center", flexGrow: 1 }}>
                                <img
                                  src={collaborations[currentCollaborationIndex].poster}
                                  alt={collaborations[currentCollaborationIndex].name}
                                  style={{ maxWidth: "100%", maxHeight: "200px" }}
                                />
                              </Box>
                            </Grid>
                            {isMobile && (
                              <Grid item xs={2} sm={1} md={1}>
                                <IconButton
                                  onClick={handleCollaborationNext}
                                  sx={{
                                    opacity: currentCollaborationIndex < collaborations.length - 1 ? 1 : 0.3,
                                    cursor: currentCollaborationIndex < collaborations.length - 1 ? 'pointer' : 'default',
                                    color: "white"
                                  }}
                                  disabled={currentCollaborationIndex === collaborations.length - 1}
                                >
                                  <ArrowForwardIosIcon />
                                </IconButton>
                              </Grid>
                            )}
                            <Grid item xs={12} sm={6} md={7}>
                              <Grid container spacing={1}>
                                <Grid item xs={12}>
                                  <Typography variant="h6" sx={{ mt: 2, fontSize: "20px", fontWeight: "800" }}>
                                    <strong>{collaborations[currentCollaborationIndex].name}</strong>
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Box sx={{ height: isMobile ? '150px' : '100px', overflow: 'auto' }}>
                                    <Typography variant="body1" sx={{ fontSize: "14px", fontWeight: "300" }}>
                                      {collaborations[currentCollaborationIndex].logLine}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                  <Typography variant="body2" sx={{ fontSize: "14px", fontWeight: "300" }}>
                                    <strong>Genre:</strong> {collaborations[currentCollaborationIndex].genre.length > 0 ? collaborations[currentCollaborationIndex].genre.join(', ') : "N/A"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                  <Typography variant="body2" sx={{ fontSize: "14px", fontWeight: "300" }}>
                                    <strong>Status:</strong> {collaborations[currentCollaborationIndex].status || "N/A"}
                                  </Typography>
                                </Grid>
                                {/* Owner Avatar and Name */}
                                <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
                                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                    <img
                                      src={collaborations[currentCollaborationIndex].ownerProfilePath}
                                      alt={collaborations[currentCollaborationIndex].ownerName}
                                      style={{ width: "40px", height: "40px", borderRadius: "50%" }}
                                    />
                                    <Typography variant="body2" sx={{ fontSize: "14px", fontWeight: "300" }}>
                                      <strong>Owner:</strong> {collaborations[currentCollaborationIndex].ownerName}
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Grid>
                            {!isMobile && (
                              <Grid item xs={2} sm={1} md={1}>
                                <IconButton
                                  onClick={handleCollaborationNext}
                                  sx={{
                                    opacity: currentCollaborationIndex < collaborations.length - 1 ? 1 : 0.3,
                                    cursor: currentCollaborationIndex < collaborations.length - 1 ? 'pointer' : 'default',
                                    color: "white"
                                  }}
                                  disabled={currentCollaborationIndex === collaborations.length - 1}
                                >
                                  <ArrowForwardIosIcon />
                                </IconButton>
                              </Grid>
                            )}
                          </>
                        ) : (
                          <Grid item xs={12}>
                            <Typography variant="h6" sx={{ mt: 2, fontSize: "20px", fontWeight: "800" }}>
                              No collaborations available
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    )}

                  </Box>
                </GlassBox>
              </Grid>
              <Grid item xs={12}>
                <GlassBox color={theme.palette.text.primary} width={{ mobile: "100%", desktop: "100%" }} height={{ mobile: "auto", desktop: "33vh" }} sx={{ padding: "20px", maxWidth: isMobile ? 'auto' : "65vw" }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {/* Favourite Tabs */}
                    <Tabs
                      value={selectedAddTab}
                      onChange={handleAddTabChange} // Ensure only `selectedAddTab` is updated
                      aria-label="Additional tabs"
                      variant="scrollable"
                      scrollButtons="auto"
                      TabIndicatorProps={{ style: { backgroundColor: 'white', color: 'white' } }}
                      sx={{
                        '.MuiTab-root': {
                          color: 'rgba(255, 255, 255, 0.7)',
                          '&:hover': { color: 'white', opacity: 1 },
                          minWidth: isMobile ? '58px' : '130px',
                          padding: isMobile ? '0px 5px' : '0px 20px',
                          height: '10px',
                          display: 'flex',
                          alignItems: 'center',
                        },
                        '.Mui-selected': { color: 'white', textShadow: '0px 0px 10px white' },
                        display: 'flex',
                        justifyContent: isMobile ? 'space-between' : 'space-around',
                      }}
                    >
                      <Tab
                        icon={isMobile ? <FavoriteIcon /> : null}
                        label={!isMobile ? "Favorite Creations" : " Creations"}
                        value={20} // Use `20` for Favorite Creations
                      />
                      <Tab
                        icon={isMobile ? <FavoriteIcon /> : null}
                        label={!isMobile ? "Favorite Creators" : " Creators"}
                        value={21} // Use `21` for Favorite Creators
                      />
                    </Tabs>

                  </Box>
                  <Typography variant="h6" sx={{ fontSize: "12px", fontWeight: "300", marginTop: "10px" }}>
                    Click on the poster to read why I like the creation/creator
                  </Typography>
                  {selectedAddTab === 20 && (
                    <Box
                      sx={{
                        display: 'flex',
                        overflowX: 'auto', // Enables horizontal scrolling
                        width: '100%',
                        gap: isMobile ? "" : 1,
                        height: isMobile ? '270px' : 'auto',
                        flexWrap: isMobile ? 'wrap' : 'no-wrap',
                        paddingTop: '20px', // Optional padding to space the carousel from the tabs
                        '&::-webkit-scrollbar': { display: 'none' }, // Optional: Hide scrollbar for aesthetic reasons
                      }}
                    >
                      {favoriteCreations.length > 0 ? (
                        favoriteCreations.map((creation) => (
                          <Box
                            key={creation.reference}
                            sx={{
                              flex: "0 0 auto",
                              width: isMobile ? "70px" : "55px",
                              height: isMobile ? "auto" : "90px",
                              marginRight: "10px",
                              margin: "8px",
                              borderRadius: "12px",
                              overflow: "hidden",
                              cursor: "pointer",
                              transition: "all 0.3s ease", // Smooth transition for the click effect    
                              ...(selectedItem === creation && { // Only apply styles if clicked      
                                transform: 'scale(1.05)', // Grow 5% when clicked      
                                border: '1px solid white', // Add white border when clicked    
                              }),
                            }}
                            onClick={(event) => handleItemClick(event, creation)} // Use the same click handler
                          >
                            <Tooltip title={creation.title} arrow>
                              <img
                                src={creation.poster_path || "placeholder.jpg"} // Use a placeholder if no image
                                alt={creation.title || "No Title"}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover"
                                }}
                              />
                            </Tooltip>
                          </Box>
                        ))
                      ) : (
                        <Typography>No Favorite Creations available</Typography>
                      )}
                    </Box>
                  )}
                  {selectedAddTab === 21 && (
                    <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ paddingTop: '30px' }}>
                      {uniqueCreators.length > 0 ? (
                        uniqueCreators.map((creator) => (
                          <Grid item key={creator._id}>
                            <Tooltip title={creator.title} arrow>
                              <Avatar
                                alt={creator.title}
                                src={creator.poster_path || "placeholder.jpg"} // Use a placeholder image if no poster is available
                                sx={{
                                  width: isMobile ? 80 : 80, // Adjust avatar size based on screen size
                                  height: isMobile ? 80 : 80,
                                  borderRadius: "50%",
                                  cursor: 'pointer',
                                  transition: "all 0.3s ease", // Smooth transition for the click effect    
                                  ...(selectedItem === creator && { // Only apply styles if clicked      
                                    transform: 'scale(1.05)', // Grow 5% when clicked      
                                    border: '1px solid white', // Add white border when clicked    
                                  }),
                                }}
                                onClick={(event) => handleItemClick(event, creator)} // Use the same click handler
                              />
                            </Tooltip>
                          </Grid>
                        ))
                      ) : (
                        <Typography>No Favorite Creators available</Typography>
                      )}
                    </Grid>
                  )}
                </GlassBox>
              </Grid>
            </Grid>
          </Grid>
          {/* Popover for displaying the favorite's details */}
          <Popover
            open={Boolean(anchorEl)} // Open the popover if there's an anchor element
            anchorEl={anchorEl} // Reuse the anchor for creators and creations
            onClose={() => setAnchorEl(null)} // Close popover when clicking outside
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <GlassBox
              width={{ mobile: "100%", desktop: "400px" }}
              height={{ mobile: "auto", desktop: "auto" }}
              sx={{
                padding: theme.spacing(2),
                backgroundColor: 'rgba(0, 0, 0, 0.7)', // Shiny black glass effect
                backdropFilter: 'blur(10px)', // Blur for the glass effect
                border: '1px solid white', // Slim white border
                borderRadius: '15px', // Rounded edges
                boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)', // Soft shadow to enhance glass look
                color: 'white', // Text color to contrast with background
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                {selectedItem?.title || "Unknown Title"} {/* Can be for either creator or creation */}
              </Typography>
              <Box sx={{ maxHeight: "200px", overflowY: "auto", marginTop: theme.spacing(2) }}>
                <Typography variant="body1" sx={{ fontWeight: "300" }}>
                  {selectedItem?.personalNote || "No personal note available."}
                </Typography>
              </Box>
            </GlassBox>
          </Popover>
        </Grid>
      </main>
    </div>
  );
}
